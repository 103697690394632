import {Card, Col, Row} from "antd";
import React, {useLayoutEffect, useRef, useState} from "react";
import {
    TextSDACardReference,
    TextSDACardClassification,
    TextSDACardForecasting,
    TextSDACardAnomaly,
    TextSDACardSoft,
    TextSDAToolbox,
    TitleSDACardReference,
    TitleSDACardClassification,
    TitleSDACardForecasting,
    TitleSDACardAnomaly,
    TitleSDACardSoft,
    TitleSDAToolbox
} from "../../text_content";
import img_sda_ref from "../../../images/sda-1.webp"
import img_sda_class from "../../../images/sda-2.webp"
import img_sda_fore from "../../../images/sda-3.webp"
import img_sda_ano from "../../../images/sda-4.webp"
import img_sda_soft from "../../../images/sda-5.webp"


const { Meta } = Card;


export default function SDAToolboxMD() {
    return (
        <div>
            <Row justify="center" className="padded-t50">
                <Col span={16}>
                    <TitleSDAToolbox />
                    <TextSDAToolbox />
                </Col>
            </Row>

            <Row justify="center" className="padded-t25">
                <Col span={16}>
                    <Row>
                        <Card
                            bordered={true}
                            className="custom-card"
                            cover={
                                <img
                                    className="sda-card-cover"
                                    alt=""
                                    src={img_sda_ref}
                                />
                            }
                        >
                            <Meta
                                title={<TitleSDACardReference />}
                                description={<TextSDACardReference />}
                            />
                        </Card>
                    </Row>

                    <Row className="padded-t25">
                        <Card
                            bordered={true}
                            className="custom-card"
                            cover={
                                <img
                                    className="sda-card-cover"
                                    alt=""
                                    src={img_sda_class}
                                />
                            }
                        >
                            <Meta
                                title={<TitleSDACardClassification />}
                                description={<TextSDACardClassification />}
                            />
                        </Card>
                    </Row>

                    <Row className="padded-t25">
                        <Card
                            bordered={true}
                            className="custom-card"
                            cover={
                                <img
                                    className="sda-card-cover"
                                    alt=""
                                    src={img_sda_fore}
                                />
                            }
                        >
                            <Meta
                                title={<TitleSDACardForecasting />}
                                description={<TextSDACardForecasting />}
                            />
                        </Card>
                    </Row>

                    <Row className="padded-t25">
                        <Card
                            bordered={true}
                            className="custom-card"
                            cover={
                                <img
                                    className="sda-card-cover"
                                    alt=""
                                    src={img_sda_ano}
                                />
                            }
                        >
                            <Meta
                                title={<TitleSDACardAnomaly />}
                                description={<TextSDACardAnomaly />}
                            />
                        </Card>
                    </Row>

                    <Row className="padded-t25 padded-b50">
                        <Card
                            bordered={true}
                            className="custom-card"
                            cover={
                                <img
                                    className="sda-card-cover"
                                    alt=""
                                    src={img_sda_soft}
                                />
                            }
                        >
                            <Meta
                                title={<TitleSDACardSoft />}
                                description={<TextSDACardSoft />}
                            />
                        </Card>
                    </Row>

                </Col>
            </Row>
        </div>
    );
}

