import {Card, Col, Row} from "antd";
import React, {useLayoutEffect, useRef, useState} from "react";
import {
    TextSDACardReference,
    TextSDACardClassification,
    TextSDACardForecasting,
    TextSDACardAnomaly,
    TextSDACardSoft,
    TextSDAToolbox,
    TitleSDACardReference,
    TitleSDACardClassification,
    TitleSDACardForecasting,
    TitleSDACardAnomaly,
    TitleSDACardSoft,
    TitleSDAToolbox
} from "../../text_content";
import img_sda_ref from "../../../images/sda-1.webp"
import img_sda_class from "../../../images/sda-2.webp"
import img_sda_fore from "../../../images/sda-3.webp"
import img_sda_ano from "../../../images/sda-4.webp"
import img_sda_soft from "../../../images/sda-5.webp"


const { Meta } = Card;


export default function SDAToolboxLG() {
    const [heightCard1, setHeightCard1] = useState(0)
    const [heightCard2, setHeightCard2] = useState(0)
    const refCardHeight1 = useRef<HTMLDivElement>(null)
    const refCardHeight2 = useRef<HTMLDivElement>(null)
    useLayoutEffect(() => {
        if(refCardHeight1.current) {
            setHeightCard1(refCardHeight1.current.clientHeight);
        }
        if(refCardHeight2.current) {
            setHeightCard2(refCardHeight2.current.clientHeight);
        }
    }, []);

    return (
        <div>
            <Row justify="center" className="padded-t50">
                <Col span={22}>
                    <TitleSDAToolbox />
                    <TextSDAToolbox />
                </Col>
            </Row>

            <Row justify="center" className="padded-t25">
                <Col span={22}>
                    <Row justify="space-between">
                        <Col span={12}>
                            <Card
                                bordered={true}
                                className="custom-card"
                                style={{height: heightCard1}}
                                cover={
                                    <img
                                        className="sda-card-cover"
                                        alt=""
                                        src={img_sda_ref}
                                    />
                                }
                            >
                                <Meta
                                    title={<TitleSDACardReference />}
                                    description={<TextSDACardReference />}
                                />
                            </Card>
                        </Col>
                        <Col span={12}>
                            <Card
                                bordered={true}
                                className="custom-card"
                                ref={refCardHeight1}
                                cover={
                                    <img
                                        className="sda-card-cover"
                                        alt=""
                                        src={img_sda_class}
                                    />
                                }
                            >
                                <Meta
                                    title={<TitleSDACardClassification />}
                                    description={<TextSDACardClassification />}
                                />
                            </Card>
                        </Col>
                    </Row>
                </Col>
            </Row>

            <Row justify="center" className="padded-t25">
                <Col span={22}>
                    <Row justify="space-between">
                        <Col span={12}>
                            <Card
                                bordered={true}
                                className="custom-card"
                                style={{height: heightCard2}}
                                cover={
                                    <img
                                        className="sda-card-cover"
                                        alt=""
                                        src={img_sda_fore}
                                    />
                                }
                            >
                                <Meta
                                    title={<TitleSDACardForecasting />}
                                    description={<TextSDACardForecasting />}
                                />
                            </Card>
                        </Col>
                        <Col span={12}>
                            <Card
                                bordered={true}
                                className="custom-card"
                                ref={refCardHeight2}
                                cover={
                                    <img
                                        className="sda-card-cover"
                                        alt=""
                                        src={img_sda_ano}
                                    />
                                }
                            >
                                <Meta
                                    title={<TitleSDACardAnomaly />}
                                    description={<TextSDACardAnomaly />}
                                />
                            </Card>
                        </Col>
                    </Row>
                </Col>
            </Row>

            <Row justify="start" className="padded-t25 padded-b50">
                <Col span={22} offset={1}>
                    <Row justify="space-between">
                        <Col span={12}>
                            <Card
                                bordered={true}
                                className="custom-card"
                                cover={
                                    <img
                                        className="sda-card-cover"
                                        alt=""
                                        src={img_sda_soft}
                                    />
                                }
                            >
                                <Meta
                                    title={<TitleSDACardSoft />}
                                    description={<TextSDACardSoft />}
                                />
                            </Card>
                        </Col>
                        <Col span={12}>

                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    );
}

