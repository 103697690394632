import {Col, Divider, Row} from "antd";
import React from "react";
import img_how from "../../../images/how.webp"
import img_how1 from "../../../images/how1.png";
import img_foxhedgehog from "../../../images/thehedgehogandthefox.webp";
import icon_fox from "../../../images/logo-fox-dark-bg.webp";
import icon_hedgehog from "../../../images/logo-hedgehog-dark-bg.webp"
import {
    PhilosophyText,
    PhilosophyFox,
    PhilosophyHedgehog,
    PhilosophyImgDescription,
    PhilosophyQuote,
    TextHow,
    TextHowShort,
    TitleHow,
    PhilosophyIntroText,
    PhilosophyIntroTitle,
    TextHowHighlight, QuoteHowHighlight,
} from "../../text_content";


export default function PhilosophyMega() {
    return (
        <div>
            <Row justify="center" className="bg-dark padded-t50">
                <Col span={10}>
                    <Row>
                        <Col span={24}>
                            <QuoteHowHighlight />
                        </Col>
                    </Row>

                    <Row align="middle" justify="center" className="bg-dark padded-t50">
                        <Col span={4}>
                            <img src={img_how} className="img-full" alt=""/>
                        </Col>
                        <Col span={12} offset={2}>
                            {TextHowShort("light-text centered")}
                        </Col>
                    </Row>

                    <Row justify="space-around" className="bg-dark padded-t50 padded-b100">
                        <Col span={24}>
                            <TextHowHighlight />
                        </Col>
                    </Row>
                </Col>
            </Row>

            <Row align="middle" justify="center" className="padded-t100">
                <Col span={12}>
                    <TitleHow />
                    <TextHow />
                </Col>
            </Row>
            <Row justify="center" className="padded-t50 padded-b100">
                <Col span={12}>
                    <img src={img_how1} className="img-full" alt=""/>
                </Col>
            </Row>

            <Divider style={{borderColor: "#1C283C", borderWidth: 2}}/>

            <Row justify="center" className="padded-t100">
                <Col span={12}>
                    <PhilosophyIntroTitle />
                </Col>
            </Row>
            <Row justify="center" align="middle">
                <Col span={12}>
                    <PhilosophyIntroText />
                </Col>
            </Row>

            <Row justify="center" className="padded-t25">
                <Col span={8}>
                    <PhilosophyQuote />
                </Col>
            </Row>
            <Row justify="center">
                <Col span={8}>
                    <img src={img_foxhedgehog} className="img-full" alt="Logo"/>
                </Col>
            </Row>
            <Row justify="center">
                <Col span={8}>
                    <PhilosophyImgDescription />
                </Col>
            </Row>

            <Row align="middle" justify="center" className="padded-t25">
                <Col span={12}>
                    <PhilosophyText />
                </Col>
            </Row>
            <Row align="middle" justify="center" className="padded-t25">
                <Col flex="0 0 10%">
                    <img src={icon_fox} className="img-scaled-70" alt="icon"/>
                </Col>
                <Col span={9} offset={1}>
                    <PhilosophyFox />
                </Col>
            </Row>
            <Row align="middle" justify="center" className="padded-t25 padded-b100">
                <Col flex="0 0 10%">
                    <img src={icon_hedgehog} className="img-scaled-70" alt="icon"/>
                </Col>
                <Col span={9} offset={1}>
                    <PhilosophyHedgehog />
                </Col>
            </Row>
        </div>
    );
}

