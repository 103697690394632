import {Card, Col, Row} from "antd";
import React, {useLayoutEffect, useRef, useState} from "react";
import {
    TitleXAIToolbox,
    TextXAIToolbox,
    TitleXAICardMotifs,
    TextXAICardMotifs,
    TitleXAICardFeature,
    TextXAICardFeature,
    TitleXAICardAblations,
    TextXAICardAblations,
} from "../../text_content";
import img_xai_feat from "../../../images/xai-1.png"
import img_xai_ablation from "../../../images/xai-2.png"
import img_xai_imp from "../../../images/xai-3.png"


const { Meta } = Card;


export default function XAIToolboxXL() {
    const [heightCard, setHeightCard] = useState(0)
    const refCardHeight = useRef<HTMLDivElement>(null)
    useLayoutEffect(() => {
        if(refCardHeight.current) {
            setHeightCard(refCardHeight.current.clientHeight);
        }
    }, []);

    return (
        <div>
            <Row justify="center" className="padded-t50">
                <Col span={18}>
                    <TitleXAIToolbox />
                    <TextXAIToolbox />
                </Col>
            </Row>

            <Row justify="center" className="padded-t25 padded-b50">
                <Col span={18}>
                    <Row justify="space-between">
                        <Col span={12}>
                            <Card
                                bordered={true}
                                className="custom-card"
                                ref={refCardHeight}
                                cover={
                                    <img
                                        className="sda-card-cover"
                                        alt=""
                                        src={img_xai_feat}
                                    />
                                }
                            >
                                <Meta
                                    title={<TitleXAICardFeature />}
                                    description={<TextXAICardFeature />}
                                />
                            </Card>
                        </Col>
                        <Col span={12}>
                            <Card
                                bordered={true}
                                className="custom-card"
                                style={{height: heightCard}}
                                cover={
                                    <img
                                        className="sda-card-cover"
                                        alt=""
                                        src={img_xai_ablation}
                                    />
                                }
                            >
                                <Meta
                                    title={<TitleXAICardAblations />}
                                    description={<TextXAICardAblations />}
                                />
                            </Card>
                        </Col>
                    </Row>

                    <Row justify="space-between" className="padded-t25">
                        <Col span={12}>
                            <Card
                                bordered={true}
                                className="custom-card"
                                style={{height: heightCard}}
                                cover={
                                    <img
                                        className="sda-card-cover"
                                        alt=""
                                        src={img_xai_imp}
                                    />
                                }
                            >
                                <Meta
                                    title={<TitleXAICardMotifs />}
                                    description={<TextXAICardMotifs />}
                                />
                            </Card>
                        </Col>
                        <Col span={12}>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    );
}

