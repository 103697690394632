import React from 'react';
import {createRoot} from "react-dom/client";
import {Layout} from 'antd';

import PageFooter from "./components/footer";
import PageFooterCopyright from "./components/footer_copyright";
import PageHeader from "./components/header";
import Routing from "./components/router";

import 'antd/dist/antd.min.css';
import './css/index.css';


const {Header, Footer, Content} = Layout;
const root = createRoot(document.getElementById("root")!);


root.render(
    <Layout>
        <Header className='header'>
            <PageHeader/>
        </Header>
        <Content className="content">
            <Routing/>
        </Content>
        <Footer className="footer">
            <PageFooter/>
        </Footer>
        <Footer className="footer-copyright">
            <PageFooterCopyright/>
        </Footer>
    </Layout>
);
