import React from "react";
import ImpressumOSFA from "../components/pages/0_osfa/impressum_osfa";


export default function Impressum() {
    return (
        <div>
            <ImpressumOSFA />
        </div>
    );
}