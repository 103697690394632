import {BrowserRouter, Routes, Route} from "react-router-dom";
import Home from "../pages/home";
import Philosophy from "../pages/philosophy";
import Impressum from "../pages/impressum";
import PrivacyPolicy from "../pages/privacy_policy";
import DataTasting from "../pages/data_tasting";
import DataDashboard from "../pages/data_dashboard";
import SDAToolbox from "../pages/sda_toolbox";
import Solutions from "../pages/solutions";
import Mission from "../pages/mission";
import XAIToolbox from "../pages/xai_toolbox";


export default function Routing() {
    return (
        <div>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/mission" element={<Mission />} />
                    <Route path="/philosophy" element={<Philosophy />} />
                    <Route path="/solutions" element={<Solutions />} />
                    <Route path="/solutions/data_tasting" element={<DataTasting />} />
                    <Route path="/solutions/data_dashboard" element={<DataDashboard />} />
                    <Route path="/solutions/sda_toolbox" element={<SDAToolbox />} />
                    <Route path="/solutions/xai_toolbox" element={<XAIToolbox />} />
                    <Route path="/impressum" element={<Impressum />} />
                    <Route path="/datenschutz" element={<PrivacyPolicy />} />
                </Routes>
            </BrowserRouter>
        </div>
    );
}