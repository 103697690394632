import React from 'react';
import {Col, Row, Carousel, Divider, Button} from "antd";
import {RightOutlined, LeftOutlined} from '@ant-design/icons';
import carousel_gif_digtrans from "../../../images/carousel_digtrans.webp"
import carousel_gif_datatasting from "../../../images/carousel_datatasting.webp"
import carousel_gif_datadashboard from "../../../images/carousel_datadashboard.webp"
import carousel_gif_sensordataanalytics from "../../../images/carousel_sda.webp"
import carousel_gif_xai from "../../../images/carousel_xai.webp"
import img_what from "../../../images/what.webp"
import logo_buw from "../../../images/buw-logo.webp"
import logo_tmdt from "../../../images/tmdt-logo.webp"
import logo_izmd from "../../../images/izmd-logo.webp"
import logo_bit from "../../../images/bit-logo.webp"
import img_industry_hws from "../../../images/industry-hws.webp"
import img_industry_dd from "../../../images/industry-deepdrawing.webp"
import img_industry_tape from "../../../images/industry-tape.webp"
import img_industry_windshield from "../../../images/industry-windshield.webp"
import {
    CarouselTextDataDashboard,
    CarouselTextDataTasting,
    CarouselTextDigitalisierung,
    CarouselTextHochwasser,
    TextHowShort,
    CarouselTextTapes,
    CarouselTextSensorDataAnalyticsToolbox,
    CarouselTextTiefziehen,
    TextWhatShort,
    TextWhyShort,
    CarouselTextWindshields,
    CarouselTextXAIToolbox,
    CarouselTitleDataDashboard,
    CarouselTitleDataTasting,
    CarouselTitleDigitalisierung,
    CarouselTitleHochwasser,
    TitleHomeCarousel,
    CarouselTitleTapes,
    CarouselTitleSensorDataAnalyticsToolbox,
    CarouselTitleTiefziehen,
    TitleGoldenCircle,
    CarouselTitleWindshields,
    CarouselTitleXAIToolbox,
    CarouselQuoteDigitalisierungPart1,
    CarouselQuoteDigitalisierungPart2,
    TitleResearchPartners,
} from "../../text_content";
import colorScheme from "../../../scss/index.scss";


export default function HomeMD() {
    return (
        <div>
            <Row justify="center" className="bg-dark padded-t25 padded-b50">
                <Col span={20}>
                    <Carousel dots={true}
                              dotPosition="bottom"
                              effect="scrollx"
                              autoplaySpeed={5000}
                              arrows={true}
                              autoplay={true}
                              nextArrow={<RightOutlined />}
                              prevArrow={<LeftOutlined/>}
                    >
                        <div>
                            <CarouselDigital />
                        </div>
                        <div>
                            <CarouselDataTasting />
                        </div>
                        <div>
                            <CarouselDataDashboard />
                        </div>
                        <div>
                            <CarouselSensorDataAnalyticsToolbox />
                        </div>
                        <div>
                            <CarouselXAIToolbox />
                        </div>
                    </Carousel>
                </Col>
            </Row>

            <Row justify="center" className="padded-t25 padded-b25">
                <Col span={20}>
                    <Row>
                        <Col span={24}>
                            {TitleGoldenCircle("centered")}
                        </Col>
                    </Row>
                    <Row justify="space-around">
                        <Col span={18}>
                            <img src={img_what} className="img-half" alt=""/>
                        </Col>
                    </Row>
                    <Row className="padded-t50" justify="space-around">
                        <Col span={14}>
                            {TextWhyShort("centered")}
                        </Col>
                    </Row>
                    <Row>
                        <Button
                            className="btn-secondary horizontally-centered"
                            onClick={event =>  window.location.href='/mission'}
                        >
                            Unsere Mission
                        </Button>
                    </Row>
                    <Row className="padded-t50" justify="space-around">
                        <Col span={14}>
                            {TextHowShort("centered")}
                        </Col>
                    </Row>
                    <Row>
                        <Button
                            className="btn-secondary horizontally-centered"
                            onClick={event =>  window.location.href='/philosophy'}
                        >
                            Unsere Philosophie
                        </Button>
                    </Row>
                    <Row className="padded-t50" justify="space-around">
                        <Col span={14}>
                            {TextWhatShort("centered")}
                        </Col>
                    </Row>
                    <Row>
                        <Button
                            className="btn-secondary horizontally-centered"
                            onClick={event =>  window.location.href='/solutions'}
                        >
                            Unsere Lösungen
                        </Button>
                    </Row>
                </Col>
            </Row>

            <Divider style={{borderColor: colorScheme.colorSoothingDarkBlue, borderWidth: "3px"}}/>

            <Row justify="center" className="padded-t25 padded-b25">
                <Col span={20}>
                    {TitleHomeCarousel("centered")}
                    <Carousel dots={true}
                              dotPosition="bottom"
                              effect="scrollx"
                              autoplaySpeed={5000}
                              arrows={true}
                              autoplay={true}
                              nextArrow={<RightOutlined />}
                              prevArrow={<LeftOutlined/>}
                              className="ant-carousel2"
                    >
                        <div>
                            <CarouselHochwasser />
                        </div>
                        <div>
                            <CarouselTiefziehen />
                        </div>
                        <div>
                            <CarouselTapes />
                        </div>
                        <div>
                            <CarouselWindshields />
                        </div>
                    </Carousel>
                </Col>
            </Row>

            <Divider style={{borderColor: colorScheme.colorSoothingDarkBlue, borderWidth: "3px"}}/>

            <Row justify="center">
                <Col span={24}>
                    <Row justify="space-between" className="padded-t25">
                        <Col span={24}>
                            <TitleResearchPartners/>
                        </Col>
                    </Row>
                    <Row justify="space-between" className="padded-b25">
                        <Col span={24}>
                            <Row justify="space-evenly">
                                <Col span={5}>
                                    <a href="https://www.tmdt.uni-wuppertal.de" target="_blank" rel="noreferrer">
                                        <img src={logo_tmdt} className="logo-partner" alt="Logo"/>
                                    </a>
                                </Col>
                                <Col span={5}>
                                    <a href="https://www.uni-wuppertal.de" target="_blank" rel="noreferrer">
                                        <img src={logo_buw} className="logo-partner" alt="Logo"/>
                                    </a>
                                </Col>
                                <Col span={5}>
                                    <a href="https://www.izmd.uni-wuppertal.de" target="_blank" rel="noreferrer">
                                        <img src={logo_izmd} className="logo-partner" alt="Logo"/>
                                    </a>
                                </Col>
                                <Col span={5}>
                                    <a href="https://www.bit-ki.de" target="_blank" rel="noreferrer">
                                        <img src={logo_bit} className="logo-partner" alt="Logo"/>
                                    </a>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    );
}

function CarouselDigital() {
    return (
        <div>
            <Row justify="center">
                <Col span={22}>
                    <CarouselTitleDigitalisierung />
                </Col>
            </Row>
            <Row justify="center">
                <Col span={22}>
                    <CarouselQuoteDigitalisierungPart1 />
                </Col>
            </Row>
            <Row justify="end">
                <Col span={21}>
                    <CarouselQuoteDigitalisierungPart2 />
                </Col>
                <Col span={1}/>
            </Row>
            <Row justify="center">
                <Col span={22}>
                    <img src={carousel_gif_digtrans} className="carousel-gif" alt="Ani"/>
                </Col>
            </Row>
            <Row justify="center" className="padded-t25">
                <Col span={22}>
                    <CarouselTextDigitalisierung />
                </Col>
            </Row>
        </div>
    )
}

function CarouselDataTasting() {
    return (
        <div>
            <Row justify="center">
                <Col span={22}>
                    <CarouselTitleDataTasting />
                </Col>
            </Row>
            <Row justify="center">
                <Col span={22}>
                    <img src={carousel_gif_datatasting} className="carousel-gif carousel-gif-wine" alt="Ani"/>
                </Col>
            </Row>
            <Row justify="center" className="padded-t25">
                <Col span={22}>
                    <CarouselTextDataTasting />
                </Col>
            </Row>
            <Row justify="center">
                <Col span={22}>
                    <Button type="primary"
                            className="horizontally-centered"
                            onClick={event =>  window.location.href='/solutions/data_tasting'}
                    >
                        Zum Data Tasting!
                    </Button>
                </Col>
            </Row>
        </div>
    )
}

function CarouselDataDashboard() {
    return (
        <div>
            <Row justify="center">
                <Col span={22}>
                    <CarouselTitleDataDashboard />
                </Col>
            </Row>
            <Row justify="center">
                <Col span={22}>
                    <img src={carousel_gif_datadashboard} className="carousel-gif" alt="Ani"/>
                </Col>
            </Row>
            <Row justify="center" className="padded-t25">
                <Col span={22}>
                    <CarouselTextDataDashboard />
                </Col>
            </Row>
            <Row justify="center">
                <Col span={22}>
                    <Button type="primary"
                            className="horizontally-centered"
                            onClick={event =>  window.location.href='/solutions/data_dashboard'}
                    >
                        Zum Data Dashboard!
                    </Button>
                </Col>
            </Row>
        </div>
    )
}

function CarouselSensorDataAnalyticsToolbox() {
    return (
        <div>
            <Row justify="center">
                <Col span={22}>
                    <CarouselTitleSensorDataAnalyticsToolbox />
                </Col>
            </Row>
            <Row justify="center">
                <Col span={22}>
                    <img src={carousel_gif_sensordataanalytics} className="carousel-gif" alt="Ani"/>
                </Col>
            </Row>
            <Row justify="center" className="padded-t25">
                <Col span={22}>
                    <CarouselTextSensorDataAnalyticsToolbox />
                </Col>
            </Row>
            <Row justify="center">
                <Col span={22}>
                    <Button type="primary"
                            className="horizontally-centered"
                            onClick={event =>  window.location.href='/solutions/sda_toolbox'}
                    >
                        Zur SDA Toolbox!
                    </Button>
                </Col>
            </Row>
        </div>
    )
}

function CarouselXAIToolbox() {
    return (
        <div>
            <Row justify="center">
                <Col span={22}>
                    <CarouselTitleXAIToolbox />
                </Col>
            </Row>
            <Row justify="center">
                <Col span={22}>
                    <img src={carousel_gif_xai} className="carousel-gif" alt="Ani"/>
                </Col>
            </Row>
            <Row justify="center" className="padded-t25">
                <Col span={22}>
                    <CarouselTextXAIToolbox />
                </Col>
            </Row>
            <Row justify="center">
                <Col span={22}>
                    <Button type="primary"
                            className="horizontally-centered"
                            onClick={event =>  window.location.href='/solutions/xai_toolbox'}
                    >
                        Zur XAI Toolbox!
                    </Button>
                </Col>
            </Row>
        </div>
    )
}

function CarouselHochwasser() {
    return (
        <div>
            <Row justify="center">
                <Col span={22}>
                    <img src={img_industry_hws} className="img-full" alt=""/>
                </Col>
            </Row>
            <Row justify="center" className="padded-t25">
                <Col span={22}>
                    {CarouselTitleHochwasser("centered")}
                </Col>
            </Row>
            <Row justify="center">
                <Col span={22}>
                    <CarouselTextHochwasser />
                </Col>
            </Row>
        </div>
    )
}

function CarouselTiefziehen() {
    return (
        <div>
            <Row justify="center">
                <Col span={22}>
                    <img src={img_industry_dd} className="img-full" alt=""/>
                </Col>
            </Row>
            <Row justify="center" className="padded-t25">
                <Col span={22}>
                    {CarouselTitleTiefziehen("centered")}
                </Col>
            </Row>
            <Row justify="center">
                <Col span={22}>
                    <CarouselTextTiefziehen />
                </Col>
            </Row>
        </div>
    )
}

function CarouselTapes() {
    return (
        <div>
            <Row justify="center">
                <Col span={22}>
                    <img src={img_industry_tape} className="img-full" alt=""/>
                </Col>
            </Row>
            <Row justify="center" className="padded-t25">
                <Col span={22}>
                    {CarouselTitleTapes("centered")}
                </Col>
            </Row>
            <Row justify="center">
                <Col span={22}>
                    <CarouselTextTapes />
                </Col>
            </Row>
        </div>
    )
}

function CarouselWindshields() {
    return (
        <div>
            <Row justify="center">
                <Col span={22}>
                    <img src={img_industry_windshield} className="img-full" alt=""/>
                </Col>
            </Row>
            <Row justify="center" className="padded-t25">
                <Col span={22}>
                    {CarouselTitleWindshields("centered")}
                </Col>
            </Row>
            <Row justify="center">
                <Col span={22}>
                    <CarouselTextWindshields />
                </Col>
            </Row>
        </div>
    )
}