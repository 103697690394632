import React from "react";
import {useMediaQuery} from "react-responsive";
import PhilosophyXXL from "../components/pages/7_xxl/philosophy_xxl";
import PhilosophyMega from "../components/pages/8_mega/philosophy_mega";
import PhilosophyXL from "../components/pages/6_xl/philosophy_xl";
import PhilosophyLG from "../components/pages/5_lg/philosophy_lg";
import PhilosophyMD from "../components/pages/4_md/philosophy_md";
import PhilosophySM from "../components/pages/3_sm/philosophy_sm";
import PhilosophyXS from "../components/pages/2_xs/philosophy_xs";
import PhilosophyXXS from "../components/pages/1_xxs/philosophy_xxs";


export default function Philosophy() {
    const size_xxs = useMediaQuery({query: "(max-device-width: 479px)"});
    const size_xs = useMediaQuery({query: "(min-device-width: 480px) and (max-device-width: 577px)"});
    const size_sm = useMediaQuery({query: "(min-device-width: 578px) and (max-device-width: 767px)"});
    const size_md = useMediaQuery({query: "(min-device-width: 768px) and (max-device-width: 991px)" });
    const size_lg = useMediaQuery({query: "(min-device-width: 992px) and (max-device-width: 1199px)"});
    const size_xl = useMediaQuery({query: "(min-device-width: 1200px) and (max-device-width: 1599px)"});
    const size_xxl = useMediaQuery({query: "(min-device-width: 1600px) and (max-device-width: 1919px)"});
    const size_mega = useMediaQuery({query: "(min-device-width: 1920px)"});


    return (
        <div>
            {size_xxs && <PhilosophyXXS />}
            {size_xs && <PhilosophyXS />}
            {size_sm && <PhilosophySM />}
            {size_md && <PhilosophyMD />}
            {size_lg && <PhilosophyLG/>}
            {size_xl && <PhilosophyXL />}
            {size_xxl && <PhilosophyXXL />}
            {size_mega && <PhilosophyMega />}
        </div>
    );
}