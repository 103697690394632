import {Col, Row} from "antd";
import React, {useLayoutEffect, useRef, useState} from "react";
import img_why from "../../../images/why.webp";
import img_why1 from "../../../images/why1b.webp";
import img_why2 from "../../../images/why2b.webp";
import img_why3 from "../../../images/why3b.webp";
import img_why4 from "../../../images/why4b.webp";
import {
    QuoteWhyHighlight,
    TextWhy1,
    TextWhy2,
    TextWhy3,
    TextWhy4,
    TextWhyHighlight,
    TextWhyShort,
    TitleWhy
} from "../../text_content";


export default function MissionLG() {
    return (
        <div>
            <Row justify="center" className="bg-dark padded-t50">
                <Col span={18}>
                    <Row>
                        <Col span={24}>
                            <QuoteWhyHighlight />
                        </Col>
                    </Row>

                    <Row align="middle" justify="center" className="bg-dark padded-t50">
                        <Col span={4}>
                            <img src={img_why} className="img-full" alt=""/>
                        </Col>
                        <Col span={12} offset={2}>
                            {TextWhyShort("light-text centered")}
                        </Col>
                    </Row>

                    <Row justify="space-around" className="bg-dark padded-t50 padded-b50">
                        <Col span={24}>
                            <TextWhyHighlight />
                        </Col>
                    </Row>
                </Col>
            </Row>

            <Row justify="center" className="padded-t50 padded-b50">
                <Col span={22}>
                    <Row>
                        <Part1 />
                    </Row>
                    <Row className="padded-t50">
                        <Part2 />
                    </Row>
                    <Row className="padded-t50">
                        <Part3 />
                    </Row>
                    <Row className="padded-t50">
                        <Part4 />
                    </Row>
                </Col>
            </Row>
        </div>
    );
}

function Part1() {
    const [heightImage, setHeightImage] = useState(0)
    const refColHeight = useRef<HTMLDivElement>(null)
    useLayoutEffect(() => {
        if(refColHeight.current) {
            setHeightImage(refColHeight.current.clientHeight);
        }
    }, []);

    return(
        <div>
            <Row>
                <Col flex="0 1 50%">
                    <TitleWhy />
                </Col>
            </Row>
            <Row justify="center" align="middle">
                <Col flex="0 1 50%" ref={refColHeight}>
                    <TextWhy1 />
                </Col>
                <Col flex="0 1 50%">
                    <img src={img_why1} className="img-scrap" alt="" style={{height: heightImage}}/>
                </Col>
            </Row>
        </div>
    )
}

function Part2() {
    const [heightImage, setHeightImage] = useState(0)
    const refColHeight = useRef<HTMLDivElement>(null)
    useLayoutEffect(() => {
        if(refColHeight.current) {
            setHeightImage(refColHeight.current.clientHeight);
        }
    }, []);

    return(
        <div>
            <Row justify="center" align="middle">
                <Col flex="0 1 50%">
                    <img src={img_why2} className="img-scrap" alt="" style={{height: heightImage}}/>
                </Col>
                <Col flex="0 1 50%" ref={refColHeight}>
                    <TextWhy2 />
                </Col>
            </Row>
        </div>
    )
}

function Part3() {
    const [heightImage, setHeightImage] = useState(0)
    const refColHeight = useRef<HTMLDivElement>(null)
    useLayoutEffect(() => {
        if(refColHeight.current) {
            setHeightImage(refColHeight.current.clientHeight);
        }
    }, []);

    return(
        <div>
            <Row justify="center" align="middle">
                <Col flex="0 1 50%" ref={refColHeight}>
                    <TextWhy3 />
                </Col>
                <Col flex="0 1 50%">
                    <img src={img_why3} className="img-scrap" alt="" style={{height: heightImage}}/>
                </Col>
            </Row>
        </div>
    )
}

function Part4() {
    const [heightImage, setHeightImage] = useState(0)
    const refColHeight = useRef<HTMLDivElement>(null)
    useLayoutEffect(() => {
        if(refColHeight.current) {
            setHeightImage(refColHeight.current.clientHeight);
        }
    }, []);

    return(
        <div>
            <Row justify="center" align="middle">
                <Col flex="0 1 50%">
                    <img src={img_why4} className="img-scrap" alt="" style={{height: heightImage}}/>
                </Col>
                <Col flex="0 1 50%" ref={refColHeight}>
                    <TextWhy4 />
                </Col>
            </Row>
        </div>
    )
}

