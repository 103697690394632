import React from "react";
import {useMediaQuery} from "react-responsive";
import HomeXXS from "../components/pages/1_xxs/home_xxs";
import HomeXS from "../components/pages/2_xs/home_xs";
import HomeSM from "../components/pages/3_sm/home_sm";
import HomeMD from "../components/pages/4_md/home_md";
import HomeLG from "../components/pages/5_lg/home_lg";
import HomeXL from "../components/pages/6_xl/home_xl";
import HomeXXL from "../components/pages/7_xxl/home_xxl";
import HomeMega from "../components/pages/8_mega/home_mega";


export default function Home() {
    const size_xxs= useMediaQuery({query: "(max-device-width: 479px)"});
    const size_xs= useMediaQuery({query: "(min-device-width: 480px) and (max-device-width: 577px)"});
    const size_sm= useMediaQuery({query: "(min-device-width: 578px) and (max-device-width: 767px)"});
    const size_md= useMediaQuery({query: "(min-device-width: 768px) and (max-device-width: 991px)" });
    const size_lg= useMediaQuery({query: "(min-device-width: 992px) and (max-device-width: 1199px)"});
    const size_xl= useMediaQuery({query: "(min-device-width: 1200px) and (max-device-width: 1599px)"});
    const size_xxl= useMediaQuery({query: "(min-device-width: 1600px) and (max-device-width: 1919px)"});
    const size_mega= useMediaQuery({query: "(min-device-width: 1920px)"});

    return (
        <div>
            {size_xxs && <HomeXXS />}
            {size_xs && <HomeXS />}
            {size_sm && <HomeSM />}
            {size_md && <HomeMD />}
            {size_lg && <HomeLG/>}
            {size_xl && <HomeXL />}
            {size_xxl && <HomeXXL />}
            {size_mega&& <HomeMega />}
        </div>
    );
}