export let dataVizBar: number[][] = [
    [250, 220, 198, 178, 164, 151, 133],
    [348, 414, 669, 786, 1103, 1345, 1666],
    [598, 634, 867, 1064, 1267, 1496, 1799]
]

export let dataVizBar2: number[][] = [
    [250, 220, 198, 178, 164, 151, 133],
    [348, 414, 669, 786, 1103, 1345, 1666],
]

let dataSensor1 = [
    0.01893041, 0.02141399, 0.05415681, 0.06124686, 0.0212672 ,
    0.03759759, 0.00010297, 0.00269273, 0.06423706, 0.04147982,
    0.04967528, 0.01957659, 0.03230341, 0.0454483 , 0.03449195,
    0.05688033, 0.04641041, 0.05112831, 0.02491607, 0.0656921 ,
    0.05807102, 0.06063074, 0.03132644, 0.04327196, 0.06609822,
    0.00662876, 0.03836248, 0.03074698, 0.05068403, 0.03723923,
    0.04956255, 0.06001224, 0.03852604, 0.00398756, 0.02076234,
    0.01723898, 0.01975015, 0.02684746, 0.05961433, 0.01132297,
    0.03209309, 0.01981766, 0.03067314, 0.03697197, 0.03640568,
    0.02431064, 0.0273404 , 0.05713943, 0.06620097, 0.01226472,
    0.05830094, 0.03069808, 0.02327361, 0.01614953, 0.0249181 ,
    0.03322559, 0.02332129, 0.03323742, 0.05854196, 0.00812903,
    0.05625345, 0.02748187, 0.03379709, 0.04112113, 0.02167064,
    0.00412794, 0.04271924, 0.02666637, 0.05778048, 0.01560275,
    0.00985912, 0.01963321, 0.0500293 , 0.0554724 , 0.05425091,
    0.0446646 , 0.06030375, 0.04051843, 0.03138145, 0.00396879,
    0.0420178 , 0.06039704, 0.0488921 , 0.00358958, 0.04591901,
    0.01812033, 0.0357043 , 0.03057091, 0.04598912, 0.01675768,
    0.00372052, 0.04043043, 0.05986909, 0.05011965, 0.03932194,
    0.04784894, 0.01345628, 0.01704408, 0.03095743, 0.03818087,
    0.06510118, 0.01065848, 0.06046244, 0.00255972, 0.05374689,
    0.01646612, 0.02247125, 0.04917561, 0.04832993, 0.00921465,
    0.00274765, 0.02583766, 0.06503873, 0.04079433, 0.03657985,
    0.01354261, 0.03845114, 0.01349695, 0.03775917, 0.04929859,

    0.10811574, 0.11021121, 0.11237371, 0.12066496, 0.13463165,
    0.14885866, 0.19880188, 0.16921525, 0.17944114, 0.21301489,
    0.20494251, 0.23824521, 0.25708762, 0.27842481, 0.31639805,
    0.33853413, 0.34825658, 0.39555916, 0.41413152, 0.45712036,
    0.48813405, 0.52971985, 0.5948431 , 0.59464247, 0.6618735 ,
    0.73424958, 0.7883396 , 0.85899081, 0.9123369 , 0.99895353,

    1.01458707, 1.01708344, 0.97194839, 0.94648141, 0.90222726,
    0.8353924 , 0.77319145, 0.71186505, 0.64259712, 0.52249534,
    0.42568733, 0.3198222 , 0.21634658, 0.10760265, 0.01053352,

    0.01350913, 0.03277242, 0.01999881, 0.03928054, 0.03536555,
    0.03728636, 0.04333602, 0.05666383, 0.02217014, 0.03722502,
    0.06611976, 0.05813732, 0.02987232, 0.0293936 , 0.04011898,
    0.03932028, 0.05549842, 0.02529098, 0.03597167, 0.02117739,
    0.0091821 , 0.03987487, 0.01300964, 0.04719996, 0.01112413,
    0.05417415, 0.02542329, 0.06656649, 0.03722883, 0.02380328,
    0.02134593, 0.03327238, 0.04747087, 0.04783539, 0.04229788]

let dataSensor2 = [
    0.0272908 , 0.02686166, 0.04350812, 0.06161981, 0.03826121,
    0.065345  , 0.04124542, 0.01678586, 0.03832985, 0.03561859,
    0.00034573, 0.04416724, 0.06352201, 0.02053204, 0.02273173,
    0.0594011 , 0.01854987, 0.02793003, 0.01114399, 0.02926242,
    0.0641637 , 0.06557642, 0.02940706, 0.02809539, 0.0125696 ,
    0.00765741, 0.00102473, 0.00595102, 0.00578376, 0.05203431,
    0.00694967, 0.05469125, 0.04250204, 0.04340102, 0.03477246,
    0.03241182, 0.05726682, 0.03804461, 0.02401445, 0.01249143,
    0.0104761 , 0.01977823, 0.03445319, 0.06040795, 0.04126185,
    0.00347388, 0.04823937, 0.05110953, 0.03696993, 0.05326248,
    0.00441774, 0.01967447, 0.01800029, 0.02484318, 0.0352934 ,
    0.03448995, 0.04853954, 0.06106735, 0.05478417, 0.00910441,
    0.05586386, 0.04874882, 0.02794933, 0.05111605, 0.04276891,
    0.00428358, 0.05583424, 0.00317233, 0.02116907, 0.06089462,
    0.02634849, 0.03707115, 0.06289948, 0.05992758, 0.0590969 ,
    0.02979673, 0.03381687, 0.00910071, 0.03581015, 0.05526277,
    0.06656628, 0.05271953, 0.04406905, 0.02237931, 0.03511273,
    0.04269625, 0.04399578, 0.05932874, 0.02984683, 0.03455263,
    0.01252423, 0.02404118, 0.02507891, 0.015278  , 0.00137227,
    0.0497378 , 0.01365648, 0.00104808, 0.04907959, 0.00861048,
    0.02577242, 0.03625065, 0.03369578, 0.0341022 , 0.05119429,
    0.03228767, 0.06148118, 0.03050843, 0.01900064, 0.00969312,
    0.03617545, 0.05444608, 0.03128216, 0.02502331, 0.04129907,

    0.09369015, 0.117901  , 0.13071545, 0.12237488, 0.13232515,
    0.14106745, 0.14843897, 0.1662784 , 0.18156255, 0.17670299,
    0.21039545, 0.22668073, 0.23029995, 0.24122613, 0.24525958,
    0.27267659, 0.32021083, 0.31807317, 0.34834318, 0.37638081,
    0.3695964 , 0.41569888, 0.43145469, 0.4721353 , 0.50231201,
    0.53139276, 0.57852287, 0.6234127 , 0.66459379, 0.70936516,
    0.75819411, 0.81499678, 0.86948375, 0.92737419, 1.004496,

    1.00224662,  0.99965024,  0.958301  ,  0.92407432,  0.82961301,
    0.75032333,  0.65219798,  0.53811801,  0.42346714,  0.2977151 ,
    0.13666567, 0.0103209,

    0.02446235, 0.00160781, 0.04911284, 0.00615162, 0.01871099,
    0.02133123, 0.02472489, 0.05168925, 0.02269677, 0.0311931 ,
    0.04528056, 0.04934567, 0.0306426 , 0.0233601 , 0.04527593,
    0.03787994, 0.02165341, 0.00307999, 0.03985303, 0.0344078 ,
    0.03062977, 0.03604326, 0.02740182, 0.02470992, 0.02122065,
    0.04757277, 0.031365  , 0.03673108, 0.00544327, 0.04645713,
    0.0042779 , 0.03569597, 0.03677851, 0.00547147, 0.02697669,
    0.04470415, 0.00167494, 0.00012582
]

let dataSensor3 = [
    0.01658047, 0.03865841, 0.01732789, 0.01646446, 0.05971902,
    0.01466226, 0.04919615, 0.003852  , 0.0396228 , 0.03560176,
    0.06292429, 0.02087809, 0.04641888, 0.01603307, 0.0259265 ,
    0.01949665, 0.02979036, 0.0283569 , 0.00361803, 0.02052526,
    0.00409498, 0.0226929 , 0.04604062, 0.01705337, 0.04767121,
    0.00016896, 0.01456556, 0.0428896 , 0.05673442, 0.04974778,
    0.0481538 , 0.02692384, 0.06611587, 0.04079256, 0.05028283,
    0.04488995, 0.04232318, 0.02727938, 0.02301405, 0.0620518 ,
    0.03101144, 0.03605559, 0.00294762, 0.0114579 , 0.02568245,
    0.00494267, 0.02701854, 0.04296612, 0.02840359, 0.0642227 ,
    0.05536402, 0.02839119, 0.00393595, 0.06275019, 0.03875206,
    0.01819165, 0.01345778, 0.03378859, 0.05004562, 0.05123787,
    0.04053907, 0.06368675, 0.04821974, 0.03177651, 0.03438204,
    0.00037557, 0.05116489, 0.0533465 , 0.03436095, 0.05663013,
    0.00669176, 0.02278863, 0.02813404, 0.02578601, 0.00084125,
    0.05829432, 0.03074285, 0.04556625, 0.01534715, 0.01969988,
    0.05028636, 0.05155864, 0.04230214, 0.01455979, 0.02168297,
    0.02298588, 0.00528286, 0.00425619, 0.06024927, 0.04941396,
    0.01260955, 0.01056223, 0.0350845 , 0.05336637, 0.01211503,
    0.00467858, 0.06610654, 0.0020273 , 0.01706921, 0.06634611,
    0.02522798, 0.06193912, 0.04395437, 0.02507518, 0.03419842,
    0.02591653, 0.00023551, 0.05757491, 0.01218455, 0.00545208,
    0.05678612, 0.05686798, 0.06537814, 0.03830946, 0.05945172,

    0.08622672, 0.09994511, 0.10007453, 0.12146325, 0.13793232,
    0.14534018, 0.17136766, 0.17031954, 0.19132059, 0.20557252,
    0.24607618, 0.24025681, 0.26288988, 0.28018073, 0.31504118,
    0.33969124, 0.36500012, 0.3655053 , 0.42054743, 0.45105937,
    0.49094328, 0.53777958, 0.57291884, 0.62792189, 0.69212063,
    0.72581078, 0.77806972, 0.85270634, 0.90263431, 1.00832103,

    0.98768258, 1.00667231, 0.97173776, 0.97287702, 0.94445765,
    0.92100298, 0.87680374, 0.83583544, 0.79673958, 0.73921373,
    0.68882738, 0.6269469 , 0.53566227, 0.46774686, 0.40270959,
    0.32020269, 0.24401852, 0.15846639, 0.06667982, 0.02302086,

    0.02779777, 0.01424509, 0.02223555, 0.00789862, 0.0051872 ,
    0.01657787, 0.02956901, 0.00952585, 0.0267135 , 0.00223844,
    0.0120129 , 0.03768387, 0.02902009, 0.03220187, 0.0417626 ,
    0.00026567, 0.04277156, 0.03087699, 0.0070076 , 0.01374813,
    0.03783917, 0.02695805, 0.00980109, 0.03236952, 0.00598234,
    0.02130541, 0.00383041, 0.01937767, 0.02651278, 0.02587643,
    0.00178499, 0.00155459, 0.02049184, 0.02175332, 0.04343938
]

let dataSensor4 = [
    0.01668772, 0.02635211, 0.02323414, 0.03030143, 0.03853199,
    0.04123913, 0.02002388, 0.00653926, 0.04141266, 0.03179442,
    0.02974379, 0.02638395, 0.02158648, 0.03017252, 0.03941775,
    0.02145314, 0.03829395, 0.00753207, 0.02310417, 0.03403044,
    0.02132462, 0.00380121, 0.03418011, 0.00838654, 0.02372353,
    0.00181712, 0.01890717, 0.02021756, 0.01498127, 0.01492076,
    0.02553123, 0.01104408, 0.0388482 , 0.00709804, 0.01111434,
    0.03359015, 0.01995043, 0.03406197, 0.00701781, 0.02393402,
    0.03257186, 0.01961604, 0.01547783, 0.02400219, 0.02815011,
    0.03855937, 0.02178382, 0.02674943, 0.0022684 , 0.00730431,
    0.03821307, 0.02334479, 0.03882179, 0.0047952 , 0.03958425,
    0.03752701, 0.02934196, 0.00554486, 0.03966315, 0.02493628,
    0.01424951, 0.04120436, 0.01314824, 0.01749014, 0.0281961 ,
    0.03102318, 0.00231662, 0.00479503, 0.00297596, 0.0219121 ,
    0.00306525, 0.03828819, 0.02041657, 0.00821553, 0.01535929,
    0.0233762 , 0.0104882 , 0.03526773, 0.01237429, 0.03856262,
    0.00476028, 0.03516004, 0.02404733, 0.03702779, 0.01103797,
    0.00011194, 0.00560565, 0.02431145, 0.03192133, 0.02747377,
    0.00355003, 0.0355641 , 0.03518103, 0.01453158, 0.00482294,
    0.00034816, 0.00010606, 0.03554941, 0.02942804, 0.00762237,
    0.03811478, 0.01363689, 0.02035075, 0.02598148, 0.000739  ,
    0.02247053, 0.03993174, 0.01048886, 0.00983411, 0.02408789,
    0.01399325, 0.0336983 , 0.03954609, 0.01492957, 0.01642545,
    0.03264296, 0.03556124,

    0.10218972, 0.10488126, 0.10840169, 0.13962079, 0.15282896,
    0.16107843, 0.16349217, 0.17057244, 0.19901646, 0.1931745 ,
    0.21392336, 0.24181596, 0.25140645, 0.27333487, 0.30612166,
    0.3278922 , 0.34747237, 0.39067868, 0.40655414, 0.44496113,
    0.48228222, 0.5343939 , 0.57585413, 0.616053  , 0.67076378,
    0.72545564, 0.79248353, 0.8428476 , 0.91692892, 0.97877819,

    0.99881155, 0.98695059, 0.97863073, 0.95181499, 0.90447152,
    0.84951918, 0.7810618 , 0.7069919 , 0.61089797, 0.55221283,
    0.41928371, 0.32028838, 0.23356963, 0.09288667, 0.00813106,

    0.01563913, 0.01182842, 0.04281637, 0.01135901, 0.00855261,
    0.03706554, 0.00152848, 0.03823833, 0.01059982, 0.04352443,
    0.01477694, 0.00194641, 0.03326684, 0.04265071, 0.00510152,
    0.0425159 , 0.02085053, 0.02305308, 0.03263773, 0.01207999,
    0.02699027, 0.02378676, 0.02817059, 0.02020412, 0.02551676,
    0.00284504, 0.02730558, 0.03383591, 0.03873714, 0.01053604,
    0.00973656, 0.04214375, 0.00132266, 0.02322534, 0.04713474,
    0.026235  , 0.00542161, 0.03068701
]

let dataSensor5 = [
    0.03518814, 0.0294525 , 0.03700539, 0.03421059, 0.03122901,
    0.02515588, 0.03586042, 0.02832193, 0.00161925, 0.00817411,
    0.01524902, 0.02745699, 0.00406367, 0.03176473, 0.03910076,
    0.00172766, 0.01750534, 0.00376155, 0.02203271, 0.03465463,
    0.02165291, 0.01437977, 0.03766795, 0.01169394, 0.02202972,
    0.03576046, 0.03302381, 0.03395886, 0.0158407 , 0.03132507,
    0.0151175 , 0.02609358, 0.0130633 , 0.0453941 , 0.01460615,
    0.01730647, 0.03751693, 0.03595514, 0.04262231, 0.03051897,
    0.02490678, 0.02270217, 0.03344765, 0.02076112, 0.02462081,
    0.02668787, 0.02262771, 0.02513554, 0.04405016, 0.01687316,
    0.03149828, 0.00135037, 0.04484273, 0.00661957, 0.00606489,
    0.04334491, 0.02601753, 0.04483688, 0.00022572, 0.01566733,
    0.02769132, 0.0155161 , 0.03182105, 0.02725301, 0.03456634,
    0.04226224, 0.00441406, 0.04109253, 0.02729653, 0.03772237,
    0.01882513, 0.00559338, 0.01814532, 0.03346296, 0.00587722,
    0.02639922, 0.00893999, 0.03804422, 0.02520779, 0.01959679,
    0.03758061, 0.01575887, 0.00065898, 0.0044615 , 0.02595259,
    0.03087716, 0.00232998, 0.00572327, 0.00278417, 0.00446325,
    0.0199314 , 0.00292423, 0.0063171 , 0.0059054 , 0.0201832 ,
    0.00770792, 0.03707194, 0.02144794, 0.01867042, 0.03865932,
    0.01948968, 0.02212256, 0.03396202, 0.03834985, 0.04224338,
    0.02326278, 0.04116232, 0.01868153, 0.01662697, 0.00758119,
    0.02832561, 0.03913587, 0.04214422, 0.00224329, 0.00918321,
    0.00697521, 0.02898967, 0.03018578, 0.03519995, 0.00350065,
    0.04391157, 0.01129849,

    0.1006441 , 0.09976626, 0.1320351 , 0.11519288, 0.16601711,
    0.1576119 , 0.16660249, 0.20179518, 0.21759912, 0.23609139,
    0.2656298 , 0.29088781, 0.31550343, 0.37314957, 0.38945536,
    0.41795156, 0.46238261, 0.51429289, 0.56194428, 0.63789528,
    0.66782812, 0.74657277, 0.82891283, 0.9189347 , 1.0126316,

    1.02596204, 0.9910529 , 0.98287418, 0.94312445, 0.90004282,
    0.85349681, 0.78427783, 0.71137277, 0.61413913, 0.53041072,
    0.42372234,

    0.0007211 , 0.04393526, 0.03544929, 0.00301823, 0.02866715,
    0.00416648, 0.01669422, 0.02594944, 0.03042588, 0.02919251,
    0.04515401, 0.02339639, 0.02145539, 0.03162516, 0.01882001,
    0.00977689, 0.0334942 , 0.03699728, 0.00361434, 0.0115157 ,
    0.00240961, 0.01014095, 0.01742763, 0.03067671, 0.0125854 ,
    0.01877556, 0.00642339, 0.0291336 , 0.02340524, 0.01304916,
    0.00876136, 0.00995597, 0.01898543, 0.01209504, 0.03944347,
    0.03244964, 0.00305433, 0.01855377, 0.02339639, 0.02145539,
    0.03699728, 0.00361434
]

let dataSensor6 = [
    0.02444884, 0.02953178, 0.03471928, 0.0383718 , 0.01114075,
    0.03668228, 0.00322137, 0.02578095, 0.01754753, 0.0328443 ,
    0.0346593 , 0.00587625, 0.00114358, 0.0094462 , 0.02790086,
    0.01753814, 0.02896745, 0.011484  , 0.02418619, 0.02142528,
    0.00948847, 0.0253143 , 0.02140326, 0.00753563, 0.02062853,
    0.00222713, 0.03084688, 0.01204235, 0.02613568, 0.01486828,
    0.02667898, 0.00308611, 0.00761706, 0.03476593, 0.02602126,
    0.03252652, 0.03463714, 0.01337124, 0.01286276, 0.02049935,
    0.02864893, 0.01305093, 0.03943406, 0.02379883, 0.02116316,
    0.03033134, 0.0303191 , 0.01325683, 0.02656819, 0.017426  ,
    0.02440128, 0.0286081 , 0.01664158, 0.00993882, 0.01957067,
    0.01034974, 0.0179775 , 0.01252986, 0.03503724, 0.00921055,
    0.03256432, 0.03922494, 0.00911241, 0.02790553, 0.02373278,
    0.0018357 , 0.03687165, 0.00902767, 0.01269299, 0.01230564,
    0.02599288, 0.02562481, 0.00894104, 0.0300818 , 0.03728719,
    0.00385031, 0.0130522 , 0.02460251, 0.03121554, 0.00898649,
    0.00692437, 0.03095674, 0.01585029, 0.00548391, 0.00800736,
    0.00166848, 0.01406727, 0.02539857, 0.00165328, 0.02066791,
    0.03794703, 0.01154009, 0.02081262, 0.02028435, 0.03052701,
    0.02983858, 0.00671235, 0.02757762, 0.01436995, 0.02820531,
    0.00296118, 0.00802612, 0.01968817, 0.02590976, 0.02312981,
    0.03731894, 0.03424965, 0.00043012, 0.00120257, 0.01411528,
    0.03178029, 0.00046931, 0.03361894, 0.02250847, 0.03446387,
    0.03648563, 0.00538227, 0.00631212, 0.03867612, 0.01323778,
    0.01414787, 0.01272495, 0.01729095, 0.03904238, 0.01638003,

    0.11159879, 0.09407539, 0.11249478, 0.16649144, 0.16228683,
    0.16893987, 0.18918874, 0.22866463, 0.25969915, 0.28437099,
    0.30708873, 0.34687519, 0.37929868, 0.44170872, 0.50602856,
    0.54638462, 0.63188168, 0.70147282, 0.78147467, 0.89252257,
    0.9847521,

    1.00586597,  1.00222205,  0.99347654,  0.95057955,  0.92586229,
    0.88630947,  0.82474815,  0.78256425,  0.69822785,  0.63840844,
    0.56243792,  0.45787799,  0.38289076,  0.27704916,  0.21258917,
    0.09447205, 0.01013721,

    0.02696133, 0.00636133, 0.00659074, 0.00191318, 0.01171927,
    0.03238043, 0.03829583, 0.01846505, 0.03007555, 0.01820644,
    0.00296131, 0.01523316, 0.01453238, 0.01652397, 0.03380641,
    0.03725223, 0.00571611, 0.02122561, 0.01822581, 0.03133396,
    0.02326621, 0.03516822, 0.03251207, 0.0053442 , 0.03340412,
    0.03459179, 0.00013287, 0.02331977, 0.02911684, 0.01494937,
    0.01899074, 0.0129871 , 0.00389305, 0.00731833, 0.0016586 ,
    0.02458912, 0.0395759
]

let dataSensor7 = [
    0.03238972, 0.00947889, 0.04209766, 0.05466991, 0.00963632,
    0.03901977, 0.0311574 , 0.05833619, 0.00373234, 0.03282126,
    0.00697415, 0.0246011 , 0.0152135 , 0.03127419, 0.02454458,
    0.01127782, 0.02415987, 0.00898853, 0.03759348, 0.00537345,
    0.03804784, 0.05606099, 0.05244415, 0.03714057, 0.00493769,
    0.02529435, 0.04778366, 0.00716074, 0.05616072, 0.00409864,
    0.01367076, 0.00275562, 0.02900269, 0.0247568 , 0.0350672 ,
    0.00572453, 0.00620309, 0.03079775, 0.04090489, 0.01427109,
    0.05417784, 0.02254625, 0.02264145, 0.02461659, 0.03046368,
    0.00097342, 0.0168041 , 0.0564406 , 0.02649779, 0.05767406,
    0.01925907, 0.01449454, 0.00559659, 0.02514083, 0.00540778,
    0.02995826, 0.02144702, 0.05176363, 0.01579882, 0.01156297,
    0.03325069, 0.05214478, 0.05557843, 0.04500277, 0.01794088,
    0.03309411, 0.05033509, 0.0530267 , 0.02246261, 0.04133973,
    0.05450784, 0.03937523, 0.05565703, 0.01396403, 0.02219205,
    0.01384617, 0.01138234, 0.02867383, 0.00463885, 0.03893584,
    0.05509638, 0.04447701, 0.04906448, 0.02390224, 0.04611289,
    0.00523791, 0.05239521, 0.03919334, 0.0535865 , 0.04036503,
    0.01562802, 0.0324511 , 0.03715186, 0.01344933, 0.00309321,
    0.03195483, 0.05022745, 0.0051497 , 0.01468235, 0.00754559,
    0.0567581 , 0.01733084, 0.02249556, 0.01960225, 0.04130043,
    0.00437535, 0.04726565, 0.01632832, 0.04981565, 0.03530602,
    0.01129094, 0.0584106 , 0.02765897, 0.05030682, 0.02102645,
    0.00629109, 0.01749379, 0.01130962, 0.0344175 , 0.01614577,

    0.09150758, 0.10385174, 0.11940459, 0.15761304, 0.14838697,
    0.16489892, 0.17778944, 0.21166453, 0.23621173, 0.26841026,
    0.29118323, 0.31790988, 0.3598357 , 0.38479179, 0.44464799,
    0.48027771, 0.53936631, 0.60509401,

    0.625     , 0.46868388, 0.35146333, 0.26356031, 0.19764235,

    0.21039817, 0.25311068, 0.29264469, 0.38848281, 0.43365347,
    0.52618045, 0.59608109, 0.71390259, 0.83461003, 1.00582928,

    0.99873043, 0.98214629, 0.94093197, 0.86635284, 0.77045311,
    0.65141785, 0.50777955, 0.3268524 , 0.17547183, 0.00149277,

    0.02574342, 0.02350254, 0.00607378, 0.00262469, 0.01198454,
    0.01002305, 0.01647237, 0.01038798, 0.02455202, 0.02348015,
    0.03230985, 0.03418077, 0.03560505, 0.04450918, 0.00988674,
    0.02738296, 0.04395893, 0.00057941, 0.00656212, 0.03839115,
    0.04346554, 0.00617867, 0.02226036, 0.01152284, 0.03126438,
    0.04471931, 0.05257967, 0.03400838, 0.03983734, 0.03207282,
    0.00481376, 0.02415742, 0.04702031, 0.00777594, 0.01960706,
    0.00726362, 0.00733812
]

let dataSensor8 = [
    0.04510615, 0.0295144 , 0.01823541, 0.07139851, 0.06602201,
    0.03444396, 0.06067336, 0.06564265, 0.0221048 , 0.05532274,
    0.06743497, 0.03701176, 0.02270468, 0.00947869, 0.06126279,
    0.04822533, 0.06356711, 0.0498683 , 0.00939759, 0.02163064,
    0.07654596, 0.07443425, 0.01484728, 0.01151276, 0.02598017,
    0.01455089, 0.01316018, 0.01697033, 0.05269848, 0.00650502,
    0.02956896, 0.0086223 , 0.03613741, 0.06573943, 0.00936815,
    0.02464179, 0.00248463, 0.04112089, 0.04686793, 0.03903112,
    0.05389428, 0.01176328, 0.05644868, 0.00114949, 0.00231404,
    0.05145378, 0.01977795, 0.01665521, 0.00844146, 0.02807921,
    0.01591216, 0.0113739 , 0.00472348, 0.02049535, 0.05651813,
    0.03313611, 0.05368247, 0.02440362, 0.05324091, 0.01009962,
    0.05433453, 0.06112793, 0.06303952, 0.03612223, 0.0301082 ,
    0.06484672, 0.00925457, 0.01483418, 0.04798781, 0.05110603,
    0.04015533, 0.02395799, 0.05683668, 0.01387336, 0.07135473,
    0.0066872 , 0.06476723, 0.00655519, 0.07133042, 0.00175805,
    0.00623132, 0.00377441, 0.05829817, 0.05115475, 0.0705031 ,
    0.02586974, 0.06677602, 0.05351111, 0.05245277, 0.02348485,
    0.05122347, 0.02523415, 0.0732884 , 0.06097844, 0.01497923,
    0.06001188, 0.01164769, 0.02243017, 0.07001821, 0.02229911,
    0.02966379, 0.03054644, 0.04021343, 0.03697637, 0.01020591,
    0.0450664 , 0.0051486 , 0.02500464, 0.07222972, 0.02898797,
    0.00951645, 0.06769717, 0.00485013, 0.03410993, 0.02778935,
    0.00759896, 0.07081477, 0.00714373, 0.0014753 , 0.0560769 ,
    0.05718893, 0.01628472, 0.06554905,

    0.09257404, 0.09789268, 0.12258591, 0.17294453, 0.162705  ,
    0.18999561, 0.21755871, 0.22292238, 0.26701037, 0.26214714,
    0.33066732, 0.3627126 , 0.39688462, 0.50731054, 0.53363206,
    0.59536015, 0.64305406, 0.75552366,

    0.75141527, 0.65445534, 0.57000677, 0.49645515,

    0.50331554, 0.58834449, 0.71627436, 0.83918676, 1.00434625,

    1.02299388, 0.99537477, 0.98523279, 0.95175674, 0.88800928,
    0.83473227, 0.77545816, 0.71675656, 0.6223819 , 0.54969763,
    0.44799227, 0.31874658, 0.20299668, 0.14177923, 0.00843017,

    0.0015368 , 0.02046864, 0.02231433, 0.06895755, 0.00457986,
    0.05374883, 0.02637418, 0.01829006, 0.05891441, 0.02957878,
    0.00754478, 0.05789965, 0.06839815, 0.00013916, 0.05406834,
    0.02785397, 0.05107762, 0.00180225, 0.0284405 , 0.04428898,
    0.04775344, 0.00805155, 0.06964756, 0.03551795, 0.0157549 ,
    0.01567969, 0.06997883, 0.05754615, 0.03841817, 0.05927013,
    0.02856274, 0.03615028, 0.03249122, 0.03333549, 0.05191305
]

export let dataVizLine: number[][] = [
    dataSensor1,
    dataSensor2,
    dataSensor3,
    dataSensor4,
    dataSensor5,
    dataSensor6,
    dataSensor7,
    dataSensor8,
]

export let dataVizLine2: number[] = [
    ...dataSensor1,
    ...dataSensor2,
    ...dataSensor3,
    ...dataSensor7,
    ...dataSensor4,
    ...dataSensor5,
    ...dataSensor8,
    ...dataSensor6,
]

let dataNOK: number[][] = [
    [161.2, 51.6], [167.5, 59.0], [159.5, 49.2], [157.0, 63.0], [155.8, 53.6],
    [170.0, 59.0], [159.1, 47.6], [166.0, 69.8], [176.2, 66.8], [160.2, 75.2],
    [172.5, 55.2], [170.9, 54.2], [172.9, 62.5], [153.4, 42.0], [160.0, 50.0],
    [147.2, 49.8], [168.2, 49.2], [175.0, 73.2], [157.0, 47.8], [167.6, 68.8],
    [159.5, 50.6], [175.0, 82.5], [166.8, 57.2], [176.5, 87.8], [170.2, 72.8],
    [174.0, 54.5], [173.0, 59.8], [179.9, 67.3], [170.5, 67.8], [160.0, 47.0],
    [154.4, 46.2], [162.0, 55.0], [176.5, 83.0], [160.0, 54.4], [152.0, 45.8],
    [162.1, 53.6], [170.0, 73.2], [160.2, 52.1], [161.3, 67.9], [166.4, 56.6],
    [168.9, 62.3], [163.8, 58.5], [167.6, 54.5], [160.0, 50.2], [161.3, 60.3],
    [167.6, 58.3], [165.1, 56.2], [160.0, 50.2], [170.0, 72.9], [157.5, 59.8],
    [167.6, 61.0], [160.7, 69.1], [163.2, 55.9], [152.4, 46.5], [157.5, 54.3],
    [168.3, 54.8], [180.3, 60.7], [165.5, 60.0], [165.0, 62.0], [164.5, 60.3],
    [156.0, 52.7], [160.0, 74.3], [163.0, 62.0], [165.7, 73.1], [161.0, 80.0],
    [162.0, 54.7], [166.0, 53.2], [174.0, 75.7], [172.7, 61.1], [167.6, 55.7],
    [151.1, 48.7], [164.5, 52.3], [163.5, 50.0], [152.0, 59.3], [169.0, 62.5],
    [164.0, 55.7], [161.2, 54.8], [155.0, 45.9], [170.0, 70.6], [176.2, 67.2],
    [170.0, 69.4], [162.5, 58.2], [170.3, 64.8], [164.1, 71.6], [169.5, 52.8],
    [163.2, 59.8], [154.5, 49.0], [159.8, 50.0], [173.2, 69.2], [170.0, 55.9],
    [161.4, 63.4], [169.0, 58.2], [166.2, 58.6], [159.4, 45.7], [162.5, 52.2],
    [159.0, 48.6], [162.8, 57.8], [159.0, 55.6], [179.8, 66.8], [162.9, 59.4],
    [161.0, 53.6], [151.1, 73.2], [168.2, 53.4], [168.9, 69.0], [173.2, 58.4],
    [171.8, 56.2], [178.0, 70.6], [164.3, 59.8], [163.0, 72.0], [168.5, 65.2],
    [166.8, 56.6], [172.7, 105.2], [163.5, 51.8], [169.4, 63.4], [167.8, 59.0],
    [159.5, 47.6], [167.6, 63.0], [161.2, 55.2], [160.0, 45.0], [163.2, 54.0],
    [162.2, 50.2], [161.3, 60.2], [149.5, 44.8], [157.5, 58.8], [163.2, 56.4],
    [172.7, 62.0], [155.0, 49.2], [156.5, 67.2], [164.0, 53.8], [160.9, 54.4],
    [162.8, 58.0], [167.0, 59.8], [160.0, 54.8], [160.0, 43.2], [168.9, 60.5],
    [158.2, 46.4], [156.0, 64.4], [160.0, 48.8], [167.1, 62.2], [158.0, 55.5],
    [167.6, 57.8], [156.0, 54.6], [162.1, 59.2], [173.4, 52.7], [159.8, 53.2],
    [170.5, 64.5], [159.2, 51.8], [157.5, 56.0], [161.3, 63.6], [162.6, 63.2],
    [160.0, 59.5], [168.9, 56.8], [165.1, 64.1], [162.6, 50.0], [165.1, 72.3],
    [166.4, 55.0], [160.0, 55.9], [152.4, 60.4], [170.2, 69.1], [162.6, 84.5],
    [170.2, 55.9], [158.8, 55.5], [172.7, 69.5], [167.6, 76.4], [162.6, 61.4],
    [167.6, 65.9], [156.2, 58.6], [175.2, 66.8], [172.1, 56.6], [162.6, 58.6],
    [160.0, 55.9], [165.1, 59.1], [182.9, 81.8], [166.4, 70.7], [165.1, 56.8],
    [177.8, 60.0], [165.1, 58.2], [175.3, 72.7], [154.9, 54.1], [158.8, 49.1],
    [172.7, 75.9], [168.9, 55.0], [161.3, 57.3], [167.6, 55.0], [165.1, 65.5],
    [175.3, 65.5], [157.5, 48.6], [163.8, 58.6], [167.6, 63.6], [165.1, 55.2],
    [165.1, 62.7], [168.9, 56.6], [162.6, 53.9], [164.5, 63.2], [176.5, 73.6],
    [168.9, 62.0], [175.3, 63.6], [159.4, 53.2], [160.0, 53.4], [170.2, 55.0],
    [162.6, 70.5], [167.6, 54.5], [162.6, 54.5], [160.7, 55.9], [160.0, 59.0],
    [157.5, 63.6], [162.6, 54.5], [152.4, 47.3], [170.2, 67.7], [165.1, 80.9],
    [172.7, 70.5], [165.1, 60.9], [170.2, 63.6], [170.2, 54.5], [170.2, 59.1],
    [161.3, 70.5], [167.6, 52.7], [167.6, 62.7], [165.1, 86.3], [162.6, 66.4],
    [152.4, 67.3], [168.9, 63.0], [170.2, 73.6], [175.2, 62.3], [175.2, 57.7],
    [160.0, 55.4], [165.1, 104.1], [174.0, 55.5], [170.2, 77.3], [160.0, 80.5],
    [167.6, 64.5], [167.6, 72.3], [167.6, 61.4], [154.9, 58.2], [162.6, 81.8],
    [175.3, 63.6], [171.4, 53.4], [157.5, 54.5], [165.1, 53.6], [160.0, 60.0],
    [174.0, 73.6], [162.6, 61.4], [174.0, 55.5], [162.6, 63.6], [161.3, 60.9],
    [156.2, 60.0], [149.9, 46.8], [169.5, 57.3], [160.0, 64.1], [175.3, 63.6],
    [169.5, 67.3], [160.0, 75.5], [172.7, 68.2], [162.6, 61.4], [157.5, 76.8],
    [176.5, 71.8], [164.4, 55.5], [160.7, 48.6], [174.0, 66.4], [163.8, 67.3]
]

let dataOK: number[][] = [
    [174.0, 65.6], [175.3, 71.8], [193.5, 80.7], [186.5, 72.6], [187.2, 78.8],
    [181.5, 74.8], [184.0, 86.4], [184.5, 78.4], [175.0, 62.0], [184.0, 81.6],
    [180.0, 76.6], [177.8, 83.6], [192.0, 90.0], [176.0, 74.6], [174.0, 71.0],
    [184.0, 79.6], [192.7, 93.8], [171.5, 70.0], [173.0, 72.4], [176.0, 85.9],
    [176.0, 78.8], [180.5, 77.8], [172.7, 66.2], [176.0, 86.4], [173.5, 81.8],
    [178.0, 89.6], [180.3, 82.8], [180.3, 76.4], [164.5, 63.2], [173.0, 60.9],
    [183.5, 74.8], [175.5, 70.0], [188.0, 72.4], [189.2, 84.1], [172.8, 69.1],
    [170.0, 59.5], [182.0, 67.2], [170.0, 61.3], [177.8, 68.6], [184.2, 80.1],
    [186.7, 87.8], [171.4, 84.7], [172.7, 73.4], [175.3, 72.1], [180.3, 82.6],
    [182.9, 88.7], [188.0, 84.1], [177.2, 94.1], [172.1, 74.9], [167.0, 59.1],
    [169.5, 75.6], [174.0, 86.2], [172.7, 75.3], [182.2, 87.1], [164.1, 55.2],
    [163.0, 57.0], [171.5, 61.4], [184.2, 76.8], [174.0, 86.8], [174.0, 72.2],
    [177.0, 71.6], [186.0, 84.8], [167.0, 68.2], [171.8, 66.1], [182.0, 72.0],
    [167.0, 64.6], [177.8, 74.8], [164.5, 70.0], [192.0, 101.6], [175.5, 63.2],
    [171.2, 79.1], [181.6, 78.9], [167.4, 67.7], [181.1, 66.0], [177.0, 68.2],
    [174.5, 63.9], [177.5, 72.0], [170.5, 56.8], [182.4, 74.5], [197.1, 90.9],
    [180.1, 93.0], [175.5, 80.9], [180.6, 72.7], [184.4, 68.0], [175.5, 70.9],
    [180.6, 72.5], [177.0, 72.5], [177.1, 83.4], [181.6, 75.5], [176.5, 73.0],
    [175.0, 70.2], [174.0, 73.4], [165.1, 70.5], [177.0, 68.9], [192.0, 102.3],
    [176.5, 68.4], [169.4, 65.9], [182.1, 75.7], [179.8, 84.5], [175.3, 87.7],
    [184.9, 86.4], [177.3, 73.2], [167.4, 53.9], [178.1, 72.0], [168.9, 55.5],
    [157.2, 58.4], [180.3, 83.2], [170.2, 72.7], [177.8, 64.1], [172.7, 72.3],
    [165.1, 65.0], [186.7, 86.4], [165.1, 65.0], [174.0, 88.6], [175.3, 84.1],
    [185.4, 66.8], [177.8, 75.5], [180.3, 93.2], [180.3, 82.7], [177.8, 58.0],
    [177.8, 79.5], [177.8, 78.6], [177.8, 71.8], [177.8, 116.4], [163.8, 72.2],
    [188.0, 83.6], [198.1, 85.5], [175.3, 90.9], [166.4, 85.9], [190.5, 89.1],
    [166.4, 75.0], [177.8, 77.7], [179.7, 86.4], [172.7, 90.9], [190.5, 73.6],
    [185.4, 76.4], [168.9, 69.1], [167.6, 84.5], [175.3, 64.5], [170.2, 69.1],
    [190.5, 108.6], [177.8, 86.4], [190.5, 80.9], [177.8, 87.7], [184.2, 94.5],
    [176.5, 80.2], [177.8, 72.0], [180.3, 71.4], [171.4, 72.7], [172.7, 84.1],
    [172.7, 76.8], [177.8, 63.6], [177.8, 80.9], [182.9, 80.9], [170.2, 85.5],
    [167.6, 68.6], [175.3, 67.7], [165.1, 66.4], [185.4, 102.3], [181.6, 70.5],
    [172.7, 95.9], [190.5, 84.1], [179.1, 87.3], [175.3, 71.8], [170.2, 65.9],
    [193.0, 95.9], [171.4, 91.4], [177.8, 81.8], [177.8, 96.8], [167.6, 69.1],
    [167.6, 82.7], [180.3, 75.5], [182.9, 79.5], [176.5, 73.6], [186.7, 91.8],
    [188.0, 84.1], [188.0, 85.9], [177.8, 81.8], [174.0, 82.5], [177.8, 80.5],
    [171.4, 70.0], [185.4, 81.8], [185.4, 84.1], [188.0, 90.5], [188.0, 91.4],
    [182.9, 89.1], [176.5, 85.0], [175.3, 69.1], [175.3, 73.6], [188.0, 80.5],
    [188.0, 82.7], [175.3, 86.4], [170.5, 67.7], [179.1, 92.7], [177.8, 93.6],
    [175.3, 70.9], [182.9, 75.0], [170.8, 93.2], [188.0, 93.2], [180.3, 77.7],
    [177.8, 61.4], [185.4, 94.1], [168.9, 75.0], [185.4, 83.6], [180.3, 85.5],
    [174.0, 73.9], [167.6, 66.8], [182.9, 87.3], [160.0, 72.3], [180.3, 88.6],
    [167.6, 75.5], [186.7, 101.4], [175.3, 91.1], [175.3, 67.3], [175.9, 77.7],
    [175.3, 81.8], [179.1, 75.5], [181.6, 84.5], [177.8, 76.6], [182.9, 85.0],
    [177.8, 102.5], [184.2, 77.3], [179.1, 71.8], [176.5, 87.9], [188.0, 94.3],
    [174.0, 70.9], [167.6, 64.5], [170.2, 77.3], [167.6, 72.3], [188.0, 87.3],
    [174.0, 80.0], [176.5, 82.3], [180.3, 73.6], [167.6, 74.1], [188.0, 85.9],
    [180.3, 73.2], [167.6, 76.3], [183.0, 65.9], [183.0, 90.9], [179.1, 89.1],
    [170.2, 62.3], [177.8, 82.7], [179.1, 79.1], [190.5, 98.2], [177.8, 84.1],
    [180.3, 83.2], [180.3, 83.2]
]

export let dataVizScatter: number[][][] = [
    dataOK,
    dataNOK
]