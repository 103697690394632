import {Col, Row} from "antd";
import React from "react";
import img_why from "../../../images/why.webp";
import img_why1 from "../../../images/why1b.webp";
import img_why2 from "../../../images/why2b.webp";
import img_why3 from "../../../images/why3b.webp";
import img_why4 from "../../../images/why4b.webp";
import {
    QuoteWhyHighlight,
    TextWhy1,
    TextWhy2,
    TextWhy3,
    TextWhy4,
    TextWhyHighlight,
    TextWhyShort,
    TitleWhy
} from "../../text_content";


export default function MissionXS() {
    return (
        <div>
            <Row justify="center" className="bg-dark padded-t25">
                <Col span={22}>
                    <Row>
                        <Col span={24}>
                            <QuoteWhyHighlight />
                        </Col>
                    </Row>

                    <Row align="middle" justify="center" className="bg-dark padded-t50">
                        <Col span={6}>
                            <img src={img_why} className="img-full" alt=""/>
                        </Col>
                        <Col span={14} offset={2}>
                            {TextWhyShort("light-text centered")}
                        </Col>
                    </Row>

                    <Row justify="space-around" className="bg-dark padded-t50 padded-b25">
                        <Col span={24}>
                            <TextWhyHighlight />
                        </Col>
                    </Row>
                </Col>
            </Row>

            <Row justify="center" className="padded-t25 padded-b25">
                <Col span={22}>
                    <Row>
                        <Part1 />
                    </Row>
                    <Row className="padded-t50">
                        <Part2 />
                    </Row>
                    <Row className="padded-t50">
                        <Part3 />
                    </Row>
                    <Row className="padded-t50">
                        <Part4 />
                    </Row>
                </Col>
            </Row>
        </div>
    );
}

function Part1() {
    return(
        <div>
            <Row>
                <Col span={24}>
                    <TitleWhy />
                    <TextWhy1 />
                </Col>
            </Row>
            <Row justify="center">
                <Col span={18}>
                    <img src={img_why1} className="img-scrap" alt=""/>
                </Col>
            </Row>
        </div>
    )
}

function Part2() {
    return(
        <div>
            <Row>
                <Col span={24}>
                    <TextWhy2 />
                </Col>
            </Row>
            <Row justify="center">
                <Col span={18}>
                    <img src={img_why2} className="img-scrap" alt=""/>
                </Col>
            </Row>
        </div>
    )
}

function Part3() {
    return(
        <div>
            <Row>
                <Col span={24}>
                    <TextWhy3 />
                </Col>
            </Row>
            <Row justify="center">
                <Col span={18}>
                    <img src={img_why3} className="img-scrap" alt=""/>
                </Col>
            </Row>
        </div>
    )
}

function Part4() {
    return(
        <div>
            <Row>
                <Col span={24}>
                    <TextWhy4 />
                </Col>
            </Row>
            <Row justify="center">
                <Col span={18}>
                    <img src={img_why4} className="img-scrap" alt=""/>
                </Col>
            </Row>
        </div>
    )
}

