import React from "react";
import {useMediaQuery} from "react-responsive";
import DataDashboardXXL from "../components/pages/7_xxl/data_dashboard_xxl";
import DataDashboardMega from "../components/pages/8_mega/data_dashboard_mega";
import DataDashboardXL from "../components/pages/6_xl/data_dashboard_xl";
import DataDashboardLG from "../components/pages/5_lg/data_dashboard_lg";
import DataDashboardSM from "../components/pages/3_sm/data_dashboard_sm";
import DataDashboardXS from "../components/pages/2_xs/data_dashboard_xs";
import DataDashboardXXS from "../components/pages/1_xxs/data_dashboard_xxs";
import DataDashboardMD from "../components/pages/4_md/data_dashboard_md";


export default function DataDashboard() {
    const size_xxs = useMediaQuery({query: "(max-device-width: 479px)"});
    const size_xs = useMediaQuery({query: "(min-device-width: 480px) and (max-device-width: 577px)"});
    const size_sm = useMediaQuery({query: "(min-device-width: 578px) and (max-device-width: 767px)"});
    const size_md = useMediaQuery({query: "(min-device-width: 768px) and (max-device-width: 991px)" });
    const size_lg = useMediaQuery({query: "(min-device-width: 992px) and (max-device-width: 1199px)"});
    const size_xl = useMediaQuery({query: "(min-device-width: 1200px) and (max-device-width: 1599px)"});
    const size_xxl = useMediaQuery({query: "(min-device-width: 1600px) and (max-device-width: 1919px)"});
    const size_mega = useMediaQuery({query: "(min-device-width: 1920px)"});


    return (
        <div>
            {size_xxs && <DataDashboardXXS />}
            {size_xs && <DataDashboardXS />}
            {size_sm && <DataDashboardSM />}
            {size_md && <DataDashboardMD />}
            {size_lg && <DataDashboardLG/>}
            {size_xl && <DataDashboardXL />}
            {size_xxl && <DataDashboardXXL />}
            {size_mega && <DataDashboardMega />}
        </div>
    );
}