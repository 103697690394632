import React, {useState} from 'react';
import {Alert, Avatar, Card, Col, Row, Switch} from "antd";
import {
    TextDataDashboard,
    TextDataDashboardCard1,
    TextDataDashboardCard2,
    TextDataDashboardCard3,
    TitleDataDashboard,
    TitleDataDashboardB,
    TitleDataDashboardCard1,
    TitleDataDashboardCard2,
    TitleDataDashboardCard3,
    TextDataDashboardWarning,
    TitleDataDashboardWarning
} from "../../text_content";
import icon_interactive from "../../../images/icon-interactive.webp"
import icon_individual from "../../../images/icon-individual.webp"
import icon_inform from "../../../images/icon-inform.webp"


const { Meta } = Card;


export default function DataDashboardMD() {
    const [playground, setPlayground] = useState(false);
    const togglePlayground = () => {
        setPlayground(!playground);
    };

    return (
        <div>
            <Row justify="center" className="padded-t50">
                <Col span={22}>
                    <TitleDataDashboard />
                    <TextDataDashboard />
                </Col>
            </Row>

            <Row justify="center" className="padded-b50">
                <Col span={22}>
                    <Row>
                        <Card
                            bordered={true}
                            className="custom-card"
                        >
                            <Meta
                                avatar={<Avatar className="icon-card" src={icon_inform} />}
                                title={<TitleDataDashboardCard1 />}
                                description={<TextDataDashboardCard1 />}
                            />
                        </Card>
                    </Row>
                    <Row className="padded-t25">
                        <Card
                            bordered={true}
                            className="custom-card"
                        >
                            <Meta
                                avatar={<Avatar className="icon-card" src={icon_interactive} />}
                                title={<TitleDataDashboardCard2 />}
                                description={<TextDataDashboardCard2 />}
                            />
                        </Card>
                    </Row>
                    <Row className="padded-t25">
                        <Card
                            bordered={true}
                            className="custom-card"
                        >
                            <Meta
                                avatar={<Avatar className="icon-card" src={icon_individual} />}
                                title={<TitleDataDashboardCard3 />}
                                description={<TextDataDashboardCard3 />}
                            />
                        </Card>
                    </Row>
                </Col>
            </Row>

            <Row justify="center" className="padded-b25">
                <Col span={22}>
                    <TitleDataDashboardB />
                    <Switch
                        className="switch-on-light-bg"
                        checkedChildren="Playing!"
                        unCheckedChildren="Open Playground"
                        onClick={togglePlayground}
                    />
                </Col>
            </Row>
            <Row justify="center" className="padded-b50">
                {playground && <Col className="bg-dark" span={22} style={{padding: "10px"}}>
                    <Alert
                        message={<TitleDataDashboardWarning />}
                        description={<TextDataDashboardWarning />}
                        type="warning"
                        showIcon
                    />
                </Col>}
            </Row>
        </div>
    );
}