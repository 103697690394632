import {Button, Col, Divider, Row} from "antd";
import React from "react";
import img_what from "../../../images/what.webp";
import img_what1 from "../../../images/what1b.png";
import {
    QuoteWhatHighlight,
    TextWhat1, TextWhat2, TextWhat3, TextWhat4, TextWhatA, TextWhatB,
    TextWhatHighlight,
    TextWhatShort, TitleWhat,
    TitleWhat1,
    TitleWhat2, TitleWhat3, TitleWhat4
} from "../../text_content";


export default function SolutionsXS() {
    return (
        <div>
            <Row justify="center" className="bg-dark padded-t25">
                <Col span={22}>
                    <Row>
                        <Col span={24}>
                            <QuoteWhatHighlight />
                        </Col>
                    </Row>

                    <Row align="middle" justify="center" className="bg-dark padded-t50">
                        <Col span={6}>
                            <img src={img_what} className="img-full" alt=""/>
                        </Col>
                        <Col span={14} offset={2}>
                            {TextWhatShort("light-text centered")}
                        </Col>
                    </Row>

                    <Row justify="space-around" className="bg-dark padded-t50 padded-b25">
                        <Col span={24}>
                            <TextWhatHighlight />
                        </Col>
                    </Row>
                </Col>
            </Row>

            <Row align="middle" justify="center" className="padded-t25">
                <Col span={22}>
                    <TitleWhat />
                    <TextWhatA />
                </Col>
            </Row>
            <Row justify="center" className="padded-t25">
                <Col span={14}>
                    <img src={img_what1} className="img-full" alt=""/>
                </Col>
            </Row>
            <Row justify="center" className="padded-t25 padded-b25">
                <Col span={22}>
                    <TextWhatB />
                </Col>
            </Row>

            <Divider style={{borderColor: "#1C283C", borderWidth: 2}}/>

            <Row justify="center" className="padded-t25">
                <Col span={22}>
                    <TitleWhat1 />
                </Col>
            </Row>
            <Row justify="center">
                <Col span={22}>
                    <TextWhat1 />
                </Col>
            </Row>
            <Row>
                <Button
                    className="btn-secondary horizontally-centered"
                    onClick={event =>  window.location.href='/solutions/data_tasting'}
                >
                    Zum Data Tasting
                </Button>
            </Row>

            <Row justify="center" className="padded-t50">
                <Col span={22}>
                    <TitleWhat2 />
                </Col>
            </Row>
            <Row justify="center">
                <Col span={22}>
                    <TextWhat2 />
                </Col>
            </Row>
            <Row>
                <Button
                    className="btn-secondary horizontally-centered"
                    onClick={event =>  window.location.href='/solutions/data_dashboard'}
                >
                    Zum Data Dashboard
                </Button>
            </Row>

            <Row justify="center" className="padded-t50">
                <Col span={22}>
                    <TitleWhat3 />
                </Col>
            </Row>
            <Row justify="center">
                <Col span={22}>
                    <TextWhat3 />
                </Col>
            </Row>
            <Row>
                <Button
                    className="btn-secondary horizontally-centered"
                    onClick={event =>  window.location.href='/solutions/sda_toolbox'}
                >
                    Zur SDA Toolbox
                </Button>
            </Row>

            <Row justify="center" className="padded-t50">
                <Col span={22}>
                    <TitleWhat4 />
                </Col>
            </Row>
            <Row justify="center">
                <Col span={22}>
                    <TextWhat4 />
                </Col>
            </Row>
            <Row className="padded-b25">
                <Button
                    className="btn-secondary horizontally-centered"
                    onClick={event =>  window.location.href='/solutions/xai_toolbox'}
                >
                    Zur XAI Toolbox
                </Button>
            </Row>
        </div>
    );
}