import React, {useState, useRef, useLayoutEffect} from 'react';
import {Avatar, Card, Col, Divider, Row, Space, Switch, Tabs} from "antd";
import {
    TextDataDashboard,
    TextDataDashboardCard1,
    TextDataDashboardCard2,
    TextDataDashboardCard3,
    TitleDataDashboard,
    TitleDataDashboardB,
    TitleDataDashboardBars,
    TitleDataDashboardCard1,
    TitleDataDashboardCard2,
    TitleDataDashboardCard3,
    TitleDataDashboardLines,
    TitleDataDashboardScatter
} from "../../text_content";
import icon_interactive from "../../../images/icon-interactive.webp"
import icon_individual from "../../../images/icon-individual.webp"
import icon_inform from "../../../images/icon-inform.webp"
import {VizBar, VizBar2, VizLine, VizLine2, VizScatter, VizScatter2} from "../../visualizations/charts";
import {dataVizBar, dataVizBar2, dataVizLine, dataVizLine2, dataVizScatter} from "../../visualizations/chart_data";


const { Meta } = Card;


export default function DataDashboardXL() {
    const [playground, setPlayground] = useState(false);
    const togglePlayground = () => {
        setPlayground(!playground);
    };

    return (
        <div>
            <Row justify="center" className="padded-t50">
                <Col span={18}>
                    <TitleDataDashboard />
                    <TextDataDashboard />
                </Col>
            </Row>

            <Row justify="center" className="padded-b100">
                <Col span={18}>
                    <Row>
                        <Card
                            bordered={true}
                            className="custom-card"
                        >
                            <Meta
                                avatar={<Avatar className="icon-card" src={icon_inform} />}
                                title={<TitleDataDashboardCard1 />}
                                description={<TextDataDashboardCard1 />}
                            />
                        </Card>
                    </Row>
                    <Row className="padded-t25">
                        <Card
                            bordered={true}
                            className="custom-card"
                        >
                            <Meta
                                avatar={<Avatar className="icon-card" src={icon_interactive} />}
                                title={<TitleDataDashboardCard2 />}
                                description={<TextDataDashboardCard2 />}
                            />
                        </Card>
                    </Row>
                    <Row className="padded-t25">
                        <Card
                            bordered={true}
                            className="custom-card"
                        >
                            <Meta
                                avatar={<Avatar className="icon-card" src={icon_individual} />}
                                title={<TitleDataDashboardCard3 />}
                                description={<TextDataDashboardCard3 />}
                            />
                        </Card>
                    </Row>
                </Col>
            </Row>

            <Row justify="center" className="padded-b25">
                <Col span={22}>
                    <TitleDataDashboardB />
                    <Switch
                        className="switch-on-light-bg"
                        checkedChildren="Playing!"
                        unCheckedChildren="Open Playground"
                        onClick={togglePlayground}
                    />
                </Col>
            </Row>
            <Row justify="center" className="padded-b50">
                {playground && <Col className="bg-dark" span={22} style={{padding: "10px"}}>
                    <Tabs defaultActiveKey="1" size="large">
                        <Tabs.TabPane tab="Balkendiagramm" key="1">
                            <ContentTabBars />
                        </Tabs.TabPane>
                        <Tabs.TabPane tab="Liniendiagramm" key="2">
                            <ContentTabLines />
                        </Tabs.TabPane>
                        <Tabs.TabPane tab="Streudiagramm" key="3">
                            <ContentTabScatter />
                        </Tabs.TabPane>
                        <Tabs.TabPane tab="All-in-one-Cockpit" key="4">
                            <ContentTabCockpit />
                        </Tabs.TabPane>
                    </Tabs>
                </Col>}
            </Row>
        </div>
    );
}

function ContentTabBars () {
    const [value, setValue] = useState(false);
    const toggle = () => {
        setValue(!value);
    };

    return (
        <div>
            <Row>
                <TitleDataDashboardBars />
            </Row>
            <Row>
                <Space align="start">
                    <p className="light-text p-small">
                        Visualisierung:
                    </p>
                    <Switch
                        checkedChildren="Kummulativ"
                        unCheckedChildren="Aufgeteilt"
                        onClick={toggle}
                    />
                </Space>
            </Row>
            {!value && VizBar(dataVizBar, "300px", 16)}
            {value && VizBar2(dataVizBar2, "300px", 16)}
        </div>
    )
}

function ContentTabLines () {
    const [value, setValue] = useState(false);
    const toggle = () => {
        setValue(!value);
    };

    return (
        <div>
            <Row>
                <TitleDataDashboardLines />
            </Row>
            <Row>
                <Space align="start">
                    <p className="light-text p-small">
                        Visualisierung:
                    </p>
                    <Switch
                        checkedChildren="Chronologisch"
                        unCheckedChildren="Event-basiert"
                        onClick={toggle}
                    />
                </Space>
            </Row>
            {!value && VizLine(dataVizLine, "300px", 16)}
            {value && VizLine2(dataVizLine2, "300px", 16)}
        </div>
    )
}

function ContentTabScatter () {
    return (
        <div>
            <TitleDataDashboardScatter />
            {VizScatter2(dataVizScatter, "300px", 16)}
        </div>
    )
}

function ContentTabCockpit () {
    const [value1, setValue1] = useState<boolean>(false);
    const [value2, setValue2] = useState<boolean>(false);
    const [displayMean, setDisplayMean] = useState<boolean>(false);
    const [displayRange, setDisplayRange] = useState<boolean>(false);

    const toggle1 = () => {
        setValue1(!value1);
    };
    const toggle2 = () => {
        setValue2(!value2);
    };

    const toggleMean = () => {
        setDisplayMean(!displayMean);
    };

    const toggleRange = () => {
        setDisplayRange(!displayRange);
    };

    return (
        <div>
            <Row>
                <Col span={24}>
                    <Divider className="dashboard-divider-1">
                        Control Panel
                    </Divider>
                </Col>
            </Row>

            <Row justify="start">
                <Col flex="0 1 40%">
                    <h5 className="light-text">Streudiagramm</h5>
                    <Row justify="start">
                        <Col flex="0 1 33%">
                            <Space align="start">
                                <p className="light-text p-small">
                                    Mittelwerte:
                                </p>
                                <Switch
                                    className="switch-toggle"
                                    checkedChildren="ein"
                                    unCheckedChildren="aus"
                                    onClick={toggleMean}
                                />
                            </Space>
                        </Col>
                        <Col flex="0 1 33%" offset={1}>
                            <Space align="start">
                                <p className="light-text p-small">
                                    Datenbereiche:
                                </p>
                                <Switch
                                    className="switch-toggle"
                                    checkedChildren="ein"
                                    unCheckedChildren="aus"
                                    onClick={toggleRange}
                                />
                            </Space>
                        </Col>
                    </Row>
                </Col>
                <Col flex="0 1 25%" offset={1}>
                    <h5 className="light-text">Balkendiagramm</h5>
                    <Space align="start">
                        <p className="light-text p-small">
                            Visualisierung:
                        </p>
                        <Switch
                            checkedChildren="Kummulativ"
                            unCheckedChildren="Aufgeteilt"
                            onClick={toggle1}
                        />
                    </Space>
                </Col>
                <Col flex="0 1 25%" offset={1}>
                    <h5 className="light-text">Liniendiagramm</h5>
                    <Space align="start">
                        <p className="light-text p-small">
                            Visualisierung:
                        </p>
                        <Switch
                            checkedChildren="Chronologisch"
                            unCheckedChildren="Event-basiert"
                            onClick={toggle2}
                        />
                    </Space>
                </Col>
            </Row>

            <Row className="padded-t25">
                <Col span={24}>
                    <Divider className="dashboard-divider-1">
                        Monitoring Dashboard
                    </Divider>
                </Col>
            </Row>

            <Row>
                <Col span={12}>
                    <TitleDataDashboardScatter />
                </Col>
                <Col span={12}>
                    <TitleDataDashboardBars />
                </Col>
            </Row>
            <Row>
                <Col span={12}>
                    {VizScatter(dataVizScatter, "200px", 10, displayMean, displayRange)}
                </Col>
                <Col span={12}>
                    {!value1 && VizBar(dataVizBar, "200px", 10)}
                    {value1 && VizBar2(dataVizBar2, "200px", 10)}
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <h4 className="light-text">Monitoring von Sensorwertverläufen</h4>
                    {!value2 && VizLine(dataVizLine, "250px", 14)}
                    {value2 && VizLine2(dataVizLine2, "250px", 10)}
                </Col>
            </Row>
        </div>
    )
}
