import React from "react";
import {useMediaQuery} from "react-responsive";
import XAIToolboxMega from "../components/pages/8_mega/xai_toolbox_mega";
import XAIToolboxXXL from "../components/pages/7_xxl/xai_toolbox_xxl";
import XAIToolboxXL from "../components/pages/6_xl/xai_toolbox_xl";
import XAIToolboxLG from "../components/pages/5_lg/xai_toolbox_lg";
import XAIToolboxMD from "../components/pages/4_md/xai_toolbox_md";
import XAIToolboxSM from "../components/pages/3_sm/xai_toolbox_sm";
import XAIToolboxXS from "../components/pages/2_xs/xai_toolbox_xs";
import XAIToolboxXXS from "../components/pages/1_xxs/xai_toolbox_xxs";


export default function TransparencyInterpretabilityToolbox() {
    const size_xxs = useMediaQuery({query: "(max-device-width: 479px)"});
    const size_xs = useMediaQuery({query: "(min-device-width: 480px) and (max-device-width: 577px)"});
    const size_sm = useMediaQuery({query: "(min-device-width: 578px) and (max-device-width: 767px)"});
    const size_md = useMediaQuery({query: "(min-device-width: 768px) and (max-device-width: 991px)" });
    const size_lg = useMediaQuery({query: "(min-device-width: 992px) and (max-device-width: 1199px)"});
    const size_xl = useMediaQuery({query: "(min-device-width: 1200px) and (max-device-width: 1599px)"});
    const size_xxl = useMediaQuery({query: "(min-device-width: 1600px) and (max-device-width: 1919px)"});
    const size_mega = useMediaQuery({query: "(min-device-width: 1920px)"});


    return (
        <div>
            {size_xxs && <XAIToolboxXXS />}
            {size_xs && <XAIToolboxXS />}
            {size_sm && <XAIToolboxSM />}
            {size_md && <XAIToolboxMD />}
            {size_lg && <XAIToolboxLG/>}
            {size_xl && <XAIToolboxXL/>}
            {size_xxl && <XAIToolboxXXL />}
            {size_mega && <XAIToolboxMega />}
        </div>
    );
}