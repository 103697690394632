import ReactEcharts from "echarts-for-react";
import {
    optionMean, optionMeanFalse,
    optionRange, optionRangeFalse,
    optionVizBar,
    optionVizBar2,
    optionVizLine,
    optionVizLine2,
    optionVizScatter,
    optionVizScatter2,
    optionVizScatter2Bar
} from "./chart_options";
import React, {useRef} from "react";
import {Col, Row, Space, Switch} from "antd";

/*
ToDo: Put chart options as function arguments
*/
export function VizBar (data: number[][], height: string, fsize: number) {
    return(
        <div>
            <ReactEcharts
                style={{height: height}}
                option={optionVizBar(data, fsize)}
                theme={'dark'}
            />
        </div>
    )
}

export function VizBar2 (data: number[][], height: string, fsize: number) {
    return(
        <div>
            <ReactEcharts
                style={{height: height}}
                option={optionVizBar2(data, fsize)}
                theme={'dark'}
            />
        </div>
    )
}

export function VizLine (data: number[][], height: string, fsize: number) {
    return(
        <div>
            <ReactEcharts
                style={{height: height}}
                option={optionVizLine(data, fsize)}
                theme={'dark'}
            />
        </div>
    )
}

export function VizLine2 (data: number[], height: string, fsize: number) {
    return(
        <div>
            <ReactEcharts
                style={{height: height}}
                option={optionVizLine2(data, fsize)}
                theme={'dark'}
            />
        </div>
    )
}

export function VizScatter (data: number[][][], height: string, fsize: number, mean: boolean, range: boolean) {
    return(
        <div>
            <ReactEcharts
                style={{height: height}}
                option={optionVizScatter(data, fsize, mean, range)}
                theme={'dark'}
            />
        </div>
    )
}

export function VizScatter2 (data: number[][][], height: string, fsize: number) {
    const numOK = useRef<number>(247)
    const numNOK = useRef<number>(260)
    const numTotal = useRef<number>(507)
    const eChartsRefBar = useRef(null as any);
    const eChartsRefScatter = useRef(null as any);
    const displayMean = useRef<boolean>(false);
    const displayRange = useRef<boolean>(false);

    const toggleMean = () => {
        displayMean.current = !displayMean.current
        console.log(displayMean.current)
        if (displayMean.current && eChartsRefScatter && eChartsRefScatter.current)
            eChartsRefScatter.current?.getEchartsInstance().setOption(
                optionMean(fsize)
            )
        else if (!displayMean.current && eChartsRefScatter && eChartsRefScatter.current)
            eChartsRefScatter.current?.getEchartsInstance().setOption(
                optionMeanFalse()
            )
    };

    const toggleRange = () => {
        displayRange.current = !displayRange.current
        console.log(displayRange.current)
        if (displayRange.current && eChartsRefScatter && eChartsRefScatter.current)
            eChartsRefScatter.current?.getEchartsInstance().setOption(
                optionRange(fsize)
            )
        else if (!displayRange.current && eChartsRefScatter && eChartsRefScatter.current)
            eChartsRefScatter.current?.getEchartsInstance().setOption(
                optionRangeFalse()
            )
    };

    const onDataSelect = (params: any) => {
        let brushComponent = params.batch[0]
        console.log(brushComponent.selected)
        numOK.current = brushComponent.selected[0].dataIndex.length
        numNOK.current = brushComponent.selected[1].dataIndex.length
        numTotal.current = brushComponent.selected[1].dataIndex.length + brushComponent.selected[0].dataIndex.length
        if (eChartsRefBar && eChartsRefBar.current)
            eChartsRefBar.current?.getEchartsInstance().setOption(
                optionVizScatter2Bar([numNOK.current, numOK.current, numTotal.current], fsize)
            );
    }

    const onEvents = {
        brushSelected: onDataSelect,
    };

    return(
        <div>
            <Row justify="start">
                <Col flex="0 1 8%">
                    <Space align="start">
                        <p className="light-text p-small">
                            Mittelwerte:
                        </p>
                        <Switch
                            className="switch-toggle"
                            checkedChildren="ein"
                            unCheckedChildren="aus"
                            onClick={toggleMean}
                        />
                    </Space>
                </Col>
                <Col flex="0 1 8%" offset={1}>
                    <Space align="start">
                        <p className="light-text p-small">
                            Datenbereiche:
                        </p>
                        <Switch
                            className="switch-toggle"
                            checkedChildren="ein"
                            unCheckedChildren="aus"
                            onClick={toggleRange}
                        />
                    </Space>
                </Col>
            </Row>
            <Row>
                <Col span={18}>
                    <PlotScatter />
                </Col>
                <Col span={6}>
                    <PlotBar />
                </Col>
            </Row>
        </div>
    )

    function PlotBar() {
        return(
            <div>
                <ReactEcharts
                    ref={eChartsRefBar}
                    style={{height: height}}
                    option={optionVizScatter2Bar([numNOK.current, numOK.current, numTotal.current], fsize)}
                    theme={'dark'}
                />
            </div>
        )
    }

    function PlotScatter() {
        return (
            <div>
                <ReactEcharts
                    ref={eChartsRefScatter}
                    onEvents={onEvents}
                    style={{height: height}}
                    option={optionVizScatter2(data, fsize)}
                    theme={'dark'}
                />
            </div>
        )
    }
}