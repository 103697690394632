import React from "react";
import icon_crawl from "../images/icon-crawl.webp";
import icon_walk from "../images/icon-walk.webp";
import icon_run from "../images/icon-run.webp";
import icon_fly from "../images/icon-fly.webp";


export function CarouselTitleDigitalisierung() {
    return(
        <div>
            <h1 className="orange-text centered">
                Digitalisierung im industriellen Umfeld
            </h1>
        </div>
    )
}

export function CarouselQuoteDigitalisierungPart1() {
    return(
        <div>
            <p className="light-text italic">
                "Nichts ist so beständig, wie der Wandel."
            </p>
        </div>
    )
}

export function CarouselQuoteDigitalisierungPart2() {
    return(
        <div>
            <p className="light-text flushed-right p-small">
                - Heraklit von Ephesus, Griechischer Philosoph der Antike, 535-475 v.u.Z.
            </p>
        </div>
    )
}

export function CarouselTextDigitalisierung () {
    return(
        <div>
            <p className="light-text">
                Im 21. Jahrhundert stehen produzierende Unternehmen vor der Herausforderung digitale Technologien in ihre
                über Jahrzehnte gewachsenen Strukturen reibungsfrei und gewinnbringend einzubinden.
            </p>
            <p className="light-text">
                Unsere Mission ist es die Führungspositionen deutscher, produzierender Unternehmen auf dem Weltmarkt zu
                erhalten und zu digitalen Champions zu machen.
                Dazu setzen wir auf datenzentrierte Lösungen und modernste Verfahren aus der Forschung
                im Bereich des maschinellen Lernens und der künstlichen Intelligenz und begleiten Unternehmen auf ihrem Weg
                in eine digitale Zukunft.
            </p>
        </div>
    )
}

export function CarouselTitleDataTasting () {
    return(
        <div>
            <h1 className="orange-text centered">
                Der erste Schritt - Das Data Tasting
            </h1>
        </div>
    )
}

export function CarouselTextDataTasting () {
    return(
        <div>
            <p className="light-text">
                Der erste Schritt zu einer datenzentrierten Lösung ist das Kennelernen der eigenen Daten.
                Mit unserem <span className="bold italic orange-text">Data Tasting</span> in vier Schritten
                untersuchen wir die Qualität von Daten und bewerten das Potential für den zielgerichteten Einsatz
                von lernenden Verfahren.
            </p>
            <p className="light-text">
                Die vier Schritte des Data Tastings:
                <ol className="ol-custom">
                    <li>
                        Aufbau von Domänenverständis
                    </li>
                    <li>
                        Charakterisierung der Daten durch explorative Analyse
                    </li>
                    <li>
                        Bewertung der Datenqualität durch prototypische Potentialanalyse
                    </li>
                    <li>
                        Aufbereitung der Erkenntnisse und Ableitung weiterer Handlungsmaßnahmen
                    </li>
                </ol>
            </p>
        </div>
    )
}

export function CarouselTitleDataDashboard () {
    return(
        <div>
            <h1 className="orange-text centered">
                Interaktive Visualisierungen
            </h1>
        </div>
    )
}

export function CarouselTextDataDashboard () {
    return(
        <div>
            <p className="light-text">
                Kontinurierliche Zustandsüberwachung - Live und in Farbe!
                Unser <span className="bold italic orange-text">Data Dashboard</span> ist auf Unternehmensprozesse
                zugeschnittenen und bietet individuelle, interaktive Visualisierungen, um die Daten von Maschinen,
                Produkten und Prozessen jederzeit im Auge zu behalten.
            </p>
            <p className="light-text">
                <ul className="ul-custom">
                    <li>
                        Überwachung von Prozessparametern
                    </li>
                    <li>
                        Überwachung von Maschinenzuständen und -verschleiß
                    </li>
                    <li>
                        Überwachung von Qualitätsmerkmalen von Produkten
                    </li>
                </ul>
            </p>
            <p className="light-text">
                Noch keine digitalen Daten? Wir unterstützen bei der Auswahl und Inbetriebnahme von Sensorik zur
                schrittweisen Digitalisierung der Prozessüberwachung.
            </p>
        </div>
    )
}

export function CarouselTitleSensorDataAnalyticsToolbox () {
    return(
        <div>
            <h1 className="orange-text centered">
                Predictive Analytics durch künstliche Intelligenz
            </h1>
        </div>
    )
}

export function CarouselTextSensorDataAnalyticsToolbox () {
    return(
        <div>
            <p className="light-text">
                Unsere <span className="italic bold orange-text">Sensor Data Analytics (SDA) Toolbox </span> bietet ein
                breites Angebot für die deskriptive und prädiktive Analyse von Sensordaten mittels
                statistischer Methoden, maschinellem Lernen und künstlicher Intelligenz.
            </p>
            <p className="light-text">
                <ul className="ul-custom">
                    <li>
                        Klassifizierung von Ausschussware
                    </li>
                    <li>
                        Forecasting von Sensorzeitreihen
                    </li>
                    <li>
                        Erkennung von Anomalien im Dauerbetrieb
                    </li>
                    <li>
                        Prädiktive Bewertung von Produktqualität (Predictive Quality)
                    </li>
                    <li>
                        Vorausschauende Planung von Wartungsarbeiten (Predictive Maintenance)
                    </li>
                </ul>
            </p>
        </div>
    )
}

export function CarouselTitleXAIToolbox () {
    return(
        <div>
            <h1 className="orange-text centered">
                Transparenz & Interpretierbarkeit für KI-Entscheidungen
            </h1>
        </div>
    )
}

export function CarouselTextXAIToolbox () {
    return(
        <div>
            <p className="light-text">
                Der inhärente Black-Box Charakter vieler KI-Modelle ist eine der größten Herausforderungen
                für ihren verlässlichen Einsatz im Produktionsumfeld.
                Unsere <span className="bold italic orange-text">Explainable AI (XAI) Toolbox</span> bietet eine Reihe
                an Verfahren, um die Entscheiedungsfindung von datengetriebenen Lernmodellen transparent und interpretierbar zu machen.
            </p>
            <p className="light-text">
                <ul className="ul-custom">
                    <li>
                        Bewertung der Wichtigkeit der Lerndaten (Feature Importance)
                    </li>
                    <li>
                        Transparenz für die erlernten Wissenrepräsentationen
                    </li>
                    <li>
                        Gesteigerte Interpretierbarkeit von automatisierten Entscheidungen
                    </li>
                    <li>
                        Alignment zwischen Domänenexperte und KI-Modell
                    </li>
                </ul>
            </p>
        </div>
    )
}

export function TitleGoldenCircle(classNameString: string) {
    return (
        <div>
            <h1 className={classNameString}>Der <span className="italic bold">Golden Circle</span> der Foxes and Hedgehogs</h1>
        </div>
    )
}

export function TextWhyShort (classNameString: string) {
    return (
        <div>
            <p className={classNameString}>
                Das <span className="bold italic">WHY</span> ist unsere Bestimmung, unser <span className="italic">Purpose</span>.
            </p>
            <p className={classNameString}>
                Es begründet den Sinn und Zweck unserer Unternehmensexistenz und ist der Grund <span className="bold">warum</span> wir tun,
                was wir tun.
            </p>
        </div>
    )
}

export function TextHowShort (classNameString: string) {
    return (
        <div>
            <p className={classNameString}>
                Das <span className="bold italic">HOW</span> sind unsere Werte und Überzeugungen.
            </p>
            <p className={classNameString}>
                Es verkörpert unsere Unternehmensphilosophie, nach der wir unserer Vision Tag
                für Tag nacheifern und beschreibt, <span className="bold">wie</span> wir tun, was wir tun.
            </p>
        </div>
    )
}

export function TextWhatShort (classNameString: string) {
    return (
        <div>
            <p className={classNameString}>
                Das <span className="bold italic">WHAT</span> ist unser Spielfeld, unsere Arena.
            </p>
            <p className={classNameString}>
                Es umfasst die Branchen, Domänen und Anwendungsfelder, in denen wir aktiv sind, sowie unsere Leistungen und Produkte.
                Es beschreibt, <span className="bold">was</span> wir tun und für wen wir es tun.
            </p>
        </div>
    )
}
export function TitleHomeCarousel(classNameString: string) {
    return(
        <div>
            <h1 className={classNameString}>Beispiele aus der Anwendung</h1>
        </div>
    )
}

export function CarouselTitleHochwasser(classNameString: string) {
    return(
        <div>
            <h3 className={classNameString}>
                Vorhersage von Pegelständen bei Starkregen
            </h3>
        </div>
    )
}

export function CarouselTextHochwasser() {
    return(
        <div>
            <p>
                Immer häufiger auftretender Starkregen und damit verbundene Hochwasserereignisse stellen eine zunehmende
                Gefahr für die lokale Bevölkerung und lokal ansässige Unternehmen in Einzugsgebieten von gefährdeten Flüssen dar.
            </p>
            <p>
                Mit unserer prädiktiven Analyse von Sensordaten über Pegelstände, Niederschlagsmengen, Wasserdrucksäulen
                und sonstigen Wetterverhältnissen, wie Temperatur, Luftfeuchtigkeit und Luftdruck,
                entwickeln wir ein Frühwarnsystem für die zeitliche und georäumliche Vorhersage von Überflutungsereignissen.
            </p>
        </div>
    )
}

export function CarouselTitleTiefziehen(classNameString: string) {
    return(
        <div>
            <h3 className={classNameString}>
                Predictive Quality im Karosseriebau
            </h3>
        </div>
    )
}

export function CarouselTextTiefziehen () {
    return(
        <div>
            <p>
                Die Herstellung von Karosseriebauteilen in der Automobilindustrie setzt auf den Umformprozess des
                Tiefziehens, bei dem flache Metallplatten unter hoher Krafteinwirkung in wenigen Sekunden verformt werden.
                Die Qualitätskontrolle findet in der Regel durch manuelle, visuelle Inspektion am Ende des Prozesses statt
                und besteht hauptsächlich darin, die geformten Karosserieteile auf Risse zu untersuchen.
            </p>
            <p>
                Mit unserer prädiktiven Analyse von Sensordaten von Dehnungsmesstreifen und Laser-Wegaufnehmern sagen wir
                zuverlässig die Qualität der Karosseriebauteile voraus und prädizieren das Auftreten von Rissen. So unterstützen
                wir die manuelle, stichprobenartige, visuelle Inspektion durch eine vollautomatisierte und 100% abdeckende,
                datengetriebene Qualitätskontrolle.
            </p>
        </div>
    )
}

export function CarouselTitleTapes(classNameString: string) {
    return(
        <div>
            <h3 className={classNameString}>
                Kamera-basierte Bestimmung der Schnittkantenqualität
            </h3>
        </div>
    )
}

export function CarouselTextTapes () {
    return(
        <div>
            <p>
                Technische Klebebänder werden durch die Beschichtung von breiten Bändern speziell angefertigter
                Textilverbundfasern mit höchster Zugfestigkeit hergestellt, die für die individuelle Nutzung auf die
                entsprechende Größe zugeschnitten werden. Der Schnitt des Textils kann zum Ausfasern einzelner Fäden führen
                und die Qualität des Klebebandes beeinträchtigen.
            </p>
            <p>
                Mit unserer automatisierten Analyse von Mikroskopiebildern der Schnittkanten bestimmen wir die Qualität
                der Klebebänder und unterstützen so die Bewertung des Zustandes der Schnittmesseranlage mit dem Ziel, die Wartung
                vorausschauend zu planen.
            </p>
        </div>
    )
}

export function CarouselTitleWindshields(classNameString: string) {
    return(
        <div>
            <h3 className={classNameString}>
                Inline Optimierung von Prozessparametern
            </h3>
        </div>
    )
}

export function CarouselTextWindshields () {
    return(
        <div>
            <p>
                Bei der Herstellung von Windschutzscheiben werden diese auf Leeren durch ein komplex konfiguriertes
                Heizofensystem geführt und bei hohen Temperaturen unter Einwirkung der Schwerkraft in die richtige Form
                gebracht.
            </p>
            <p>
                Mit unserer prädiktiven Analyse der Prozessparameter des Ofensystems bestimmen wir die Einflussfaktoren
                individueller Parameter auf die Krümmung der Windschutzscheiben. In einem Live-Dashboard ermöglichen wir Domänenexperten
                die Anpassung des Prozesses mit Hilfe eines KI-Unterstützungssystems, welches Empfehlungen zur Optimierung der Prozessparameter vorschlägt.
            </p>
        </div>
    )
}

export function QuoteWhyHighlight () {
    return(
        <div>
            <h1 className="light-text centered italic bold"><i>"Wer ein 'Warum' hat, dem ist kein 'Wie' zu schwer."</i></h1>
            <h4 className="light-text flushed-right">– Friedrich Wilhelm Nietzsche, deutscher Philosoph</h4>
        </div>
    )
}

export function TitleResearchPartners() {
    return(
        <div>
            <h1 className="centered">Unsere Forschungspartner</h1>
        </div>
    )
}

export function TextWhyHighlight () {
    return(
        <div>
            <p className="light-text">
                Unsere Vision der Zukunft: <span className="bold">"Deutschland,
                das Land der <span className="underlined">digitalen</span> Dichter und Denker"</span> als
                Treiber digitaler Innovation im industriellen Umfeld, führend auf der ganzen Welt.

            </p>
            <p className="light-text">
                Unsere Mission ist es, den deutschen Erfindergeist des 18. und 19. Jahrhunderts im Zeitalter der Digitalisierung
                wieder aufblühen zu lassen.
                Gemeinsam mit unseren Forschungs- und Anwendungspartnern treiben wir Innovation und Wandel voran.
                Wir knüpfen an die großen Errungenschaften aus der Vergangenheit an und gehen Schulter an Schulter
                den Weg in die digitale Zukunft.
            </p>
        </div>
    )
}

export function TitleWhy () {
    return(
        <div>
            <h1>
                Schon gewusst?
            </h1>
        </div>
    )
}

export function TextWhy1 () {
    return(
        <div>
            <p>
                Unsere Mission ist tief in der deutschen Historie verwurzelt.
                Wir sind inspiriert von den zahlreichen Errungenschaften der Vergangenheit in Kunst und Literatur sowie
                in der Wissenschaft und dem Ingenieurswesen.
                Historische Größen, wie Ludwig van Beethoven, Wolfang Amadeus Mozart und Johann Sebastian Bach,
                Friedrich Wilhelm Nietzsche, Georg Friedrich Wilhelm Hegel, Johann Wolfgang von Goethe und Johann Christoph Friedrich Schiller,
                Alexander von Humboldt, Carl Friedrich Gauß, Gottfried Wilhelm Leibniz und Albert Einstein
                sowie Carl Benz, Gottlieb Daimler, Otto Lilienthal und Robert Bosch haben mit ihren Werken die Welt verändert.
            </p>
        </div>
    )
}

export function TextWhy2 () {
    return(
        <div>
            <p>
                <span className="bold">"Deutschland, das Land der Dichter und Denker"</span> ist eine Wortverbindung,
                die im frühen 19. Jahrhundert als Referenz auf ebendiese kulturellen Errungenschaften
                geprägt wurde. Insbesondere der deutsche Erfindergeist hat eine Reihe an technischen Erfindungen
                hervorgebracht, die aus dem heutigen Alltag nicht mehr wegzudenken sind.
                Dazu gehören unter anderem der Buchdruck, die Glühbirne, das Telefon, das Dynamo, die Kleinbildkamera,
                der Kühlschrank, die Straßenbahn, das Fahrrad, das Motorrad und das Automobil sowie das Flugzeug und der erste Computer.
            </p>
        </div>
    )
}

export function TextWhy3 () {
    return(
        <div>
            <p>
                Im Zeitalter der Digitalisierung und der künstlichen Intelligenz kommen derartig bahnbrechende technische
                Errungenschaften jedoch zunehmend aus dem Ausland, denn
            </p>
            <p className="centered">
                <span className="italic">"schwer ist das Haupt, welches die Krone trägt"</span>
            </p>
            <p className="flushed-right p-small" style={{marginTop: "-20px"}}>
                <span>- William Shakespeare, englischer Dramatiker und Lyriker</span>
            </p>
            <p>
                und der einst so glanzvolle deutsche Erfindergeist ist müde geworden.
            </p>
        </div>
    )
}

export function TextWhy4 () {
    return(
        <div>
            <p>
                Wir wollen diesem Erfindergeist im 21. Jahrhundert wieder zu altem Glanz verhelfen und treiben gemeinsam mit unseren
                Partnern aus Forschung und Industrie den deutschen Motor der digitalen Innovation.
                Denn unsere Vision der Zukunft ist <span className="bold">"Deutschland,
                das Land der <span className="underlined">digitalen</span> Dichter und Denker"</span>
                als Treiber von Innovation, führend auf der ganzen Welt.
            </p>
        </div>
    )
}

export function QuoteHowHighlight () {
    return(
        <div>
            <h1 className="light-text centered italic bold"><i>"Mache die Dinge so einfach wie möglich, <br /> aber nicht einfacher."</i></h1>
            <h4 className="light-text flushed-right">– Albert Einstein, deutscher Physiker und Nobelpreisträger</h4>
        </div>
    )
}

export function TextHowHighlight () {
    return(
        <div>
            <p className="light-text">
                Wir sind neugierig und verspielt, wissenschaftlich motiviert und methodisch genau,
                und leidenschaftliche Treiber des technologischen Fortschritts.
            </p>
            <p className="light-text">
                Wir sind aber auch praktikabel in unserem Handeln, zielgerichtet in unseren Entscheidungen,
                und hegen höchste Ansprüche an uns und unser Handwerk.
            </p>
            <p className="light-text">
                Wir lieben Herausforderungen, denn wir wachsen daran. <br />
                Wir sind mutig und selbstbewusst, denn wir stehen füreinander ein. <br />
                Wir stehen Hand in Hand, Arm in Arm, Schulter an Schulter und verändern zusammen die Welt.
            </p>
            <p className="light-text bold">
                We are Foxes and Hedgehogs!
            </p>
        </div>
    )
}

export function TitleHow () {
    return(
        <div>
            <h1>Im Herzen Wissenschaftler und im Kopf Ingenieure</h1>
        </div>
    )
}

export function TextHow () {
    return (
        <div>
            <p>
                Als junges Start-Up und Ausgründung aus dem akademischen Umfeld schlagen wir die Brücke zwischen
                anwendungsorientierter Forschung und der industriellen Wertschöpfung. Auf unserem Weg zur
                digitalen Innovation für produzierende Unternehmen verfolgen wir die ganzheitliche Betrachtung der
                digitalen Transformation. Diese beginnt bei der wissenschaftlich und methodisch geprägten Grundlagenforschung
                im Bereich der künstlichen Intelligenz und des maschinellen Lernens und geht über den Transfer in industrielle
                Anwendungsbereiche bis hin zu konkreten Umsetzungen unter Berücksichtigung individueller Anforderungen unserer Kunden.
            </p>
        </div>
    )
}

export function PhilosophyIntroTitle () {
    return(
        <div>
            <h1>
                Schon gewusst?
            </h1>
        </div>
    )
}

export function PhilosophyIntroText () {
    return(
        <div>
            <p>
                Unser Name steht für unsere Werte und für die stehen wir mit unserem Namen. Denn die Dichotomie
                des Fuchses und des Igels, die auf den Poeten und Schriftsteller Archilochus bis in die griechische Antike zurück geht,
                stellt zwei im Grundsatz unterschiedliche Denkrichtungen einander gegenüber.
                Diese Gegenüberstellung kann mit folgendem Zitat zusammengefasst werden:
            </p>
        </div>
    )
}

export function PhilosophyQuote () {
    return (
        <div>
            <h1 className="centered italic bold"><i>"The Fox knows many things, <br /> but the Hedgehog knows one big thing."</i></h1>
            <h4 className="flushed-right">– Archilochus, Griechischer Schriftsteller, 7 Jh. v.u.Z.</h4>
        </div>
    );
}

export function PhilosophyImgDescription () {
    return (
        <div>
            <p className="img-description">
                Titelbild von George Warings "The Squirrels and Other Animals: Illustrations of the Habits and Instincts
                of Many of the Smaller British Quadrupeds"
            </p>
        </div>
    );
}

export function PhilosophyText () {
    return (
        <div>
            <p>
                Diese Idee wurde 1953 von Isaiah Berlin, einem russisch-britischen, sozialen und
                politischen Theoretiker, Philosoph und Ideenhistoriker, aufgegriffen, der große Denker der Historie,
                wie Plato, Aristoteles, Nietzsche, Hegel, Shakespeare, Goethe, Dostoyevsky und Pushkin anhand ihrer
                Werke in Füchse und Igel unterteilt hat. Füchse und Igel finden sich nicht nur in der Literatur, sondern
                in allen Lebensbereichen und nicht zuletzt im modernen Feld der digitalen Transformation.
            </p>
        </div>
    );
}

export function PhilosophyFox () {
    return (
        <div>
            <p>
                Füchse sind Erfinder. Sie sind kreativ, clever, forsch und energetisch.
                Sie zeichnen sich durch ihre Vielfältigkeit im Umgang mit neuen, komplexen Herausforderungen aus und
                schöpfen aus einem breiten Wissensschatz von Erfahrungen.
                Sie betrachten Probleme aus diversen Blickwinkeln und kombinieren verschiedene Lösungsansätze, ohne jedoch
                die kleinen aber feinen Details zu verpassen.
                Sie erreichen ihr Ziel mit Elan und Einfallsreichtum.
            </p>
        </div>
    );
}

export function PhilosophyHedgehog () {
    return (
        <div>
            <p>
                Igel sind Entdecker. Sie sind gewissenhaft, gründlich, bedächtig und geduldig.
                Sie sind starke Verfechter eines fundierten, methodischen und lückenlosen Vorgehens und stellen sich
                komplexen Herausforderungen durch rigorose Auseinandersetzung mit der Materie.
                Sie betrachten Probleme als Rätsel der Natur, deren Lösung nur darauf wartet entdeckt zu werden.
                Sie erreichen ihr Ziel mit Sorgfalt und Hingabe.
            </p>
        </div>
    );
}

export function QuoteWhatHighlight () {
    return(
        <div>
            <h1 className="light-text centered italic bold"><i>"Information is the oil of the 21st century, and analytics is the combustion engine."</i></h1>
            <h4 className="light-text flushed-right">– Peter Sondergaard, Senior Vice President and Global Head of Research at Gartner, Inc.</h4>
        </div>
    )
}

export function TextWhatHighlight () {
    return(
        <div>
            <p className="light-text">
                Wir bieten datenzentrierte Beratungsdienstleistungen an und entwickeln individuelle Softwarelösungen
                für die Digitalisierung von Unternehmens- und Produktionsprozessen.
            </p>
            <p className="light-text">
                Unser Angebot umfasst
                <ul>
                    <li>die Durchführung von Potentialanalysen zur Bewertung von Datenqualität,</li>
                    <li>das Schaffen eines intuitiven Zugangs zu Daten durch interaktive Visualisierungen,</li>
                    <li>die Entwicklung von lernenden Modellen für die prädiktive Datenanalyse,</li>
                    <li>und das Schaffen von Transparenz und Interpretierbarkeit für die Entscheidungen von lernenden Modellen.</li>
                </ul>
            </p>
        </div>
    )
}

export function TitleWhat () {
    return(
        <div>
            <h1>Datenzentrierte Individuallösungen für hochspezialisierte Unternehmen</h1>
        </div>
    )
}

export function TextWhatA () {
    return (
        <div>
            <p>
                Die deutsche Unternehmenslandschaft ist geprägt von kleinen und mittleren Unternehmen (KMU)
                und einer Vielzahl von sogenannten Hidden Champions.
                Diese Unternehmen zeichnen sich durch ihre hochspezialisierten Lösungen aus, mit denen sie individuelle
                Nischen auf dem globalen Markt führend besetzen.
            </p>
            <p>
                Die digitale Transformation stellt diese Unternehmen vor die Herausforderung, neue, datenzentrierte
                Technologien in ihre über Jahrzehnte gewachsenen Strukturen reibungsfrei und gewinnbringend einzubinden.
            </p>
            <p>
                Wir beraten und begleiten diese Unternehmen bei ihren individuellen Herausforderungen auf ihrem Weg
                in die digitale Zukunft.
                Mit unseren vier aufeinander aufbauenden Kernkompetenzen legen wir die Grundlage für den Einsatz von
                datenzentrierten Lösungen.
            </p>
        </div>
    )
}

export function TextWhatB () {
    return (
        <div>
            <p>
                Im ersten Schritt analysieren wir die Qualität von vorliegenden Daten und bewerten deren Reifegrad für
                die Nutzung durch Verfahren des maschinellen Lernens und der künstlichen Intelligenz.
                Im zweiten Schritt schaffen wir einen intuitiven Zugang zu diesen Daten durch interaktive
                und auf die Umstände des zugrundeliegenden Prozesses zugeschnittene Visualisierungen.
                Im dritten Schritt entwickeln wir individuelle Lösungen für die prädiktive Analyse der Daten mittels
                Verfahren des maschinellen Lernens und der künstlichen Intelligenz.
                Im vierten und letzten Schritt fördern wir Transparenz und Interpretierbarkeit für die
                Entscheidungen der trainierten KI-Modelle, um Vertrauen in die Technologie zu schaffen und den Einsatz
                im produktiven Umfeld zu ermöglichen.
            </p>
        </div>
    )
}

export function TitleWhat1 () {
    return(
        <div>
            <h1>
                1. Schritt <img src={icon_crawl} className="icon-headline" alt="icon"/>
            </h1>
            <h2>
                Qualitätsbewertung & Potentialanalyse - Das Data Tasting
            </h2>
        </div>
    )
}

export function TextWhat1 () {
    return (
        <div>
            <p>
                Der erste Schritt zu einer digitalen, datenzentrierten Lösung ist das Kennenlernen der eigenen Daten.
                Denn mit Daten ist es manchmal wie mit Wein. Bevor die Entscheidung für eine Flasche fällt, wird vorher
                probiert, um herauszufinden, wozu der Wein am besten passt.
            </p>
            <p>
                Mit unserem <span className="bold italic">Data Tasting</span> in vier Schritten finden wir heraus,
                zu welchen Frage- und Zielstellungen die Daten am besten passen.
                Wir fördern die Potentiale der Daten zu Tage, analysieren deren Qualität und untersuchen die Eignung
                für den zielgerichteten Einsatz von lernenden Verfahren.
            </p>
        </div>
    )
}

export function TitleWhat2 () {
    return(
        <div>
            <h1>
                2. Schritt <img src={icon_walk} className="icon-headline" alt="icon"/>
            </h1>
            <h2>
                Interaktive Visualisierungen - Das Data Dashboard
            </h2>
        </div>
    )
}

export function TextWhat2 () {
    return (
        <div>
            <p>
                Nach dem Aufbau von Verständnis der eigenen Daten liegt der zweite Schritt darin, einen einfachen
                und intuitiven Zugang zu diesen zu schaffen.
                Menschen sind stark visuell wahrnehmende Wesen und der intuitivste Zugang zu Daten lässt sich über interaktive
                Visualisierungen erreichen.
            </p>
            <p>
                Mit unseren interaktiven <span className="bold italic">Data Dashboards</span> schaffen wir einen niederschwelligen Zugang
                zu Daten und ermöglichen den Blick durch die Datenbrille auf Unternehmens- und Produktionsprozesse.
            </p>
        </div>
    )
}

export function TitleWhat3 () {
    return(
        <div>
            <h1>
                3. Schritt <img src={icon_run} className="icon-headline" alt="icon"/>
            </h1>
            <h2>
                Prädiktive Analyse durch künstliche Intelligenz - Die SDA Toolbox
            </h2>
        </div>
    )
}

export function TextWhat3 () {
    return (
        <div>
            <p>
                Der dritte Schritt liegt in der datenzentrierten Wertschöpfung durch die zielgerichtete und gewinnbringende
                Analyse der Daten.
                Denn Daten sind nicht nur wie Wein, sondern auch wie Öl. Ohne den richtigen Raffinierungsprozess sind sie
                von geringem Wert und unter Umständen umweltschädlich, wenn sie unkontrolliert und ohne Zielstellung angehäuft werden.
            </p>
            <p>
                Mit unserer <span className="bold italic">Sensor Data Analytics (SDA) Toolbox</span> bieten wir eine Reihe
                an Methoden und Verfahren für die deskriptive und prädiktive Analyse von Sensordaten an, die wir
                individuell für jeden Anwendungsfall konfigurieren und auf die Bedürfnisse des Unternehmens und seine Prozesse zuschneiden.
            </p>
        </div>
    )
}

export function TitleWhat4 () {
    return(
        <div>
            <h1>
                4. Schritt <img src={icon_fly} className="icon-headline" alt="icon"/>
            </h1>
            <h2>
                Transparenz & Interpretierbarkeit von KI-Entscheidungen - Die XAI Toolbox
            </h2>
        </div>
    )
}

export function TextWhat4 () {
    return (
        <div>
            <p>
                Der vierte Schritt liegt im vertrauensvollen Einsatz von KI als transparenter und verlässlicher
                Unterstützer im täglichen Unternehmensbetrieb. Denn erst das Vertrauen in die Technologie und die Akzeptanz
                ihres Einsatzes ermöglichen die reibungsfreie digitale Transformation von Prozessen auf sozio-technischer
                Ebene.
            </p>
            <p>
                Mit unserer <span className="bold italic">Explainable AI (XAI) Toolbox </span> bieten wir eine Reihe an
                Methoden und Verfahren an, um die Entscheidungen von trainierten Lernmodellen aus den Bereichen des
                maschinellen Lernens und des Deep Learnings transparent und interpretierbar zu machen, sowohl im Hinblick
                auf die Wichtigkeit und Relevanz der Trainingsdaten, als auch auf der Ebene der erlernten Wissensrepräsentationen.
            </p>
        </div>
    )
}

export function TitleDataTastingA () {
    return(
        <div>
            <h1>Das Data Tasting</h1>
        </div>
    )
}

export function TextDataTastingA () {
    return(
        <div>
            <p>
                Der erste Schritt zu einer digitalen, datenzentrierten Lösung ist das Kennenlernen der eigenen Daten.
                Denn mit Daten ist es manchmal wie mit Wein. Bevor die Entscheidung für eine Flasche fällt, wird vorher
                probiert, um herauszufinden, wozu der Wein am besten passt.
            </p>
            <p>
                Mit unserem <span className="bold italic">Data Tasting</span> in vier Schritten finden wir heraus,
                zu welchen Frage- und Zielstellungen die Daten am besten passen.
                Wir fördern die Potentiale der Daten zu Tage, analysieren deren Qualität und untersuchen die Eignung
                für den zielgerichteten Einsatz von lernenden Verfahren.
            </p>
        </div>
    )
}

export function TitleDataTasting1 () {
    return(
        <div>
            <h2>Was steckt in den Daten drin?</h2>
        </div>
    )
}

export function TextDataTasting1 () {
    return(
        <div>
            <p>
                Jedes Data Tasting beginnt mit den individuellen Fragestellungen eines Unternehmens und den
                hochspezialisierten Prozessen, aus denen die Daten erhoben werden.
                Denn genauso individuell, wie das Unternehmen, die Prozesse und die
                gewachsenen Strukturen, ist das entwicklte Vorgehen zur Beantwortung dieser Fragen.
                Wir unterstützen dabei die richtigen Fragen zu stellen und erarbeiten ein maßgeschneidertes Vorgehen zur
                Beantwortung dieser Fragen, um die Wertschöpfung unserer Kunden zu steigern.
            </p>
        </div>
    )
}

export function TitleDataTasting2 () {
    return(
        <div>
            <h2>1. Aufbau von Domänenverständnis</h2>
        </div>
    )
}

export function TextDataTasting2 () {
    return(
        <div>
            <p>
                Im ersten Schritt bauen wir gemeinsam mit den Prozessexperten im Unternehmen Verständnis für die
                individuelle Domäne auf und identifizieren prozessrelevante Kenngrößen sowie prozessspezifische
                Anforderungen und Einschränkungen.
                In der Anlaufphase des Data Tastings gewinnen wir die ersten Einblicke in das Tagesgeschäft unserer
                Kunden, identifizieren relevante Datenquellen und führen eine Bestandsanalyse bezüglich bereits verfügbarer
                Daten durch.
            </p>
        </div>
    )
}

export function TitleDataTasting3 () {
    return(
        <div>
            <h2>2. Explorative Datenanalyse</h2>
        </div>
    )
}

export function TextDataTasting3 () {
    return(
        <div>
            <p>
                Im zweiten Schritt charakterisieren wir die bestehenden Datenbestände auf Basis der statistischen
                Verteilungen ihrer Beschreibungsmerkmale und führen eine Qualitätsanalyse
                hinsichtlich der sieben gängigen Qualitätskriterien Konsistenz, Einheitlichkeit, Vollständigkeit,
                Eindeutigkeit, Korrektheit, Redundanz und Plausibilität durch.
            </p>
        </div>
    )
}

export function TitleDataTasting4 () {
    return(
        <div>
            <h2>3. Prototypische Implementierung von Lernmodellen</h2>
        </div>
    )
}

export function TextDataTasting4 () {
    return(
        <div>
            <p>
                Im dritten Schritt führen wir eine Potentialanalyse der Daten durch, um die Qualität und die Eignung
                dieser für den Einsatz von maschinellen Lernverfahren und künstlicher Intelligenz zu bewerten.
                Mit einer prototypischen Umsetzung von lernenden Verfahren bewerten wir diese Eignung
                "unter Laborbedingungen" und identifizieren Herausforderungen für den produktiven Einsatz dieser Verfahren.
            </p>
        </div>
    )
}

export function TitleDataTasting5 () {
    return(
        <div>
            <h2>4. Erstellung des individuellen Data Tasting Reports</h2>
        </div>
    )
}

export function TextDataTasting5 () {
    return(
        <div>
            <p>
                Im letzten Schritt bereiten wir die gesammelten Erkenntnisse über die Daten sowie die Ergebnisse der
                Qualitätsbewertung und der Potentialanalyse in einem individuellen Data Tasting Report auf.
                Dabei identifizieren wir Maßnahmen zur Verbesserung der Datenqualität sowie zur Nutzung der erprobten
                lernenden Verfahren zur datengetriebenen Steigerung der Wertschöpfung im Unternehmen.
            </p>
        </div>
    )
}

export function TitleDataTasting6 () {
    return(
        <div>
            <h2>Wie geht es weiter?</h2>
        </div>
    )
}

export function TextDataTasting6 () {
    return(
        <div>
            <p>
                Das Data Tasting liefert einen ungefilterten Blick durch die Datenbrille auf die digitale Reife
                des untersuchten Prozesses.
                Abhängig von den gesammelten Erkenntnissen identifizieren wir gemeinsam mit unseren Kunden die nächsten
                Schritte des digitalen Heranreifens.
                Dabei identifizieren wir Maßnahmen zur Erweiterung der bestehenden Datengrundlage,
                beispielsweise durch den Einsatz geeigneter Sensorik zur Anbindung weiterer Datenquellen,
                und erarbeiten konkrete Umsetzungskonzepte für den Einsatz
                unseres <span className="bold italic">Data Dashboards</span>,
                unserer <span className="bold italic">Sensor Data Analytics Toolbox</span> und
                unserer <span className="bold italic">Explainable AI Toolbox</span>.
            </p>
        </div>
    )
}

export function TitleDataTastingB () {
    return(
        <div>
            <h1>Auf den Geschmack gekommen?</h1>
        </div>
    )
}

export function TextDataTastingB () {
    return(
        <div>
            <p>
                Hinterlassen Sie uns eine Nachricht und wir kommen auf Sie zu!
            </p>
        </div>
    )
}

export function TitleDataDashboard() {
    return(
        <div>
            <h1 className="centered">Das interaktive Data Dashboard</h1>
        </div>
    )
}

export function TextDataDashboard () {
    return(
        <div>
            <p>
                Nach dem Aufbau von Verständnis der eigenen Daten durch ein Data Tasting folgt der nächste Schritt:
                einen einfachen und intuitiven Zugang zu den Daten schaffen.
                Menschen sind stark visuell wahrnehmende Wesen und der intuitivste Zugang zu Daten lässt sich über interaktive
                Visualisierungen erreichen.
            </p>
            <p>
                Mit unseren interaktiven <span className="bold italic">Data Dashboards</span> schaffen wir einen
                niederschwelligen Zugang zu Daten und ermöglichen den Blick durch die Datenbrille auf Unternehmens- und Produktionsprozesse.
            </p>
            <p>
                Wir verfolgen bei der Entwicklung unserer Dashboards das Prinzip <span className="bold">I<sup>4</sup></span>:
                <span className="bold"> I</span>nformativ, <span className="bold">I</span>ntuitiv, <span className="bold">I</span>nteraktiv und <span className="bold">I</span>ndividuell.
            </p>
        </div>
    )
}

export function TitleDataDashboardCard1 () {
    return(
        <h4>Visuell Informativ</h4>
    )
}

export function TextDataDashboardCard1 () {
    return(
        <p className="p-small">
            Unsere Dashboards sind so designed, dass sie maximalen Informationsgehalt auf visuelle Art und Weise
            ansprechend transportieren.
            Sie geben alle wichtigen Informationen, die in den Daten enthalten sind, wieder und ermöglichen somit einen
            direkten, datengetriebenen Zugang zu den zugrundeliegenden Prozessen.
        </p>
    )
}

export function TitleDataDashboardCard2 () {
    return(
        <h4>Intuitiv & Interaktiv</h4>
    )
}

export function TextDataDashboardCard2 () {
    return(
        <p className="p-small">
            Je interaktiver der Zugang zu den Daten, desto intuitiver der Prozess des Verständnisgewinns.
            Unsere Dashboards ermöglichen eine Vielzahl an Interaktionsmöglichkeiten mit verschiedensten Visualisierungen,
            um einen spielerischen Zugang zu den Daten zu schaffen.
        </p>
    )
}

export function TitleDataDashboardCard3 () {
    return(
        <h4>Individuell</h4>
    )
}

export function TextDataDashboardCard3 () {
    return(
        <p className="p-small">
            Jedes unserer Dashboards ist so individuell, wie die Unternehmen und Prozesse unserer Kunden selbst.
            Wir stellen je nach Bedarf die passenden Visualisierungen und Interaktionsmöglichkeiten bereit, die
            von den Prozessexperten für nachgelagerte offline Analysen oder für die direkte in-line Überwachung
            benötigt werden.
        </p>
    )
}

export function TitleDataDashboardB () {
    return(
        <div>
            <h3>Dashboard Demo - Live und in Farbe!</h3>
        </div>
    )
}

export function TitleDataDashboardBars() {
    return(
        <div>
            <h4 className="light-text">Entwicklung der Stückzahlen über Zeit</h4>
        </div>
    )
}

export function TitleDataDashboardLines() {
    return(
        <div>
            <h4 className="light-text">Monitoring von Sensorwertverläufen</h4>
        </div>
    )
}

export function TitleDataDashboardScatter() {
    return(
        <div>
            <h4 className="light-text">Verteilung der Prozessparameter für Ausschussware</h4>
        </div>
    )
}

export function TitleSDAToolbox() {
    return(
        <div>
            <h1 className="centered">Die Sensor Data Analytics Toolbox</h1>
        </div>
    )
}

export function TextSDAToolbox() {
    return(
        <div>
            <p>
                Nachdem der intuitive und interaktive Zugang zu den Daten geschaffen ist, besteht der nächste Schritt
                zur datenzentrierten Wertschöpfung in der zielgerichteten und gewinnbringenden Analyse der Daten.
                Denn Daten sind nicht nur wie Wein, sondern auch wie Öl. Ohne den richtigen Raffinierungsprozess sind sie
                von geringem Wert und unter Umständen umweltschädlich, wenn sie unkontrolliert und ohne Zielstellung angehäuft werden.
            </p>
            <p>
                Mit unserer <span className="bold italic">Sensor Data Analytics (SDA) Toolbox</span> bieten wir eine Reihe
                an Methoden und Verfahren für die deskriptive und prädiktive Analyse von Sensordaten an, die wir
                individuell für jeden Anwendungsfall konfigurieren und auf die Bedürfnisse des Unternehmens und seine Prozesse zuschneiden.
            </p>
        </div>
    )
}

export function TitleSDACardReference () {
    return(
        <h4>Referenzkurvenabweichung</h4>
    )
}

export function TextSDACardReference () {
    return(
        <div>
            <p className="p-small">
                Die Frage, wie ähnlich zwei Sensorsignale sind, ist nicht leicht zu beantworten.
                Unterschiedliche Längen, Formen, Frequenzen und Abtastraten müssen berücksichtigt werden.
            </p>
            <p className="p-small">
                Die SDA Toolbox bietet eine Kombination aus <span className="italic bold">Dynamic Time Warping</span>,
                <span className="italic bold"> Matrix Profiling</span> und
                <span className="italic bold"> Time Series Embedding</span>,
                um diese herausfordernde Frage zu beantworten.
            </p>
        </div>
    )
}

export function TitleSDACardClassification () {
    return(
        <h4>Klassifizierung von Signalkurven</h4>
    )
}

export function TextSDACardClassification () {
    return(
        <div>
            <p className="p-small">
                Die überwachte Klassifizierung von Daten ist eine der häufigsten Aufgaben im Bereich des maschinellen Lernens.
            </p>
            <p className="p-small">
                Die SDA Toolbox bietet modernste Deep-Learning Modelle zur Klassifizierung von Sensorsignalen, wie beispielsweise
                <span className="italic bold"> Attention</span>-basierte
                <span className="italic bold"> LSTM-Netze</span> und
                <span className="italic bold"> Transformerarchitekturen</span>, für die
                prädiktive Qualitätskontrolle.
            </p>
        </div>
    )
}

export function TitleSDACardForecasting () {
    return(
        <h4>Forecasting von Signalkurven</h4>
    )
}

export function TextSDACardForecasting () {
    return(
        <div>
            <p className="p-small">
                Die genaue Vorhersage des zeitlichen Verlaufs von Sensorkurven ermöglicht
                die Reaktion auf mögliche Prozessausfälle, bevor diese tatsächlich eintreten.
            </p>
            <p className="p-small">
                Die SDA Toolbox bietet modernste Deep-Learning-Modelle zur Vorhersage von unregelmäßigen Sensorsignalen,
                wie beispielsweise <span className="italic bold">Attention</span>-basierte
                <span className="italic bold"> LSTM-Netze</span> und
                <span className="italic bold"> Transformerarchitekturen </span>,
                um das künftige Auftreten von Prozessausfällen vorherzusagen und präventiv einzugreifen statt nachgelagert zu reagieren.
            </p>
        </div>
    )
}

export function TitleSDACardAnomaly () {
    return(
        <h4>Anomalieerkennung in Sensorsignalen</h4>
    )
}

export function TextSDACardAnomaly () {
    return(
        <div>
            <p className="p-small">
                Industrielle Prozesse laufen wie ein Uhrwerk. Doch jedes Uhrwerk gerät manchmal ins Stocken
                und es ist von größter Bedeutung herauszufinden, ob sich ein Prozess ungewöhnlich verhält.
            </p>
            <p className="p-small">
                Die SDA Toolbox bietet eine Kombination aus statistischen Verfahren und modernsten Deep-Learning-Modellen,
                wie beispielsweise <span className="italic bold"> Matrix Profiling</span>,
                <span className="italic bold"> Time Series Embedding</span>,
                <span className="italic bold"> LSTM-Netze</span> und
                <span className="italic bold"> Transformerarchitekturen </span>
                zur Erkennung von Anomalien in Sensorsignalen.
            </p>
        </div>
    )
}


export function TitleSDACardSoft () {
    return(
        <h4>Soft-Sensor Entwicklung</h4>
    )
}

export function TextSDACardSoft () {
    return(
        <div>
            <p className="p-small">
                Je mehr Daten, desto besser!? Im realen Produktionsprozess bedeuten mehr Daten auch mehr physische Sensoren,
                höhere Kosten und ein größeres Fehlerpotential.
            </p>
            <p className="p-small">
                Die SDA Toolbox bietet eine Reihe an statistischen Verfahren und lernenden Modellen, wie beispielsweise
                <span className="italic bold"> Sensitivitäts-</span> und <span className="italic bold"> Korrelationsanlysen </span>
                in Kombination mit lernenden <span className="italic bold"> Regressionsmodellen</span> zur intelligenten,
                software-basierten Rekonstruktion von Signalkurven physischer Sensoren, um diese durch Soft-Sensors
                zu ersetzen.
            </p>
        </div>
    )
}

export function TitleXAIToolbox() {
    return(
        <div>
            <h1 className="centered">Die Explainable AI Toolbox</h1>
        </div>
    )
}

export function TextXAIToolbox() {
    return(
        <div>
            <p>
                Im Anschluss an die prädiktive Analyse der Daten durch lernende Verfahren liegt der letzte Schritt zum
                vertrauenvollen Einsatz von KI darin, diese als transparenten und verlässlichen Unterstützer im
                täglichen Unternehmensbetrieb zu entwickeln. Denn erst das Vertrauen in die Technologie und die Akzeptanz
                ihres Einsatzes ermöglichen die reibungsfreie digitale Transformation von Prozessen auf sozio-technischer
                Ebene.
            </p>
            <p>
                Mit unserer <span className="bold italic">Explainable AI (XAI) Toolbox </span> bieten wir eine Reihe an
                Methoden und Verfahren an, um die Entscheidungen von trainierten Lernmodellen aus den Bereichen des
                maschinellen Lernens und des Deep Learnings transparent und interpretierbar zu machen, sowohl im Hinblick
                auf die Wichtigkeit und Relevanz der Trainingsdaten, als auch auf der Ebene der erlernten Wissensrepräsentationen.
            </p>
        </div>
    )
}

export function TitleXAICardFeature () {
    return(
        <h4>Feature Importance</h4>
    )
}

export function TextXAICardFeature () {
    return(
        <div>
            <p className="p-small">
                Die unterschiedlichen Parameter eines Produktionsprozesses haben unterschiedlich starken Einfluss auf
                die Produktqualität oder die Lebenserwartung der Maschinen.
            </p>
            <p className="p-small">
                Die XAI Toolbox bietet eine Reihe an Verfahren, wie beispielsweise
                <span className="italic bold"> Gini impurity ranking</span> und
                <span className="italic bold"> Feature permutation</span>, zur Bewertung der Wichtigkeit einzelner
                Merkmale für Lernaufgaben, wie die Klassifizierung von Ausschussware.
            </p>
        </div>
    )
}

export function TitleXAICardAblations () {
    return(
        <h4>Charakterisierung von Netzwerkarealen</h4>
    )
}

export function TextXAICardAblations () {
    return(
        <div>
            <p className="p-small">
                Welche Teile eines neuronalen Netzes sind für eine trainierte Aufgabe wichtig? Welche Teile sind für
                fehlerhafte Vorhersagen verantwortlich?
            </p>
            <p className="p-small">
                Die XAI Toolbox bietet eine Reihe an neurowissenschaftlich inspirierten Methoden, wie beispielsweise
                <span className="italic bold"> Netzwerk-Ablationen</span>, zur Charakterisierung der
                erlernten Repräsentationen und zur Identifikation von Schwachstellen in trainierten Netzen.
            </p>
        </div>
    )
}

export function TitleXAICardMotifs () {
    return(
        <h4>Time Series Motif Mining</h4>
    )
}

export function TextXAICardMotifs () {
    return(
        <div>
            <p className="p-small">
                Wenn etwas schief geht, wollen wir in der Regel wissen, was es war und warum es passiert ist.
            </p>
            <p className="p-small">
                Die XAI Toolbox bietet eine Reihe an Verfahren, wie beispielsweise
                <span className="italic bold"> Grad-CAM</span> und
                <span className="italic bold"> Netzwerk-Ablationen</span>, zur Extrahierung von relevanten
                Sensorsignalmotiven zur Erklärung von Prozessfehlern.
            </p>
        </div>
    )
}

export function TitleDataDashboardWarning() {
    return(
        <div>
            <h4>
                Warnung!
            </h4>
        </div>
    )
}

export function TextDataDashboardWarning() {
    return(
        <div>
            <p>
                Unser Data Dashboard ist für die Anzeige auf großen Displays in Produktionslinien von Fabikhallen oder
                auf großen Monitoren mit einer Breite von mindestens 1200 Pixeln ausgelegt.
                Die Breite Ihres Anzeigedisplays ist zu klein für die saubere Darstellung des Dashboards.
                Bitte wechseln Sie auf ein größeres Display.
            </p>
        </div>
    )
}