import React from "react";
import PrivacyPolicyOSFA from "../components/pages/0_osfa/privacy_policy_osfa";


export default function PrivacyPolicy() {
    return (
        <div>
            <PrivacyPolicyOSFA />
        </div>
    );
}