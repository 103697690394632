import {Card, Col, Row} from "antd";
import React, {useLayoutEffect, useRef, useState} from "react";
import {
    TextSDACardReference,
    TextSDACardClassification,
    TextSDACardForecasting,
    TextSDACardAnomaly,
    TextSDACardSoft,
    TextSDAToolbox,
    TitleSDACardReference,
    TitleSDACardClassification,
    TitleSDACardForecasting,
    TitleSDACardAnomaly,
    TitleSDACardSoft,
    TitleSDAToolbox
} from "../../text_content";
import img_sda_ref from "../../../images/sda-1.webp"
import img_sda_class from "../../../images/sda-2.webp"
import img_sda_fore from "../../../images/sda-3.webp"
import img_sda_ano from "../../../images/sda-4.webp"
import img_sda_soft from "../../../images/sda-5.webp"


const { Meta } = Card;


export default function SDAToolboxXXL() {
    const [heightCard, setHeightCard] = useState(0)
    const refCardHeight = useRef<HTMLDivElement>(null)
    useLayoutEffect(() => {
        if(refCardHeight.current) {
            setHeightCard(refCardHeight.current.clientHeight);
        }
    }, []);

    return (
        <div>
            <Row justify="center" className="padded-t50">
                <Col span={20}>
                    <TitleSDAToolbox />
                    <TextSDAToolbox />
                </Col>
            </Row>

            <Row justify="center" className="padded-t50">
                <Col span={20}>
                    <Row justify="space-between">
                        <Col span={8}>
                            <Card
                                bordered={true}
                                className="custom-card"
                                style={{height: heightCard}}
                                cover={
                                    <img
                                        className="sda-card-cover"
                                        alt=""
                                        src={img_sda_ref}
                                    />
                                }
                            >
                                <Meta
                                    title={<TitleSDACardReference />}
                                    description={<TextSDACardReference />}
                                />
                            </Card>
                        </Col>
                        <Col span={8}>
                            <Card
                                bordered={true}
                                className="custom-card"
                                style={{height: heightCard}}
                                cover={
                                    <img
                                        className="sda-card-cover"
                                        alt=""
                                        src={img_sda_class}
                                    />
                                }
                            >
                                <Meta
                                    title={<TitleSDACardClassification />}
                                    description={<TextSDACardClassification />}
                                />
                            </Card>
                        </Col>
                        <Col span={8}>
                            <Card
                                bordered={true}
                                className="custom-card"
                                style={{height: heightCard}}
                                cover={
                                    <img
                                        className="sda-card-cover"
                                        alt=""
                                        src={img_sda_fore}
                                    />
                                }
                            >
                                <Meta
                                    title={<TitleSDACardForecasting />}
                                    description={<TextSDACardForecasting />}
                                />
                            </Card>
                        </Col>
                    </Row>
                </Col>
            </Row>

            <Row justify="start" className="padded-t50 padded-b100">
                <Col span={20} offset={2}>
                    <Row justify="space-between">
                        <Col span={8}>
                            <Card
                                bordered={true}
                                className="custom-card"
                                style={{height: heightCard}}
                                cover={
                                    <img
                                        className="sda-card-cover"
                                        alt=""
                                        src={img_sda_ano}
                                    />
                                }
                            >
                                <Meta
                                    title={<TitleSDACardAnomaly />}
                                    description={<TextSDACardAnomaly />}
                                />
                            </Card>
                        </Col>
                        <Col span={8}>
                            <Card
                                bordered={true}
                                className="custom-card"
                                ref={refCardHeight}
                                cover={
                                    <img
                                        className="sda-card-cover"
                                        alt=""
                                        src={img_sda_soft}
                                    />
                                }
                            >
                                <Meta
                                    title={<TitleSDACardSoft />}
                                    description={<TextSDACardSoft />}
                                />
                            </Card>
                        </Col>
                        <Col span={8}>

                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    );
}

