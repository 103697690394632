import React, {useLayoutEffect, useRef, useState} from 'react';
import {Col, Row, Carousel, Divider, Button} from "antd";
import {RightOutlined, LeftOutlined} from '@ant-design/icons';
import carousel_gif_digtrans from "../../../images/carousel_digtrans.webp"
import carousel_gif_datatasting from "../../../images/carousel_datatasting.webp"
import carousel_gif_datadashboard from "../../../images/carousel_datadashboard.webp"
import carousel_gif_sensordataanalytics from "../../../images/carousel_sda.webp"
import carousel_gif_xai from "../../../images/carousel_xai.webp"
import img_why from "../../../images/why-spaced.webp"
import img_how from "../../../images/how-spaced.webp"
import img_what from "../../../images/what.webp"
import logo_buw from "../../../images/buw-logo.webp"
import logo_tmdt from "../../../images/tmdt-logo.webp"
import logo_izmd from "../../../images/izmd-logo.webp"
import logo_bit from "../../../images/bit-logo.webp"
import img_industry_hws from "../../../images/industry-hws.webp"
import img_industry_dd from "../../../images/industry-deepdrawing.webp"
import img_industry_tape from "../../../images/industry-tape.webp"
import img_industry_windshield from "../../../images/industry-windshield.webp"
import {
    CarouselTextDataDashboard,
    CarouselTextDataTasting,
    CarouselTextDigitalisierung,
    CarouselTextHochwasser,
    TextHowShort,
    CarouselTextTapes,
    CarouselTextSensorDataAnalyticsToolbox,
    CarouselTextTiefziehen,
    TextWhatShort,
    TextWhyShort,
    CarouselTextWindshields,
    CarouselTextXAIToolbox,
    CarouselTitleDataDashboard,
    CarouselTitleDataTasting,
    CarouselTitleDigitalisierung,
    CarouselTitleHochwasser,
    TitleHomeCarousel,
    CarouselTitleTapes,
    CarouselTitleSensorDataAnalyticsToolbox,
    CarouselTitleTiefziehen,
    TitleGoldenCircle,
    CarouselTitleWindshields,
    CarouselTitleXAIToolbox,
    TitleResearchPartners,
    CarouselQuoteDigitalisierungPart2,
    CarouselQuoteDigitalisierungPart1,
} from "../../text_content";
import colorScheme from "../../../scss/index.scss";


export default function HomeMega() {
    return (
        <div>
            <Row justify="center" className="bg-dark padded-t50 padded-b100">
                <Col span={18}>
                    <Carousel dots={true}
                              dotPosition="bottom"
                              effect="fade"
                              autoplaySpeed={5000}
                              arrows={true}
                              autoplay={true}
                              nextArrow={<RightOutlined />}
                              prevArrow={<LeftOutlined/>}
                    >
                        <div>
                            <CarouselDigital />
                        </div>
                        <div>
                            <CarouselDataTasting />
                        </div>
                        <div>
                            <CarouselDataDashboard />
                        </div>
                        <div>
                            <CarouselSDAToolbox />
                        </div>
                        <div>
                            <CarouselXAIToolbox />
                        </div>
                    </Carousel>
                </Col>
            </Row>

            <Row justify="center" className="padded-t50">
                <Col span={18}>
                    {TitleGoldenCircle("")}
                </Col>
            </Row>
            <Row justify="center" className="padded-b50">
                <Col span={18}>
                    <Row justify="space-between">
                        <Col span={7}>
                            <WhyShort />
                        </Col>
                        <Col span={7}>
                            <HowShort />
                        </Col>
                        <Col span={7}>
                            <WhatShort />
                        </Col>
                    </Row>
                    <Row justify="space-between">
                        <Col span={7}>
                            <Button
                                className="btn-secondary horizontally-centered"
                                onClick={event =>  window.location.href='/mission'}
                            >
                                Unsere Mission
                            </Button>
                        </Col>
                        <Col span={7}>
                            <Button
                                className="btn-secondary horizontally-centered"
                                onClick={event =>  window.location.href='/philosophy'}
                            >
                                Unsere Philosophie
                            </Button>
                        </Col>
                        <Col span={7}>
                            <Button
                                className="btn-secondary horizontally-centered"
                                onClick={event =>  window.location.href='/solutions'}
                            >
                                Unsere Lösungen
                            </Button>
                        </Col>
                    </Row>
                </Col>
            </Row>

            <Divider style={{borderColor: colorScheme.colorSoothingDarkBlue, borderWidth: "3px"}}/>

            <Row justify="center" className="padded-t50 padded-b100">
                <Col span={18}>
                    {TitleHomeCarousel("centered")}
                    <Carousel dots={true}
                              dotPosition="bottom"
                              effect="scrollx"
                              autoplaySpeed={5000}
                              arrows={true}
                              autoplay={true}
                              nextArrow={<RightOutlined />}
                              prevArrow={<LeftOutlined/>}
                              className="ant-carousel2"
                    >
                        <div>
                            <CarouselHochwasser />
                        </div>
                        <div>
                            <CarouselTiefziehen />
                        </div>
                        <div>
                            <CarouselTapes />
                        </div>
                        <div>
                            <CarouselWindshields />
                        </div>
                    </Carousel>
                </Col>
            </Row>

            <Divider style={{borderColor: colorScheme.colorSoothingDarkBlue, borderWidth: "3px"}}/>

            <Row justify="center">
                <Col span={18}>
                    <Row justify="space-between" className="padded-t50">
                        <Col span={24}>
                            <TitleResearchPartners/>
                        </Col>
                    </Row>
                    <Row justify="space-between" className="padded-b50">
                        <Col span={24}>
                            <Row justify="space-evenly">
                                <Col span={4}>
                                    <a href="https://www.tmdt.uni-wuppertal.de" target="_blank" rel="noreferrer">
                                        <img src={logo_tmdt} className="logo-partner" alt="Logo"/>
                                    </a>
                                </Col>
                                <Col span={4}>
                                    <a href="https://www.uni-wuppertal.de" target="_blank" rel="noreferrer">
                                        <img src={logo_buw} className="logo-partner" alt="Logo"/>
                                    </a>
                                </Col>
                                <Col span={4}>
                                    <a href="https://www.izmd.uni-wuppertal.de" target="_blank" rel="noreferrer">
                                        <img src={logo_izmd} className="logo-partner" alt="Logo"/>
                                    </a>
                                </Col>
                                <Col span={4}>
                                    <a href="https://www.bit-ki.de" target="_blank" rel="noreferrer">
                                        <img src={logo_bit} className="logo-partner" alt="Logo"/>
                                    </a>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    );
}

function WhyShort () {
    return (
        <div>
            <Row>
                <Col span={24}>
                    <img src={img_why} className="img-half" alt=""/>
                </Col>
            </Row>
            <Row className="padded-t25">
                <Col span={24}>
                    {TextWhyShort("centered")}
                </Col>
            </Row>
        </div>
    )
}

function HowShort () {
    return (
        <div>
            <Row>
                <Col span={24}>
                    <img src={img_how} className="img-half" alt=""/>
                </Col>
            </Row>
            <Row className="padded-t25">
                <Col span={24}>
                    {TextHowShort("centered")}
                </Col>
            </Row>
        </div>
    )
}

function WhatShort () {
    return (
        <div>
            <Row>
                <Col span={24}>
                    <img src={img_what} className="img-half" alt=""/>
                </Col>
            </Row>
            <Row className="padded-t25">
                <Col span={24}>
                    {TextWhatShort("centered")}
                </Col>
            </Row>
        </div>
    )
}

function CarouselDigital() {
    const [heightImage, setHeightImage] = useState(0)
    const refColHeight = useRef<HTMLDivElement>(null)
    useLayoutEffect(() => {
        if(refColHeight.current) {
            setHeightImage(refColHeight.current.clientHeight);
        }
    }, []);

    return (
        <div>
            <Row align="middle" justify="center">
                <Col flex="0 1 42%" ref={refColHeight}>
                    <Row>
                        <CarouselTitleDigitalisierung />
                    </Row>
                    <Row>
                        <CarouselQuoteDigitalisierungPart1 />
                    </Row>
                    <Row justify="end">
                        <CarouselQuoteDigitalisierungPart2 />
                    </Row>
                    <Row>
                        <CarouselTextDigitalisierung />
                    </Row>
                </Col>
                <Col flex="0 1 50%" offset={1}>
                    <img src={carousel_gif_digtrans} className="carousel-gif" alt="Ani" style={{height: heightImage}}/>
                </Col>
            </Row>
        </div>
    )
}

function CarouselDataTasting() {
    const [heightImage, setHeightImage] = useState(0)
    const refColHeight = useRef<HTMLDivElement>(null)
    useLayoutEffect(() => {
        if(refColHeight.current) {
            setHeightImage(refColHeight.current.clientHeight);
        }
    }, []);

    return (
        <div>
            <Row align="middle" justify="center">
                <Col flex="0 1 42%" ref={refColHeight}>
                    <Row>
                        <CarouselTitleDataTasting />
                    </Row>
                    <Row>
                        <CarouselTextDataTasting />
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Button type="primary"
                                    className="horizontally-centered"
                                    onClick={event =>  window.location.href='/solutions/data_tasting'}
                            >
                                Zum Data Tasting!
                            </Button>
                        </Col>
                    </Row>
                </Col>
                <Col flex="0 1 35%" offset={1}>
                    <img src={carousel_gif_datatasting} className="carousel-gif" alt="Ani" style={{height: heightImage}}/>
                </Col>
            </Row>
        </div>
    )
}

function CarouselDataDashboard() {
    const [heightImage, setHeightImage] = useState(0)
    const refColHeight = useRef<HTMLDivElement>(null)
    useLayoutEffect(() => {
        if(refColHeight.current) {
            setHeightImage(refColHeight.current.clientHeight);
        }
    }, []);

    return (
        <div>
            <Row align="middle" justify="center">
                <Col flex="0 1 55%" ref={refColHeight}>
                    <Row>
                        <CarouselTitleDataDashboard />
                    </Row>
                    <Row>
                        <CarouselTextDataDashboard />
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Button type="primary"
                                    className="horizontally-centered"
                                    onClick={event =>  window.location.href='/solutions/data_dashboard'}
                            >
                                Zum Data Dashboard!
                            </Button>
                        </Col>
                    </Row>
                </Col>
                <Col flex="0 1 35%" offset={1}>
                    <img src={carousel_gif_datadashboard} className="carousel-gif" alt="Ani" style={{height: heightImage}}/>
                </Col>
            </Row>
        </div>
    )
}

function CarouselSDAToolbox() {
    const [heightImage, setHeightImage] = useState(0)
    const refColHeight = useRef<HTMLDivElement>(null)
    useLayoutEffect(() => {
        if(refColHeight.current) {
            setHeightImage(refColHeight.current.clientHeight);
        }
    }, []);

    return (
        <div>
            <Row align="middle" justify="center">
                <Col flex="0 1 50%" ref={refColHeight}>
                    <Row>
                        <CarouselTitleSensorDataAnalyticsToolbox />
                    </Row>
                    <Row>
                        <CarouselTextSensorDataAnalyticsToolbox />
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Button type="primary"
                                    className="horizontally-centered"
                                    onClick={event =>  window.location.href='/solutions/sda_toolbox'}
                            >
                                Zur SDA Toolbox!
                            </Button>
                        </Col>
                    </Row>
                </Col>
                <Col flex="0 1 42%" offset={1}>
                    <img src={carousel_gif_sensordataanalytics} className="carousel-gif" alt="Ani" style={{height: heightImage}}/>
                </Col>
            </Row>
        </div>
    )
}

function CarouselXAIToolbox() {
    const [heightImage, setHeightImage] = useState(0)
    const refColHeight = useRef<HTMLDivElement>(null)
    useLayoutEffect(() => {
        if(refColHeight.current) {
            setHeightImage(refColHeight.current.clientHeight);
        }
    }, []);

    return (
        <div>
            <Row align="middle" justify="center">
                <Col flex="0 1 55%" ref={refColHeight}>
                    <Row>
                        <CarouselTitleXAIToolbox />
                    </Row>
                    <Row>
                        <CarouselTextXAIToolbox />
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Button type="primary"
                                    className="horizontally-centered"
                                    onClick={event =>  window.location.href='/solutions/xai_toolbox'}
                            >
                                Zur XAI Toolbox!
                            </Button>
                        </Col>
                    </Row>
                </Col>
                <Col flex="0 1 35%" offset={1}>
                    <img src={carousel_gif_xai} className="carousel-gif" alt="Ani" style={{height: heightImage}}/>
                </Col>
            </Row>
        </div>
    )
}

function CarouselHochwasser() {
    const [heightImage, setHeightImage] = useState(0)
    const refColHeight = useRef<HTMLDivElement>(null)
    useLayoutEffect(() => {
        if(refColHeight.current) {
            setHeightImage(refColHeight.current.clientHeight);
        }
    }, []);

    return (
        <div>
            <Row align="middle" justify="center">
                <Col flex="0 1 25%" ref={refColHeight}>
                    <Row>
                        {CarouselTitleHochwasser("centered")}
                        <CarouselTextHochwasser />
                    </Row>
                </Col>
                <Col flex="0 1 50%" offset={1}>
                    <Row>
                        <img src={img_industry_hws} className="img-full" alt="Logo" style={{height: heightImage}}/>
                    </Row>
                </Col>
            </Row>
        </div>
    )
}

function CarouselTiefziehen() {
    const [heightImage, setHeightImage] = useState(0)
    const refColHeight = useRef<HTMLDivElement>(null)
    useLayoutEffect(() => {
        if(refColHeight.current) {
            setHeightImage(refColHeight.current.clientHeight);
        }
    }, []);

    return (
        <div>
            <Row align="middle" justify="center">
                <Col flex="0 1 35%" ref={refColHeight}>
                    <Row>
                        <Col span={24}>
                            {CarouselTitleTiefziehen("centered")}
                            <CarouselTextTiefziehen />
                        </Col>
                    </Row>
                </Col>
                <Col flex="0 1 50%" offset={1}>
                    <Row>
                        <img src={img_industry_dd} className="img-full" alt="Logo" style={{height: heightImage}}/>
                    </Row>
                </Col>
            </Row>
        </div>
    )
}

function CarouselTapes() {
    const [heightImage, setHeightImage] = useState(0)
    const refColHeight = useRef<HTMLDivElement>(null)
    useLayoutEffect(() => {
        if(refColHeight.current) {
            setHeightImage(refColHeight.current.clientHeight);
        }
    }, []);

    return (
        <div>
            <Row align="middle" justify="center">
                <Col flex="0 1 25%" ref={refColHeight}>
                    <Row>
                        {CarouselTitleTapes("centered")}
                        <CarouselTextTapes />
                    </Row>
                </Col>
                <Col flex="0 1 50%" offset={1}>
                    <Row>
                        <img src={img_industry_tape} className="img-full" alt="Logo" style={{height: heightImage}}/>
                    </Row>
                </Col>
            </Row>
        </div>
    )
}

function CarouselWindshields() {
    const [heightImage, setHeightImage] = useState(0)
    const refColHeight = useRef<HTMLDivElement>(null)
    useLayoutEffect(() => {
        if(refColHeight.current) {
            setHeightImage(refColHeight.current.clientHeight);
        }
    }, []);

    return (
        <div>
            <Row align="middle" justify="center">
                <Col flex="0 1 25%" ref={refColHeight}>
                    <Row>
                        {CarouselTitleWindshields("centered")}
                        <CarouselTextWindshields />
                    </Row>
                </Col>
                <Col flex="0 1 50%" offset={1}>
                    <Row>
                        <img src={img_industry_windshield} className="img-full" alt="Logo" style={{height: heightImage}}/>
                    </Row>
                </Col>
            </Row>
        </div>
    )
}