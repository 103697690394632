import React from "react";
import {useMediaQuery} from "react-responsive";
import DataTastingXXS from "../components/pages/1_xxs/data_tasting_xxs";
import DataTastingXS from "../components/pages/2_xs/data_tasting_xs";
import DataTastingSM from "../components/pages/3_sm/data_tasting_sm";
import DataTastingMD from "../components/pages/4_md/data_tasting_md";
import DataTastingLG from "../components/pages/5_lg/data_tasting_lg";
import DataTastingXL from "../components/pages/6_xl/data_tasting_xl";
import DataTastingXXL from "../components/pages/7_xxl/data_tasting_xxl";
import DataTastingMega from "../components/pages/8_mega/data_tasting_mega";


export default function DataTasting() {
    const size_xxs = useMediaQuery({query: "(max-device-width: 479px)"});
    const size_xs = useMediaQuery({query: "(min-device-width: 480px) and (max-device-width: 577px)"});
    const size_sm = useMediaQuery({query: "(min-device-width: 578px) and (max-device-width: 767px)"});
    const size_md = useMediaQuery({query: "(min-device-width: 768px) and (max-device-width: 991px)" });
    const size_lg = useMediaQuery({query: "(min-device-width: 992px) and (max-device-width: 1199px)"});
    const size_xl = useMediaQuery({query: "(min-device-width: 1200px) and (max-device-width: 1599px)"});
    const size_xxl = useMediaQuery({query: "(min-device-width: 1600px) and (max-device-width: 1919px)"});
    const size_mega = useMediaQuery({query: "(min-device-width: 1920px)"});


    return (
        <div>
            {size_xxs && <DataTastingXXS />}
            {size_xs && <DataTastingXS />}
            {size_sm && <DataTastingSM />}
            {size_md && <DataTastingMD />}
            {size_lg && <DataTastingLG/>}
            {size_xl && <DataTastingXL />}
            {size_xxl && <DataTastingXXL />}
            {size_mega && <DataTastingMega />}
        </div>
    );
}