import {Row, Col, Menu, Divider, Button, Drawer} from 'antd';
import {
    HomeOutlined,
    CoffeeOutlined,
    TrophyOutlined,
    TeamOutlined,
    MenuOutlined,
    EyeOutlined,
    SolutionOutlined,
    DashboardOutlined,
    LineChartOutlined,
    BulbOutlined,
    RocketOutlined
} from '@ant-design/icons';
import logo_header from "../images/logo_header.png";
import React, {useState} from "react";
import {useMediaQuery} from "react-responsive";
import colorScheme from "../scss/index.scss";


const { SubMenu } = Menu;

const handleClick = (e: any) => {
    console.log('click ', e);
};


export default function PageHeader() {
    const size_xxs = useMediaQuery({query: "(max-device-width: 479px)"});
    const size_xs = useMediaQuery({query: "(min-device-width: 480px) and (max-device-width: 575px)"});
    const size_sm = useMediaQuery({query: "(min-device-width: 576px) and (max-device-width: 767px)"});
    const size_md = useMediaQuery({query: "(min-device-width: 768px) and (max-device-width: 991px)" });
    const size_lg = useMediaQuery({query: "(min-device-width: 992px) and (max-device-width: 1199px)"});
    const size_xl = useMediaQuery({query: "(min-device-width: 1200px) and (max-device-width: 1599px)"});
    const size_xxl = useMediaQuery({query: "(min-device-width: 1600px)"});

    return (
        <div>
            {size_xxs && <PageHeaderDrawer />}
            {size_xs && <PageHeaderDrawer />}
            {size_sm && <PageHeaderDrawer />}
            {size_md && <PageHeaderDrawer />}
            {size_lg && <PageHeaderNormal />}
            {size_xl && <PageHeaderNormal />}
            {size_xxl && <PageHeaderNormal />}
            <Divider style={{borderColor: "whitesmoke", borderWidth: 3}}/>
        </div>
    );
}

function PageHeaderNormal() {
    return (
        <div style={{background: colorScheme.colorSoothingDarkBlue}}>
            <Row>
                <Col flex={2}/>
                <Col flex={1}>
                    <a href="/">
                        <img src={logo_header} className={'header-logo'} alt="Logo"/>
                    </a>
                </Col>
                <Col flex={9}>
                    <Menu onClick={handleClick} mode="horizontal">
                        <Menu.Item icon={<HomeOutlined style={{fontSize: "14pt"}}/>}>
                            <a href="/">Home</a>
                        </Menu.Item>
                        <SubMenu icon={<TeamOutlined style={{fontSize: "14pt"}}/>} key="SubMenu2" title="About Us">
                            <Menu.Item icon={<RocketOutlined style={{fontSize: "14pt"}}/>}>
                                <a href="/mission">Unsere Mission</a>
                            </Menu.Item>
                            <Menu.Item icon={<CoffeeOutlined style={{fontSize: "14pt"}}/>}>
                                <a href="/philosophy">Unsere Philosophie</a>
                            </Menu.Item>
                        </SubMenu>
                        <SubMenu icon={<TrophyOutlined style={{fontSize: "14pt"}}/>} key="SubMenu" title="Solutions">
                            <Menu.Item icon={<EyeOutlined style={{fontSize: "14pt"}}/>}>
                                <a href="/solutions">Übersicht</a>
                            </Menu.Item>
                            <Divider style={{borderColor: "whitesmoke"}}/>
                            <Menu.ItemGroup title="Unsere vier Kernkompetenzen">
                                <Menu.Item icon={<SolutionOutlined style={{fontSize: "14pt"}}/>}>
                                    <a href="/solutions/data_tasting">1 - Data Tasting</a>
                                </Menu.Item>
                                <Menu.Item icon={<DashboardOutlined style={{fontSize: "14pt"}}/>}>
                                    <a href="/solutions/data_dashboard">2 - Data Dashboard</a>
                                </Menu.Item>
                                <Menu.Item icon={<LineChartOutlined style={{fontSize: "14pt"}}/>}>
                                    <a href="/solutions/sda_toolbox">3 - SDA Toolbox</a>
                                </Menu.Item>
                                <Menu.Item icon={<BulbOutlined style={{fontSize: "14pt"}}/>}>
                                    <a href="/solutions/xai_toolbox">4 - XAI Toolbox</a>
                                </Menu.Item>
                            </Menu.ItemGroup>
                        </SubMenu>
                    </Menu>
                </Col>
                <Col flex={2}/>
            </Row>
        </div>
    );
}


function PageHeaderDrawer() {
    const [visible, setVisible] = useState(false)

    const showDrawer = () => {
        setVisible(true)
    }

    const onClose = () => {
        setVisible(false)
    }

    return (
        <div>
            <Row justify="space-between" align="bottom">
                <Col flex="1 2 50%">
                    <a href="/">
                        <img src={logo_header} className='header-logo' alt="Logo"/>
                    </a>
                </Col>
                <Col flex="2 1 50%">
                    <Button className="btn-menu" onClick={showDrawer}>
                        <MenuOutlined />
                    </Button>
                    <Drawer
                        placement="right"
                        closable={false}
                        onClose={onClose}
                        visible={visible}
                        headerStyle={{background: colorScheme.colorSoothingDarkBlue, color: "whitesmoke"}}
                        bodyStyle={{background: colorScheme.colorSoothingDarkBlue}}
                        drawerStyle={{background: colorScheme.colorSoothingDarkBlue}}
                    >
                        <Menu onClick={handleClick} mode="inline" style={{minWidth: 0, flex: "auto"}}>
                            <Menu.Item icon={<HomeOutlined style={{fontSize: "14pt"}}/>}>
                                <a href="/">Home</a>
                            </Menu.Item>
                            <SubMenu icon={<TeamOutlined style={{fontSize: "14pt"}}/>} key="SubMenu2" title="About Us">
                                <Menu.Item icon={<RocketOutlined style={{fontSize: "14pt"}}/>}>
                                    <a href="/mission">Unsere Mission</a>
                                </Menu.Item>
                                <Menu.Item icon={<CoffeeOutlined style={{fontSize: "14pt"}}/>}>
                                    <a href="/philosophy">Unsere Philosophie</a>
                                </Menu.Item>
                            </SubMenu>
                            <SubMenu icon={<TrophyOutlined style={{fontSize: "14pt"}}/>} key="SubMenu" title="Solutions">
                                <Menu.Item icon={<EyeOutlined style={{fontSize: "14pt"}}/>}>
                                    <a href="/solutions">Übersicht</a>
                                </Menu.Item>
                                <Divider style={{borderColor: "whitesmoke"}}/>
                                <Menu.ItemGroup title="Unsere vier Kernkompetenzen">
                                    <Menu.Item icon={<SolutionOutlined style={{fontSize: "14pt"}}/>}>
                                        <a href="/solutions/data_tasting">1 - Data Tasting</a>
                                    </Menu.Item>
                                    <Menu.Item icon={<DashboardOutlined style={{fontSize: "14pt"}}/>}>
                                        <a href="/solutions/data_dashboard">2 - Data Dashboard</a>
                                    </Menu.Item>
                                    <Menu.Item icon={<LineChartOutlined style={{fontSize: "14pt"}}/>}>
                                        <a href="/solutions/sda_toolbox">3 - SDA Toolbox</a>
                                    </Menu.Item>
                                    <Menu.Item icon={<BulbOutlined style={{fontSize: "14pt"}}/>}>
                                        <a href="/solutions/xai_toolbox">4 - XAI Toolbox</a>
                                    </Menu.Item>
                                </Menu.ItemGroup>
                            </SubMenu>
                        </Menu>
                    </Drawer>
                </Col>
            </Row>
        </div>
    );
}
