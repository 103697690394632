import {Col, Row} from "antd";
import React from "react";
import icon_dt_1 from "../../../images/data-tasting-1.webp"
import icon_dt_2 from "../../../images/data-tasting-2.webp"
import icon_dt_3 from "../../../images/data-tasting-3.webp"
import icon_dt_4 from "../../../images/data-tasting-4.webp"
import icon_dt_5 from "../../../images/data-tasting-5.webp"
import icon_dt_6 from "../../../images/data-tasting-6.webp"
import {
    TextDataTastingA,
    TextDataTasting1,
    TextDataTasting2,
    TextDataTasting3,
    TextDataTasting4,
    TextDataTasting5,
    TextDataTasting6,
    TitleDataTastingA,
    TitleDataTasting1,
    TitleDataTasting2,
    TitleDataTasting3,
    TitleDataTasting4,
    TitleDataTasting5,
    TitleDataTasting6,
} from "../../text_content";


export default function DataTastingMD() {
    return (
        <div>
            <Row justify="center" className="padded-t50">
                <Col span={22}>
                    <TitleDataTastingA />
                </Col>
            </Row>
            <Row justify="center">
                <Col span={22}>
                    <TextDataTastingA />
                </Col>
            </Row>
            <Row align="top" justify="center" className="padded-t50">
                <Col flex="0 0 18%">
                    <img src={icon_dt_1} className="img-full" alt="icon"/>
                </Col>
                <Col flex="0 0 70%" offset={1}>
                    <TitleDataTasting1 />
                    <TextDataTasting1 />
                </Col>
            </Row>
            <Row align="top" justify="center" className="padded-t50">
                <Col flex="0 0 18%">
                    <img src={icon_dt_2} className="img-scaled-70" alt="icon"/>
                </Col>
                <Col flex="0 0 70%" offset={1}>
                    <TitleDataTasting2 />
                    <TextDataTasting2 />
                </Col>
            </Row>
            <Row align="top" justify="center" className="padded-t50">
                <Col flex="0 0 18%">
                    <img src={icon_dt_3} className="img-scaled-70" alt="icon"/>
                </Col>
                <Col flex="0 0 70%" offset={1}>
                    <TitleDataTasting3 />
                    <TextDataTasting3 />
                </Col>
            </Row>
            <Row align="top" justify="center" className="padded-t50">
                <Col flex="0 0 18%">
                    <img src={icon_dt_4} className="img-scaled-70" alt="icon"/>
                </Col>
                <Col flex="0 0 70%" offset={1}>
                    <TitleDataTasting4 />
                    <TextDataTasting4 />
                </Col>
            </Row>
            <Row align="top" justify="center" className="padded-t50">
                <Col flex="0 0 18%">
                    <img src={icon_dt_5} className="img-scaled-70" alt="icon"/>
                </Col>
                <Col flex="0 0 70%" offset={1}>
                    <TitleDataTasting5 />
                    <TextDataTasting5 />
                </Col>
            </Row>
            <Row align="top" justify="center" className="padded-t50 padded-b50">
                <Col flex="0 0 18%">
                    <img src={icon_dt_6} className="img-scaled-70" alt="icon"/>
                </Col>
                <Col flex="0 0 70%" offset={1}>
                    <TitleDataTasting6 />
                    <TextDataTasting6 />
                </Col>
            </Row>
        </div>
    );
}
