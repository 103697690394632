import colorScheme from "../../scss/index.scss";


export function optionVizBar(data: number[][], fsize: number): Object {
    return(
        {
            backgroundColor: colorScheme.colorSoothingDarkBlue,
            tooltip: {
                backgroundColor: colorScheme.colorSoothingDarkBlue,
                borderColor: colorScheme.colorSmoothWhite,
                borderWidth: 2,
                textStyle: {
                    color: colorScheme.colorSmoothWhite
                },
                trigger: 'axis',
                axisPointer: {
                    type: 'shadow'
                }
            },
            legend: {
                right: 50,
                textStyle: {
                    fontSize: fsize,
                    color: colorScheme.colorSmoothWhite,
                }
            },
            title: {
                text: '',
                subtext: '',
                textStyle: {
                    color: colorScheme.colorSmoothWhite,
                    fontFamily: 'Lato'
                }
            },
            grid: {
                left: '3%',
                right: '4%',
                bottom: '3%',
                containLabel: true,
            },
            toolbox: {
                itemSize: fsize+4,
                iconStyle: {
                    borderColor: colorScheme.colorSmoothWhite,
                    borderWidth: 2,
                },
                feature: {
                    saveAsImage: {
                        title: 'Save as image'
                    },
                },
                showTitle: false,
                tooltip: {
                    show: true,
                    formatter: function (param: any) {
                        return('<div>' + param.title + '</div>')}, // user-defined DOM structure
                    backgroundColor: colorScheme.colorSoothingDarkBlue,
                    borderColor: colorScheme.colorSmoothWhite,
                    borderWidth: 2,
                    textStyle: {
                        color: colorScheme.colorSmoothWhite,
                        fontSize: fsize,
                    }
                }
            },
            xAxis: [
                {
                    type: 'category',
                    data: ['Q1 2020', 'Q2 2020', 'Q3 2020', 'Q4 2020', 'Q1 2021', 'Q2 2021', 'Q3 2021'],
                    axisLabel: {
                        fontSize: fsize,
                        color: colorScheme.colorSmoothWhite
                    },
                    axisLine: {
                        lineStyle: {
                            color: colorScheme.colorSmoothWhite
                        }
                    }
                }
            ],
            yAxis: [
                {
                    type: 'value',
                    name: 'amount',
                    nameLocation: 'middle',
                    nameTextStyle: {
                        fontSize: fsize,
                        lineHeight: 80,
                        color: colorScheme.colorSmoothWhite,
                    },
                    axisLabel: {
                        fontSize: fsize,
                        color: colorScheme.colorSmoothWhite
                    },
                    axisLine: {
                        lineStyle: {
                            color: colorScheme.colorSmoothWhite
                        }
                    }
                }
            ],
            series: [
                {
                    name: 'n-OK',
                    color: colorScheme.colorFireBerryPink,
                    type: 'bar',
                    emphasis: {
                        focus: 'series'
                    },
                    data: data[0],
                    markLine: {
                        lineStyle: {
                            type: 'dashed',
                            width: 2
                        },
                        data: [[{ type: 'max' }, { type: 'min' }]],
                        label: {
                            formatter: String(
                                Math.round(
                                    (100*Math.min.apply(Math, data[0])/Math.max.apply(Math, data[2]) + Number.EPSILON)
                                    *100)/100
                            )+'%',
                            position: 'end',
                            color: colorScheme.colorSmoothWhite,
                            fontSize: fsize,
                            backgroundColor: colorScheme.colorSoothingDarkBlue
                        }
                    }
                },
                {
                    name: 'OK',
                    color: colorScheme.colorLeafGreen,
                    type: 'bar',
                    emphasis: {
                        focus: 'series'
                    },
                    data: data[1],
                    markLine: {
                        lineStyle: {
                            type: 'dashed',
                            width: 2
                        },
                        data: [[{ type: 'min' }, { type: 'max' }]],
                        label: {
                            formatter: String(
                                Math.round(
                                    (100*Math.max.apply(Math, data[1])/Math.max.apply(Math, data[2]) + Number.EPSILON)
                                    *100)/100
                            )+'%',
                            position: 'end',
                            color: colorScheme.colorSmoothWhite,
                            fontSize: fsize,
                            backgroundColor: colorScheme.colorSoothingDarkBlue
                        }
                    }
                },
                {
                    name: 'Total',
                    color: colorScheme.colorBlazingYellow,
                    type: 'bar',
                    data: data[2],
                    emphasis: {
                        focus: 'series'
                    }
                },
            ]
        }
    )
}


export function optionVizBar2(data: number[][], fsize: number): Object  {
    return(
        {
            backgroundColor: colorScheme.colorSoothingDarkBlue,
            tooltip: {
                backgroundColor: colorScheme.colorSoothingDarkBlue,
                borderColor: colorScheme.colorSmoothWhite,
                borderWidth: 2,
                textStyle: {
                    color: colorScheme.colorSmoothWhite
                },
                trigger: 'axis',
                axisPointer: {
                    type: 'shadow'
                }
            },
            legend: {
                right: 50,
                textStyle: {
                    fontSize: fsize,
                    color: colorScheme.colorSmoothWhite,
                }
            },
            title: {
                text: '',
                subtext: '',
                textStyle: {
                    color: colorScheme.colorSmoothWhite,
                    fontFamily: 'Lato'
                }
            },
            grid: {
                left: '3%',
                right: '4%',
                bottom: '3%',
                containLabel: true
            },
            toolbox: {
                itemSize: fsize+4,
                iconStyle: {
                    borderColor: colorScheme.colorSmoothWhite,
                    borderWidth: 2,
                },
                feature: {
                    saveAsImage: {
                        title: 'Save as image'
                    },
                },
                showTitle: false,
                tooltip: {
                    show: true,
                    formatter: function (param: any) {
                        return('<div>' + param.title + '</div>')}, // user-defined DOM structure
                    backgroundColor: colorScheme.colorSoothingDarkBlue,
                    borderColor: colorScheme.colorSmoothWhite,
                    borderWidth: 2,
                    textStyle: {
                        color: colorScheme.colorSmoothWhite,
                        fontSize: fsize,
                    }
                }
            },
            xAxis: [
                {
                    type: 'category',
                    data: ['Q1 2020', 'Q2 2020', 'Q3 2020', 'Q4 2020', 'Q1 2021', 'Q2 2021', 'Q3 2021'],
                    axisLabel: {
                        fontSize: fsize,
                        color: colorScheme.colorSmoothWhite
                    },
                    axisLine: {
                        lineStyle: {
                            color: colorScheme.colorSmoothWhite
                        }
                    }
                }
            ],
            yAxis: [
                {
                    type: 'value',
                    name: 'amount',
                    nameLocation: 'middle',
                    nameTextStyle: {
                        fontSize: fsize,
                        lineHeight: 80,
                        color: colorScheme.colorSmoothWhite,
                    },
                    axisLabel: {
                        fontSize: fsize,
                        color: colorScheme.colorSmoothWhite
                    },
                    axisLine: {
                        lineStyle: {
                            color: colorScheme.colorSmoothWhite
                        }
                    }
                }
            ],
            series: [
                {
                    name: 'n-OK',
                    color: colorScheme.colorFireBerryPink,
                    type: 'bar',
                    stack: 'Ad',
                    emphasis: {
                        focus: 'series'
                    },
                    data: data[0],
                    markLine: {
                        lineStyle: {
                            type: 'dashed',
                            width: 2
                        },
                        data: [[{ type: 'max' }, { type: 'min' }]],
                        label: {
                            formatter: String(
                                Math.round(
                                    (100*Math.min.apply(Math, data[0])/(data[0][data[0].length-1]+data[1][data[1].length-1]) + Number.EPSILON)
                                    *100)/100
                            )+'%',
                            position: 'end',
                            color: colorScheme.colorSmoothWhite,
                            fontSize: fsize,
                            backgroundColor: colorScheme.colorSoothingDarkBlue
                        }
                    }
                },
                {
                    name: 'OK',
                    color: colorScheme.colorLeafGreen,
                    type: 'bar',
                    stack: 'Ad',
                    emphasis: {
                        focus: 'series'
                    },
                    data: data[1],
                    markLine: {
                        lineStyle: {
                            type: 'dashed',
                            width: 2
                        },
                        data: [[{ type: 'min' }, { type: 'max' }]],
                        label: {
                            formatter: String(
                                Math.round(
                                    (100*Math.max.apply(Math, data[1])/(data[0][data[0].length-1]+data[1][data[1].length-1]) + Number.EPSILON)
                                    *100)/100
                            )+'%',
                            position: 'end',
                            color: colorScheme.colorSmoothWhite,
                            fontSize: fsize,
                            backgroundColor: colorScheme.colorSoothingDarkBlue
                        }
                    }
                },
            ]
        }
    )
}


export function optionVizLine(data: number[][], fsize: number): Object  {
    return(
        {
            backgroundColor: colorScheme.colorSoothingDarkBlue,
            title: {
                text: ''
            },
            tooltip: {
                backgroundColor: colorScheme.colorSoothingDarkBlue,
                borderColor: colorScheme.colorSmoothWhite,
                borderWidth: 2,
                textStyle: {
                    color: colorScheme.colorSmoothWhite
                },
                trigger: 'axis'
            },
            legend: {
                data: [
                    'Sensor 1',
                    'Sensor 2',
                    'Sensor 3',
                    'Sensor 4',
                    'Sensor 5',
                    'Sensor 6',
                    'Sensor 7',
                    'Sensor 8',
                ],
                textStyle: {
                    fontSize: fsize,
                    color: colorScheme.colorSmoothWhite,
                }
            },
            grid: {
                left: '5%',
                right: '4%',
                bottom: '3%',
                containLabel: true,
            },
            toolbox: {
                itemSize: fsize+4,
                iconStyle: {
                    borderColor: colorScheme.colorSmoothWhite,
                    borderWidth: 2,
                },
                feature: {
                    saveAsImage: {
                        title: 'Save as image'
                    },
                },
                showTitle: false,
                tooltip: {
                    show: true,
                    formatter: function (param: any) {
                        return('<div>' + param.title + '</div>')}, // user-defined DOM structure
                    backgroundColor: colorScheme.colorSoothingDarkBlue,
                    borderColor: colorScheme.colorSmoothWhite,
                    borderWidth: 2,
                    textStyle: {
                        color: colorScheme.colorSmoothWhite,
                        fontSize: fsize,
                    }
                }
            },
            xAxis: {
                type: 'value',
                axisLabel: {
                    fontSize: fsize,
                    color: colorScheme.colorSmoothWhite
                },
                axisLine: {
                    lineStyle: {
                        color: colorScheme.colorSmoothWhite
                    }
                },
            },
            yAxis: {
                type: 'value',
                name: 'normalized strain',
                nameLocation: 'middle',
                nameTextStyle: {
                    fontSize: fsize,
                    lineHeight: 80,
                    color: colorScheme.colorSmoothWhite,
                },
                axisLabel: {
                    fontSize: fsize,
                    color: colorScheme.colorSmoothWhite
                },
                axisLine: {
                    lineStyle: {
                        color: colorScheme.colorSmoothWhite
                    }
                }
            },
            dataZoom: [
                {
                    type: 'inside',
                },
                {
                    type: 'slider',
                },
            ],
            series: [
                {
                    name: 'Sensor 1',
                    color: colorScheme.colorLeafGreen,
                    type: 'line',
                    data: data[0].map((value, index) => [index, value]),
                },
                {
                    name: 'Sensor 2',
                    color: colorScheme.colorLeafGreen,
                    type: 'line',
                    data: data[1].map((value, index) => [index, value]),
                },
                {
                    name: 'Sensor 3',
                    color: colorScheme.colorLeafGreen,
                    type: 'line',
                    data: data[2].map((value, index) => [index, value]),
                },
                {
                    name: 'Sensor 4',
                    color: colorScheme.colorLeafGreen,
                    type: 'line',
                    data: data[3].map((value, index) => [index, value]),
                },
                {
                    name: 'Sensor 5',
                    color: colorScheme.colorLeafGreen,
                    type: 'line',
                    data: data[4].map((value, index) => [index, value]),
                },
                {
                    name: 'Sensor 6',
                    color: colorScheme.colorLeafGreen,
                    type: 'line',
                    data: data[5].map((value, index) => [index, value]),
                },
                {
                    name: 'Sensor 7',
                    color: colorScheme.colorFireBerryPink,
                    type: 'line',
                    data: data[6].map((value, index) => [index, value]),
                },
                {
                    name: 'Sensor 8',
                    color: colorScheme.colorFireBerryPink,
                    type: 'line',
                    data: data[7].map((value, index) => [index, value]),
                }
            ]
        }
    )
}


export function optionVizLine2(data: number[], fsize: number): Object  {
    return(
        {
            backgroundColor: colorScheme.colorSoothingDarkBlue,
            title: {
                text: ''
            },
            tooltip: {
                backgroundColor: colorScheme.colorSoothingDarkBlue,
                borderColor: colorScheme.colorSmoothWhite,
                borderWidth: 2,
                textStyle: {
                    color: colorScheme.colorSmoothWhite
                },
                trigger: 'axis'
            },
            legend: {
                data: [
                    'Sensor',
                ],
                textStyle: {
                    fontSize: fsize,
                    color: colorScheme.colorSmoothWhite,
                }
            },
            grid: {
                left: '5%',
                right: '4%',
                bottom: '3%',
                containLabel: true
            },
            toolbox: {
                itemSize: fsize+4,
                iconStyle: {
                    borderColor: colorScheme.colorSmoothWhite,
                    borderWidth: 2,
                },
                feature: {
                    saveAsImage: {
                        title: 'Save as image'
                    },
                },
                showTitle: false,
                tooltip: {
                    show: true,
                    formatter: function (param: any) {
                        return('<div>' + param.title + '</div>')}, // user-defined DOM structure
                    backgroundColor: colorScheme.colorSoothingDarkBlue,
                    borderColor: colorScheme.colorSmoothWhite,
                    borderWidth: 2,
                    textStyle: {
                        color: colorScheme.colorSmoothWhite,
                        fontSize: fsize,
                    }
                }
            },
            xAxis: {
                type: 'value',
                min: 'dataMin',
                max: 'dataMax',
                axisLabel: {
                    fontSize: fsize,
                    color: colorScheme.colorSmoothWhite
                },
                axisLine: {
                    lineStyle: {
                        color: colorScheme.colorSmoothWhite
                    }
                },
            },
            yAxis: {
                type: 'value',
                name: 'normalized strain',
                nameLocation: 'middle',
                nameTextStyle: {
                    fontSize: fsize,
                    lineHeight: 80,
                    color: colorScheme.colorSmoothWhite,
                },
                axisLabel: {
                    fontSize: fsize,
                    color: colorScheme.colorSmoothWhite
                },
                axisLine: {
                    lineStyle: {
                        color: colorScheme.colorSmoothWhite
                    }
                },
            },
            dataZoom: [
                {
                    type: 'inside',
                },
                {
                    type: 'slider',
                },
            ],
            series: [
                {
                    name: 'Sensor',
                    color: colorScheme.colorBlazingYellow,
                    type: 'line',
                    data: data.map((value, index) => [index, value]),
                    markLine: {
                        silent: true,
                        symbol: ['none', 'none'],
                        lineStyle: {
                            color: colorScheme.colorAutumnOrange,
                            width: 1,
                            type: 'dashed',
                            cap: 'butt',
                        },
                        data: [
                            [
                                {
                                    xAxis: 200,
                                    yAxis: 0
                                },
                                {
                                    xAxis: 200,
                                    yAxis: 1.2
                                },
                            ],
                            [
                                {
                                    xAxis: 400,
                                    yAxis: 0
                                },
                                {
                                    xAxis: 400,
                                    yAxis: 1.2
                                },
                            ],
                            [
                                {
                                    xAxis: 600,
                                    yAxis: 0
                                },
                                {
                                    xAxis: 600,
                                    yAxis: 1.2
                                },
                            ],
                            [
                                {
                                    xAxis: 800,
                                    yAxis: 0
                                },
                                {
                                    xAxis: 800,
                                    yAxis: 1.2
                                },
                            ],
                            [
                                {
                                    xAxis: 1000,
                                    yAxis: 0
                                },
                                {
                                    xAxis: 1000,
                                    yAxis: 1.2
                                },
                            ],
                            [
                                {
                                    xAxis: 1200,
                                    yAxis: 0
                                },
                                {
                                    xAxis: 1200,
                                    yAxis: 1.2
                                },
                            ],
                            [
                                {
                                    xAxis: 1400,
                                    yAxis: 0
                                },
                                {
                                    xAxis: 1400,
                                    yAxis: 1.2
                                },
                            ],
                        ],
                    },
                    markArea: {
                        itemStyle: {
                            color: colorScheme.colorFireBerryPink,
                            opacity: '1.0',
                        },
                        label: {
                            color: colorScheme.colorSmoothWhite,
                            fontSize: fsize,
                        },
                        data: [
                            [
                                {
                                    name: 'Large Crack',
                                    xAxis: '600',
                                    yAxis: '1.1',
                                },
                                {
                                    xAxis: '800',
                                    yAxis: '1.2',
                                }
                            ],
                            [
                                {
                                    name: 'Medium Crack ',
                                    xAxis: '1200',
                                    yAxis: '1.1',
                                },
                                {
                                    xAxis: '1400',
                                    yAxis: '1.2',
                                }
                            ]
                        ]
                    }
                },
                {
                    name: 'Dummy',
                    color: colorScheme.colorSmoothWhite,
                    type: 'line',
                    data: [],
                    markArea: {
                        itemStyle: {
                            color: colorScheme.colorLeafGreen,
                            opacity: '1.0',
                        },
                        label: {
                            color: colorScheme.colorSmoothWhite,
                            fontSize: fsize,
                        },
                        data: [
                            [
                                {
                                    name: 'Regular Stroke',
                                    xAxis: '0',
                                    yAxis: '1.1',
                                },
                                {
                                    xAxis: '200',
                                    yAxis: '1.2',
                                }
                            ],
                            [
                                {
                                    name: 'Regular Stroke',
                                    xAxis: '200',
                                    yAxis: '1.1',
                                },
                                {
                                    xAxis: '400',
                                    yAxis: '1.2',
                                }
                            ],
                            [
                                {
                                    name: 'Regular Stroke',
                                    xAxis: '400',
                                    yAxis: '1.1',
                                },
                                {
                                    xAxis: '600',
                                    yAxis: '1.2',
                                }
                            ],
                            [
                                {
                                    name: 'Regular Stroke',
                                    xAxis: '800',
                                    yAxis: '1.1',
                                },
                                {
                                    xAxis: '1000',
                                    yAxis: '1.2',
                                }
                            ],
                            [
                                {
                                    name: 'Regular Stroke',
                                    xAxis: '1000',
                                    yAxis: '1.1',
                                },
                                {
                                    xAxis: '1200',
                                    yAxis: '1.2',
                                }
                            ],
                            [
                                {
                                    name: 'Regular Stroke',
                                    xAxis: '1400',
                                    yAxis: '1.1',
                                },
                                {
                                    xAxis: '1600',
                                    yAxis: '1.2',
                                }
                            ],
                        ]
                    }
                }

            ]
        }
    )
}

export function optionVizScatter(data: number[][][], fsize: number, mean: boolean, range: boolean): Object  {
    return(
        {
            backgroundColor: colorScheme.colorSoothingDarkBlue,
            title: {
                text: '',
                subtext: '',
                textStyle: {
                    color: colorScheme.colorSmoothWhite,
                    fontFamily: 'Lato'
                }
            },
            grid: {
                left: '5%',
                right: '18%',
                bottom: '10%',
                containLabel: true
            },
            tooltip: {
                backgroundColor: colorScheme.colorSoothingDarkBlue,
                borderColor: colorScheme.colorSmoothWhite,
                borderWidth: 2,
                textStyle: {
                    color: colorScheme.colorSmoothWhite
                },
                // trigger: 'axis',
                showDelay: 0,
                formatter: function (params: any) {
                    if (params.value.length > 1) {
                        return (
                            params.seriesName +
                            ':<br/>' +
                            'x=' + params.value[0] +
                            ' <br />' +
                            'y=' + params.value[1]
                        );
                    } else {
                        return (
                            params.name +
                            ' : ' +
                            params.value
                        );
                    }
                },
                axisPointer: {
                    show: true,
                    type: 'cross',
                    lineStyle: {
                        type: 'dashed',
                        width: 1
                    },
                    label: {
                        color: colorScheme.colorSmoothWhite,
                        backgroundColor: colorScheme.colorSoothingDarkBlue,
                        fontSize: fsize,
                    }
                }
            },
            toolbox: {
                itemSize: fsize+4,
                iconStyle: {
                    borderColor: colorScheme.colorSmoothWhite,
                    borderWidth: 2,
                },
                feature: {
                    dataZoom: {
                        title: {
                            zoom: 'Zoom in',
                            back: 'Reset zoom'
                        }
                    },
                    saveAsImage: {
                        title: 'Save as image'
                    },
                },
                showTitle: false,
                tooltip: {
                    show: true,
                    formatter: function (param: any) {
                        return('<div>' + param.title + '</div>')}, // user-defined DOM structure
                    backgroundColor: colorScheme.colorSoothingDarkBlue,
                    borderColor: colorScheme.colorSmoothWhite,
                    borderWidth: 2,
                    textStyle: {
                        color: colorScheme.colorSmoothWhite,
                        fontSize: fsize,
                    }
                }
            },
            legend: {
                data: ['OK', 'n-OK'],
                left: 'center',
                bottom: 0,
                textStyle: {
                    fontSize: fsize,
                    color: colorScheme.colorSmoothWhite,
                }
            },
            xAxis: [
                {
                    name: 'max strain [mu-eps]',
                    nameTextStyle: {
                        fontSize: fsize,
                        color: colorScheme.colorSmoothWhite,
                    },
                    type: 'value',
                    scale: true,
                    axisLabel: {
                        formatter: '{value}',
                        fontSize: fsize,
                        color: colorScheme.colorSmoothWhite
                    },
                    axisLine: {
                        lineStyle: {
                            color: colorScheme.colorSmoothWhite
                        }
                    },
                    splitLine: {
                        show: false
                    }
                }
            ],
            yAxis: [
                {
                    name: 'mean strain [mu-eps]',
                    nameTextStyle: {
                        fontSize: fsize,
                        color: colorScheme.colorSmoothWhite,
                    },
                    type: 'value',
                    scale: true,
                    axisLabel: {
                        formatter: '{value}',
                        fontSize: fsize,
                        color: colorScheme.colorSmoothWhite
                    },
                    axisLine: {
                        lineStyle: {
                            color: colorScheme.colorSmoothWhite
                        }
                    },
                    splitLine: {
                        show: false
                    }
                }
            ],
            series: [
                {
                    name: 'n-OK',
                    type: 'scatter',
                    color: colorScheme.colorFireBerryPink,
                    emphasis: {
                        focus: 'series'
                    },
                    // prettier-ignore
                    data: data[1],
                    markArea: {
                        silent: true,
                        itemStyle: {
                            color: 'transparent',
                            borderWidth: 1,
                            borderType: 'dashed'
                        },
                        label: {
                            fontSize: fsize,
                            color: colorScheme.colorSmoothWhite,
                            backgroundColor: colorScheme.colorSoothingDarkBlue
                        },
                        data: range && [
                            [
                                {
                                    name: 'n-OK Data Range',
                                    xAxis: 'min',
                                    yAxis: 'min'
                                },
                                {
                                    xAxis: 'max',
                                    yAxis: 'max'
                                }
                            ]
                        ]
                    },
                    markLine: mean && {
                        lineStyle: {
                            type: 'solid'
                        },
                        data: [{ type: 'average', name: 'y-mean' }, {name: 'x-mean', xAxis: 160.7}],
                        label: {
                            fontSize: fsize,
                            color: colorScheme.colorSmoothWhite,
                            backgroundColor: colorScheme.colorSoothingDarkBlue
                        },
                    }
                },
                {
                    name: 'OK',
                    type: 'scatter',
                    color: colorScheme.colorLeafGreen,
                    emphasis: {
                        focus: 'series'
                    },
                    // prettier-ignore
                    data: data[0],
                    markArea: range && {
                        silent: true,
                        itemStyle: {
                            color: 'transparent',
                            borderWidth: 1,
                            borderType: 'dashed'
                        },
                        label: {
                            fontSize: fsize,
                            color: colorScheme.colorSmoothWhite,
                            backgroundColor: colorScheme.colorSoothingDarkBlue
                        },
                        data: [
                            [
                                {
                                    name: 'OK Data Range',
                                    xAxis: 'min',
                                    yAxis: 'min'
                                },
                                {
                                    xAxis: 'max',
                                    yAxis: 'max'
                                }
                            ]
                        ]
                    },
                    markLine: mean && {
                        lineStyle: {
                            type: 'solid'
                        },
                        data: [{ type: 'average', name: 'y-mean' }, {name: 'x-mean', xAxis: 177.8}],
                        label: {
                            fontSize: fsize,
                            color: colorScheme.colorSmoothWhite,
                            backgroundColor: colorScheme.colorSoothingDarkBlue
                        },
                    }
                }
            ]
        }
    )
}

export function optionVizScatter2Bar(data: number[], fsize: number): Object  {
    return(
        {
            backgroundColor: colorScheme.colorSoothingDarkBlue,
            tooltip: {
                backgroundColor: colorScheme.colorSoothingDarkBlue,
                borderColor: colorScheme.colorSmoothWhite,
                borderWidth: 2,
                textStyle: {
                    color: colorScheme.colorSmoothWhite
                },
                trigger: 'axis',
                axisPointer: {
                    type: 'shadow'
                }
            },
            legend: {
                right: 50,
                data: [
                    'OK',
                    'n-OK',
                    'Total'
                ],
                textStyle: {
                    fontSize: fsize,
                    color: colorScheme.colorSmoothWhite,
                }
            },
            title: {
                text: '',
                subtext: '',
                textStyle: {
                    color: colorScheme.colorSmoothWhite,
                    fontFamily: 'Lato'
                }
            },
            grid: {
                left: '7%',
                right: '4%',
                bottom: '3%',
                containLabel: true
            },
            toolbox: {
                itemSize: fsize+4,
                iconStyle: {
                    borderColor: colorScheme.colorSmoothWhite,
                    borderWidth: 2,
                },
                feature: {
                    saveAsImage: {
                        title: 'Save as image'
                    },
                },
                showTitle: false,
                tooltip: {
                    show: true,
                    formatter: function (param: any) {
                        return('<div>' + param.title + '</div>')}, // user-defined DOM structure
                    backgroundColor: colorScheme.colorSoothingDarkBlue,
                    borderColor: colorScheme.colorSmoothWhite,
                    borderWidth: 2,
                    textStyle: {
                        color: colorScheme.colorSmoothWhite,
                        fontSize: fsize,
                    }
                }
            },
            xAxis: [
                {
                    type: 'category',
                    data: ['OK', 'n-OK', 'Total'],
                    axisLabel: {
                        fontSize: fsize,
                        color: colorScheme.colorSmoothWhite
                    },
                    axisLine: {
                        lineStyle: {
                            color: colorScheme.colorSmoothWhite
                        }
                    }
                }
            ],
            yAxis: [
                {
                    type: 'value',
                    name: 'amount',
                    nameLocation: 'middle',
                    nameTextStyle: {
                        fontSize: fsize,
                        lineHeight: 80,
                        color: colorScheme.colorSmoothWhite
                    },
                    axisLabel: {
                        fontSize: fsize,
                        color: colorScheme.colorSmoothWhite
                    },
                    axisLine: {
                        lineStyle: {
                            color: colorScheme.colorSmoothWhite
                        }
                    }
                }
            ],
            series: [
                {
                    name: 'combined',
                    color: colorScheme.colorFireBerryPink,
                    type: 'bar',
                    emphasis: {
                        focus: 'series'
                    },
                    data: [
                        {
                            value: data[0],
                            itemStyle: {
                                color: colorScheme.colorLeafGreen,
                            }
                        },
                        {
                            value: data[1],
                            itemStyle: {
                                color: colorScheme.colorFireBerryPink,
                            }
                        },
                        {
                            value: data[2],
                            itemStyle: {
                                color: colorScheme.colorBlazingYellow,
                            }
                        },
                    ],
                },
                {
                    name: 'OK',
                    color: colorScheme.colorLeafGreen,
                    type: 'scatter',
                    data: [],
                },
                {
                    name: 'n-OK',
                    color: colorScheme.colorFireBerryPink,
                    type: 'scatter',
                    data: [],
                },
                {
                    name: 'Total',
                    color: colorScheme.colorBlazingYellow,
                    type: 'scatter',
                    data: [],
                }
            ]
        }
    )
}

export function optionVizScatter2(data: number[][][], fsize: number): Object {
    return(
        {
            backgroundColor: colorScheme.colorSoothingDarkBlue,
            title: {
                text: '',
                subtext: '',
                textStyle: {
                    color: colorScheme.colorSmoothWhite,
                    fontFamily: 'Lato'
                }
            },
            grid: {
                left: '4%',
                right: '13%',
                bottom: '7%',
                containLabel: true
            },
            tooltip: {
                backgroundColor: colorScheme.colorSoothingDarkBlue,
                borderColor: colorScheme.colorSmoothWhite,
                borderWidth: 2,
                textStyle: {
                    color: colorScheme.colorSmoothWhite
                },
                // trigger: 'axis',
                showDelay: 0,
                formatter: function (params: any) {
                    if (params.value.length > 1) {
                        return (
                            params.seriesName +
                            ':<br/>' +
                            'x=' + params.value[0] +
                            ' <br />' +
                            'y=' + params.value[1]
                        );
                    } else {
                        return (
                            params.name +
                            ' : ' +
                            params.value
                        );
                    }
                },
                axisPointer: {
                    show: true,
                    type: 'cross',
                    lineStyle: {
                        type: 'dashed',
                        width: 1
                    },
                    label: {
                        color: colorScheme.colorSmoothWhite,
                        backgroundColor: colorScheme.colorSoothingDarkBlue,
                        fontSize: fsize,
                    }
                }
            },
            toolbox: {
                itemSize: fsize+4,
                iconStyle: {
                    borderColor: colorScheme.colorSmoothWhite,
                    borderWidth: 2,
                },
                feature: {
                    dataZoom: {
                        title: {
                            zoom: 'Zoom in',
                            back: 'Reset zoom'
                        }
                    },
                    brush: {
                        type: ['rect', 'polygon', 'clear'],
                        title: {
                            rect: 'Rectangle selection',
                            polygon: 'Polygon selection',
                            clear: 'Clear selection'
                        }
                    },
                    saveAsImage: {
                        title: 'Save as image'
                    },
                },
                showTitle: false,
                tooltip: {
                    show: true,
                    formatter: function (param: any) {
                        return('<div>' + param.title + '</div>')}, // user-defined DOM structure
                    backgroundColor: colorScheme.colorSoothingDarkBlue,
                    borderColor: colorScheme.colorSmoothWhite,
                    borderWidth: 2,
                    textStyle: {
                        color: colorScheme.colorSmoothWhite,
                        fontSize: fsize,
                    }
                }
            },
            brush: {
                throttleType: 'debounce',
                throttleDelay: 100,
            },
            legend: {
                data: ['OK', 'n-OK'],
                left: 'center',
                bottom: 0,
                textStyle: {
                    fontSize: fsize,
                    color: colorScheme.colorSmoothWhite,
                }
            },
            xAxis: [
                {
                    name: 'max strain [mu-eps]',
                    nameTextStyle: {
                        fontSize: fsize,
                        color: colorScheme.colorSmoothWhite,
                    },
                    type: 'value',
                    scale: true,
                    axisLabel: {
                        formatter: '{value}',
                        fontSize: fsize,
                        color: colorScheme.colorSmoothWhite
                    },
                    axisLine: {
                        lineStyle: {
                            color: colorScheme.colorSmoothWhite
                        }
                    },
                    splitLine: {
                        show: false
                    }
                }
            ],
            yAxis: [
                {
                    name: 'mean strain [mu-eps]',
                    nameTextStyle: {
                        fontSize: fsize,
                        color: colorScheme.colorSmoothWhite
                    },
                    type: 'value',
                    scale: true,
                    axisLabel: {
                        formatter: '{value}',
                        fontSize: fsize,
                        color: colorScheme.colorSmoothWhite
                    },
                    axisLine: {
                        lineStyle: {
                            color: colorScheme.colorSmoothWhite
                        }
                    },
                    splitLine: {
                        show: false
                    }
                }
            ],
            series: [
                {
                    name: 'n-OK',
                    type: 'scatter',
                    color: colorScheme.colorFireBerryPink,
                    emphasis: {
                        focus: 'series'
                    },
                    // prettier-ignore
                    data: data[1],
                },
                {
                    name: 'OK',
                    type: 'scatter',
                    color: colorScheme.colorLeafGreen,
                    emphasis: {
                        focus: 'series'
                    },
                    // prettier-ignore
                    data: data[0],
                }
            ]
        }
    )
}

export function optionRange(fsize: number): Object {
    return(
        {
            series: [
                {
                    name: 'n-OK',
                    // prettier-ignore
                    markArea: {
                        silent: true,
                        itemStyle: {
                            color: 'transparent',
                            borderWidth: 1,
                            borderType: 'dashed'
                        },
                        label: {
                            fontSize: fsize,
                            color: colorScheme.colorSmoothWhite,
                            backgroundColor: colorScheme.colorSoothingDarkBlue
                        },
                        data: [
                            [
                                {
                                    name: 'n-OK Data Range',
                                    xAxis: 'min',
                                    yAxis: 'min'
                                },
                                {
                                    xAxis: 'max',
                                    yAxis: 'max'
                                }
                            ]
                        ]
                    },
                },
                {
                    name: 'OK',
                    // prettier-ignore
                    markArea: {
                        silent: true,
                        itemStyle: {
                            color: 'transparent',
                            borderWidth: 1,
                            borderType: 'dashed'
                        },
                        label: {
                            fontSize: fsize,
                            color: colorScheme.colorSmoothWhite,
                            backgroundColor: colorScheme.colorSoothingDarkBlue
                        },
                        data: [
                            [
                                {
                                    name: 'OK Data Range',
                                    xAxis: 'min',
                                    yAxis: 'min'
                                },
                                {
                                    xAxis: 'max',
                                    yAxis: 'max'
                                }
                            ]
                        ]
                    },
                }
            ]
        }
    )
}

export function optionRangeFalse(): Object {
    return(
        {
            series: [
                {
                    name: 'n-OK',
                    // prettier-ignore
                    markArea: {
                        data: []
                    },
                },
                {
                    name: 'OK',
                    // prettier-ignore
                    markArea: {
                        data: []
                    },
                }
            ]
        }
    )
}

export function optionMean(fsize: number): Object {
    return(
        {
            series: [
                {
                    name: 'n-OK',
                    // prettier-ignore
                    markLine: {
                        lineStyle: {
                            type: 'solid'
                        },
                        label: {
                            fontSize: fsize,
                            color: colorScheme.colorSmoothWhite,
                            backgroundColor: colorScheme.colorSoothingDarkBlue
                        },
                        data: [{ type: 'average', name: 'y-mean' }, {name: 'x-mean', xAxis: 160.7}],
                    }
                },
                {
                    name: 'OK',
                    // prettier-ignore
                    markLine: {
                        lineStyle: {
                            type: 'solid'
                        },
                        data: [{type: 'average', name: 'y-mean'}, {name: 'x-mean', xAxis: 177.8}],
                        label: {
                            fontSize: fsize,
                            color: colorScheme.colorSmoothWhite,
                            backgroundColor: colorScheme.colorSoothingDarkBlue
                        },
                    }
                }
            ]
        }
    )
}

export function optionMeanFalse(): Object {
    return(
        {
            series: [
                {
                    name: 'n-OK',
                    // prettier-ignore
                    markLine: {
                        data: []
                    },
                },
                {
                    name: 'OK',
                    // prettier-ignore
                    markLine: {
                        data: []
                    },
                }
            ]
        }
    )
}