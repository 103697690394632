import React from "react";
import {useMediaQuery} from "react-responsive";
import SDAToolboxXXL from "../components/pages/7_xxl/sda_toolbox_xxl";
import SDAToolboxMega from "../components/pages/8_mega/sda_toolbox_mega";
import SDAToolboxXL from "../components/pages/6_xl/sda_toolbox_xl";
import SDAToolboxLG from "../components/pages/5_lg/sda_toolbox_lg";
import SDAToolboxMD from "../components/pages/4_md/sda_toolbox_md";
import SDAToolboxSM from "../components/pages/3_sm/sda_toolbox_sm";
import SDAToolboxXS from "../components/pages/2_xs/sda_toolbox_xs";
import SDAToolboxXXS from "../components/pages/1_xxs/sda_toolbox_xxs";


export default function SDAToolbox() {
    const size_xxs = useMediaQuery({query: "(max-device-width: 479px)"});
    const size_xs = useMediaQuery({query: "(min-device-width: 480px) and (max-device-width: 577px)"});
    const size_sm = useMediaQuery({query: "(min-device-width: 578px) and (max-device-width: 767px)"});
    const size_md = useMediaQuery({query: "(min-device-width: 768px) and (max-device-width: 991px)" });
    const size_lg = useMediaQuery({query: "(min-device-width: 992px) and (max-device-width: 1199px)"});
    const size_xl = useMediaQuery({query: "(min-device-width: 1200px) and (max-device-width: 1599px)"});
    const size_xxl = useMediaQuery({query: "(min-device-width: 1600px) and (max-device-width: 1919px)"});
    const size_mega = useMediaQuery({query: "(min-device-width: 1920px)"});


    return (
        <div>
            {size_xxs && <SDAToolboxXXS />}
            {size_xs && <SDAToolboxXS />}
            {size_sm && <SDAToolboxSM />}
            {size_md && <SDAToolboxMD />}
            {size_lg && <SDAToolboxLG/>}
            {size_xl && <SDAToolboxXL />}
            {size_xxl && <SDAToolboxXXL />}
            {size_mega && <SDAToolboxMega />}
        </div>
    );
}