import {Card, Col, Row} from "antd";
import React from "react";
import {
    TitleXAIToolbox,
    TextXAIToolbox,
    TitleXAICardMotifs,
    TextXAICardMotifs,
    TitleXAICardFeature,
    TextXAICardFeature,
    TitleXAICardAblations,
    TextXAICardAblations
} from "../../text_content";
import img_xai_feat from "../../../images/xai-1.png"
import img_xai_ablation from "../../../images/xai-2.png"
import img_xai_imp from "../../../images/xai-3.png"


const { Meta } = Card;


export default function XAIToolboxMD() {
    return (
        <div>
            <Row justify="center" className="padded-t50">
                <Col span={16}>
                    <TitleXAIToolbox />
                    <TextXAIToolbox />
                </Col>
            </Row>

            <Row justify="center" className="padded-t25">
                <Col span={16}>
                    <Row justify="center">
                        <Col span={24}>
                            <Card
                                bordered={true}
                                className="custom-card"
                                cover={
                                    <img
                                        className="sda-card-cover"
                                        alt=""
                                        src={img_xai_feat}
                                    />
                                }
                            >
                                <Meta
                                    title={<TitleXAICardFeature />}
                                    description={<TextXAICardFeature />}
                                />
                            </Card>
                        </Col>
                    </Row>

                    <Row justify="center" className="padded-t25">
                        <Col span={24}>
                            <Card
                                bordered={true}
                                className="custom-card"
                                cover={
                                    <img
                                        className="sda-card-cover"
                                        alt=""
                                        src={img_xai_ablation}
                                    />
                                }
                            >
                                <Meta
                                    title={<TitleXAICardAblations />}
                                    description={<TextXAICardAblations />}
                                />
                            </Card>
                        </Col>
                    </Row>

                    <Row justify="center" className="padded-t25 padded-b50">
                        <Col span={24}>
                            <Card
                                bordered={true}
                                className="custom-card"
                                cover={
                                    <img
                                        className="sda-card-cover"
                                        alt=""
                                        src={img_xai_imp}
                                    />
                                }
                            >
                                <Meta
                                    title={<TitleXAICardMotifs />}
                                    description={<TextXAICardMotifs />}
                                />
                            </Card>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    );
}

